<template>
    <tinymce
        :value="value"
        ref="tinymceinline"
        @input="onChange"
        :height="height"
        :plugins="plugins"
        :maxchar="maxchar"
        :menubar="false"
        inline
        insert-toolbar=""
        toolbar="undo redo | bold italic underline bullist numlist anchor"
        :readonly="readonly"
        :placeholder="placeholder"
    />
</template>

<script>
    import Tinymce from '@/front/shared/components/Tinymce/index';

    const tinymceInline = {
        plugins: ['autolink lists link charmap anchor', 'searchreplace visualblocks', 'paste'],
    };

    export default {
        name: 'TinymceInline',
        components: {
            Tinymce,
        },
        props: {
            menubar: {
                type: [String, Boolean],
                default: false,
            },
            height: {
                type: Number,
                default: 200,
            },
            value: {
                type: String,
                default: '',
            },
            maxchar: Number,
            readonly: Boolean,
            placeholder: String,
        },
        data() {
            return {
                plugins: tinymceInline.plugins,
            };
        },
        methods: {
            onChange(value) {
                this.$emit('input', value);
            },
        },
    };
</script>
