import Vue from 'vue';
import Editor from '@tinymce/tinymce-vue';
import draggable from 'vuedraggable';
import TinymceInline from '@/front/shared/components/Tinymce/TinymceInline';

Vue.component('input-number', require('@/front/shared/components/InputNumber').default);
Vue.component('time-picker', require('@/front/shared/components/TimePicker').default);
Vue.component('tinymce-editor', Editor);
Vue.component('draggable', draggable);
Vue.component('tinymce-inline', TinymceInline);
