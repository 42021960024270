import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(sessionsId, query = {}) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns`,
        method: 'get',
        params: query,
    });
}

export function remove(id, sessionsId) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns/${id}`,
        method: 'delete',
    });
}

export function show(id, sessionsId) {
    return get(id, sessionsId);
}

export function get(id, sessionsId) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns/${id}`,
        method: 'get',
    });
}

export function update(id, sessionsId, data) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns/${id}`,
        method: 'put',
        data,
    });
}

export function store(sessionsId, data) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns`,
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey)) {
        return update(data[primaryKey], data);
    }

    return store(data);
}

export function sync(sessionsId, data) {
    return request({
        url: `/sessions/${sessionsId}/extra-columns/sync`,
        method: 'post',
        data,
    });
}
