export default {
    items: [
        {
            name: 'Мои сценарии',
            trans: 'nav.my_sessions',
            url: '/default',
            icon: 'nav-icon icon-user-following',
        },
        {
            name: 'Корпоративные сценарии',
            //url: '/default',
            trans: 'nav.teams_sessions',
            icon: 'nav-icon icon-people',
            children: [],
        },
        {
            name: 'Заблокированные',
            url: '/locked',
            trans: 'nav.locked',
            icon: 'nav-icon icon-lock',
            visible: function (subscription, app) {
                return app.hasLockedSessions;
            },
        },
        {
            name: 'Библиотека',
            url: '/library',
            trans: 'nav.library',
            icon: 'nav-icon icon-graduation',
            visible: function (subscription) {
                return subscription.canUseAccessMethods || subscription.canUseAccessScenarios;
            },
            children: [
                {
                    name: 'Модули',
                    url: '/library/modules',
                    trans: 'nav.modules',
                    icon: '',
                    visible: function (subscription) {
                        return subscription.canUseAccessScenarios;
                    },
                },
                {
                    name: 'Методы',
                    url: '/library/methods',
                    trans: 'nav.methods',
                    icon: '',
                    visible: function (subscription) {
                        return subscription.canUseAccessMethods;
                    },
                },
                {
                    name: 'Сценарии',
                    url: '/library/scenarios',
                    trans: 'nav.scenarios',
                    icon: '',
                    visible: function (subscription) {
                        return subscription.canUseAccessScenarios;
                    },
                },
                {
                    name: 'Видео библиотека',
                    url: '/library/videoLibrary',
                    trans: 'nav.videoLibrary',
                    icon: '',
                    visible: function (subscription) {
                        return subscription.canUseAccessScenarios;
                    },
                },
            ],
        },
        {
            name: 'Корпоративная библиотека',
            url: '/corporate-library',
            trans: 'nav.corporateLibrary',
            icon: 'nav-icon icon-graduation',
            visible: function (subscription) {
                return subscription.canUseCooperativeScenarios;
            },
            children: [
                {
                    name: 'Корпоративные методы',
                    url: '/corporate-library/methods',
                    trans: 'nav.corporate_methods',
                    icon: '',
                },
                {
                    name: 'Корпоративные сессии',
                    url: '/corporate-library/sessions',
                    trans: 'nav.corporate_sessions',
                    icon: '',
                },
            ],
        },
    ],
};
