import { DateTime } from 'luxon';

let globSeq = sequence();

export function sequence(start = 0) {
    return function () {
        return start++;
    };
}

export function globalSequence() {
    return globSeq();
}

/**
 * @param blocks
 * @param block
 * @param consider учитывать */
export function reOrdinal(blocks, block, consider = false) {
    blocks
        .filter((item) => {
            if (item.id === block.id) {
                return consider;
            }
            return item.ordinal >= block.ordinal;
        })
        .sort(sort)
        .forEach((item, idx) => {
            item.ordinal = block.ordinal + idx;
        });

    function sort(a, b) {
        if (a.id === block.id) {
            return -1;
        }
        if (b.id === block.id) {
            return 1;
        }
        return a.ordinal - b.ordinal;
    }
}

/**
 * @param blocks
 */
export function sortBlocks(blocks) {
    return blocks.sort(sort);

    function sort(a, b) {
        if (a.dummy || b.dummy) {
            let diff = a.ordinal - b.ordinal;
            if (diff === 0) {
                return a.dummy ? -1 : 1;
            }
        }
        return a.ordinal - b.ordinal;
    }
}

export function mdt(value, format = undefined, options = { zone: 'utc' }) {
    if (value instanceof DateTime) {
        return value;
    }

    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }

    if (typeof value === 'string') {
        return format
            ? DateTime.fromFormat(value, format, options)
            : DateTime.fromISO(value, options);
    }

    if (typeof value === 'number') {
        return DateTime.fromJSDate(new Date(value), options);
    }

    return null;
}

export function minuteToDate(value) {
    return mdt(value * 60 * 1000);
}

export function diffMinutes(a, b = mdt(0)) {
    return mdt(a).diff(b, 'minutes').toObject()['minutes'];
}

export function isPromise(obj) {
    return (
        !!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function'
    );
}

export function closest(el, selector) {
    let node = el;
    while (node) {
        if (node.matches(selector)) return node;
        else node = node.parentElement;
    }
    return null;
}

export function imageFront(item) {
    if (!item) return '';

    if (item.image_front) {
        return item.image_front.url;
    }

    if (item.card) {
        return item.card.image_front.url;
    }

    return '';
}

export function imageBack(item) {
    if (!item) return '';

    if (item.image_back) {
        return item.image_back.url;
    }

    if (item.card) {
        return item.card.image_back.url;
    }

    return '';
}
