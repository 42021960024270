const GuestLayout = () => import('@/front/shared/views/layouts/GuestLayout');

export default [
    {
        path: '/login',
        component: GuestLayout,
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('@/front/shared/views/pages/Login'),
            },
        ],
        meta: { guest: true, anon: true },
    },
    {
        path: '/signup',
        component: GuestLayout,
        children: [
            {
                path: '',
                name: 'SignUp',
                component: () => import('@/front/shared/views/pages/SignUp'),
            },
        ],
        meta: { guest: true, anon: true },
    },
    {
        path: '/reset',
        component: GuestLayout,
        children: [
            {
                path: '',
                name: 'ForgotPassword',
                component: () => import('@/front/shared/views/pages/ForgotPassword'),
            },
        ],
        meta: { guest: true, anon: true },
    },
    {
        path: '/reset',
        component: GuestLayout,
        children: [
            {
                path: ':token',
                name: 'ResetPassword',
                component: () => import('@/front/shared/views/pages/ResetPassword'),
            },
        ],
        meta: { guest: true, anon: true },
    },
];
