<template>
    <b-modal
        ref="modal"
        :title="title"
        v-model="visible"
        size="md"
        @hidden="reset"
        @ok="handleOk"
        :busy="pendingPaymentGetAddInfo || pendingOk"
        :okTitle="$t('dialog.payment_add.ok')"
        :ok-disabled="okDisabled"
        :cancelTitle="$t('dialog.cancel')"
        okOnly
    >
        <form-wrapper :validator="$v.form" v-loading="pendingPaymentGetAddInfo">
            <b-form-group>
                <b-row>
                    <b-col>
                        <form-group
                            name="amount"
                            class="mb-0"
                            :label="$t('dialog.payment_add.amount')"
                        >
                            <input-number
                                slot-scope="{ attrs, listeners }"
                                :inputclass="{
                                    'form-control': true,
                                    'is-valid': attrs.state,
                                    'is-invalid': attrs.state === false,
                                }"
                                :step="1"
                                :min="0"
                                :max="999999999"
                                :maxlength="10"
                                v-select
                                @onInputNumberChange="changeAmount($event)"
                                :value="form.amount"
                            ></input-number>
                        </form-group>
                    </b-col>
                    <b-col>
                        <div>
                            <div style="padding-bottom: calc(0.375rem + 1px)">
                                {{ exchangeCurrencyText }}
                            </div>
                            <currency-switcher
                                disabled
                                v-model="form.currency"
                                :list="currencies"
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
                <p class="mb-2">{{ $t('dialog.payment_add.payment_method') }}</p>
                <pay-methods v-model="form.payMethod" :list="payMethods" />
            </b-form-group>
        </form-wrapper>
    </b-modal>
</template>

<script>
    import CurrencySwitcher from '@/front/shared/components/CurrencySwitcher';
    import FormValidationMixin from '@/front/shared/components/FormValidationMixin';
    import * as paymentApi from '@/front/shared/api/payment';
    import { minAmount } from '@/front/shared/validate/validators';
    import mixinRequestWithCancel from '@/common/mixins/mixinRequestWithCancel';
    import PayMethods from '@/front/shared/components/PayMethods';
    import { required } from 'vuelidate/lib/validators';

    const defaults = {
        currency: 'rub',
        amount: 10,
        payMethod: null,
    };

    const paymentGetAddInfoMixin = mixinRequestWithCancel(
        'paymentGetAddInfo',
        paymentApi.getAddInfo,
    );

    export default {
        name: 'ModalPaymentAdd',
        mixins: [FormValidationMixin, paymentGetAddInfoMixin],
        components: { PayMethods, CurrencySwitcher },
        props: {
            title: {
                type: String,
                default() {
                    return this.$t('dialog.payment_add.title');
                },
            },
            label: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                visible: false,
                loading: false,
                pendingOk: false,
                value: null,
                currencies: ['rub', 'usd', 'eur'],
                payMethods: [],
                form: {
                    ...defaults,
                },
                currencyRate: {},
            };
        },
        computed: {
            okDisabled() {
                const $v = this.$v;
                if ($v.$pending) return true;
                return $v.$dirty && $v.$error;
            },
            exchangeCurrencyText() {
                const currency = this.form.currency || '';
                if (!currency) return '';

                const oneCurrency = this.$options.filters.tCurrency(1, currency);
                const equalRub = this.$options.filters.tCurrency(
                    this.currencyRate[currency],
                    'rub',
                );
                return `${oneCurrency} = ${equalRub}`;
            },
        },
        created() {
            this.playload = {};
            this.onOk = null;
        },
        validations() {
            let form = {
                payMethod: required,
            };

            let minAmountValue = 10;
            switch (this.form.currency) {
                case 'eur':
                case 'usd':
                    minAmountValue = 1;
            }

            form.amount = {
                minAmount: minAmount(minAmountValue, this.form.currency.toUpperCase()),
            };

            return { form };
        },
        methods: {
            open({ playload = {}, onOk = null } = {}) {
                this.reset();
                this.preparePlayload(playload);
                this.onOk = onOk;
                this.load();
                this.visible = true;
            },
            preparePlayload(playload) {
                this.playload = playload ?? {};
                const tmp = { ...defaults, ...playload };

                Object.keys(defaults).forEach((key) => {
                    this.form[key] = tmp[key];
                });
            },
            close() {
                this.visible = false;
            },
            reset() {
                this.cancelPaymentGetAddInfo();
                this.$v.form.$reset();
                this.playload = {};
                this.onOk = null;
                this.payMethods = [];
            },
            async load() {
                const response = await this.fetchPaymentGetAddInfo();
                if (response === undefined) {
                    return;
                }

                this.currencyRate = response.currency_rate;
                this.form.payMethod = response.payment_methods.find(
                    (item) => item.code === response.payment_method_default,
                );
                this.payMethods = response.payment_methods;
                console.log('fetchPaymentGetAddInfo', response);
            },
            changeAmount(event) {
                this.form.amount = event;
                this.$v.form.amount.$touch();
            },
            async handleOk(e) {
                e.preventDefault();

                const valid = await this.validation();
                if (!valid) return valid;

                let result = {
                    currency: this.form.currency,
                    amount: this.form.amount,
                    paymentMethodCode: this.form.payMethod.code,
                };
                if (_.isFunction(this.onOk)) {
                    this.pendingOk = true;
                    try {
                        result = await this.onOk(result, this.playload);
                    } catch (e) {
                        return false;
                    } finally {
                        this.pendingOk = false;
                    }
                }

                if (result === false) return false;
                this.$emit('ok', result, this.playload);
                this.visible = false;
            },
        },
    };
</script>
