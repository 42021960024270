import axios from 'axios';
import { Message } from 'element-ui';
import _ from 'lodash';
import i18n from '@/front/shared/lang';
import { ERROR_MSG_ABORTED } from '@/common/utils/request';
import * as Sentry from '@sentry/browser';

const service = axios.create({
    baseURL: '/web/api/',
    //timeout: 20000, // Request timeout
});

service.interceptors.request.use(
    (config) => {
        let socketId;
        if (config.headers['X-Socket-Id'] == null && (socketId = window.Echo.socketId())) {
            config.headers['X-Socket-Id'] = socketId;
        }

        return config;
    },
    (error) => {
        Sentry.captureException(error);
        if (window.config.appDebug) {
            console.error('request error', error);
        }
        return Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (isCancel(error)) {
            return Promise.reject(error);
        }

        const url = error.config.url || '';
        if (url.indexOf('/auth/login') === -1) {
            if (error.response && error.response.status === 401) {
                location.assign('/login');
                return Promise.reject(error);
            }
        }

        if (error.message === ERROR_MSG_ABORTED) {
            return Promise.reject(error);
        }

        if (error.response?.status >= 500) {
            Sentry.captureException(error);
        }

        if (window.config.appDebug) {
            console.error('response error', error);
        }

        let message = (error.response && error.response.data.message) || error.message;
        message = i18n.t(message);

        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    },
);

export default service;

export const CancelToken = axios.CancelToken;

export const isCancel = axios.isCancel;

export function isNetworkError(err) {
    return !!err.isAxiosError && !err.response;
}

function noop() {}

export function upload(options = {}, file) {
    const defaults = {
        url: '',
        method: 'post',
        request: null, // axios
        beforeSend: noop,
        onUploadProgress: noop,
    };

    const context = {
        options: _.extend({}, defaults, options),
    };
    context.config = {
        method: context.options.method,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            context.options.onUploadProgress(percentCompleted);
        },
    };

    return send(context, file);
}

function send(context, file) {
    let formData = new FormData();
    formData.append('file', file);
    context.config.data = formData;

    doBeforeSend(context);
    let request = context.options.request || service;
    return request(context.config);
}

function doBeforeSend(context) {
    context.config.url = _.isFunction(context.options.url)
        ? context.options.url()
        : context.options.url;
    context.options.beforeSend(context.config);
}
