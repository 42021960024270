var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        size: "md",
        busy: _vm.pendingPaymentGetAddInfo || _vm.pendingOk,
        okTitle: _vm.$t("dialog.payment_add.ok"),
        "ok-disabled": _vm.okDisabled,
        cancelTitle: _vm.$t("dialog.cancel"),
        okOnly: "",
      },
      on: { hidden: _vm.reset, ok: _vm.handleOk },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "form-wrapper",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pendingPaymentGetAddInfo,
              expression: "pendingPaymentGetAddInfo",
            },
          ],
          attrs: { validator: _vm.$v.form },
        },
        [
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("form-group", {
                        staticClass: "mb-0",
                        attrs: {
                          name: "amount",
                          label: _vm.$t("dialog.payment_add.amount"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ attrs, listeners }) {
                              return _c("input-number", {
                                directives: [
                                  { name: "select", rawName: "v-select" },
                                ],
                                attrs: {
                                  inputclass: {
                                    "form-control": true,
                                    "is-valid": attrs.state,
                                    "is-invalid": attrs.state === false,
                                  },
                                  step: 1,
                                  min: 0,
                                  max: 999999999,
                                  maxlength: 10,
                                  value: _vm.form.amount,
                                },
                                on: {
                                  onInputNumberChange: function ($event) {
                                    return _vm.changeAmount($event)
                                  },
                                },
                              })
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "calc(0.375rem + 1px)",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.exchangeCurrencyText) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("currency-switcher", {
                          attrs: { disabled: "", list: _vm.currencies },
                          model: {
                            value: _vm.form.currency,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "currency", $$v)
                            },
                            expression: "form.currency",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { staticClass: "mb-0" },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("dialog.payment_add.payment_method"))),
              ]),
              _vm._v(" "),
              _c("pay-methods", {
                attrs: { list: _vm.payMethods },
                model: {
                  value: _vm.form.payMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "payMethod", $$v)
                  },
                  expression: "form.payMethod",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }