const getters = {
    sidebar: (state) => state.app.sidebar,
    language: (state) => state.app.language,
    device: (state) => state.app.device,
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
    token: (state) => state.user.token,
    avatar: (state) => state.user.avatar,
    name: (state) => state.user.name,
    loggedIn: (state) => !!state.user.id,
    addRouters: (state) => state.permission.addRouters,
    roles: (state) => state.user.roles,
    permission_routers: (state) => state.permission.routers,
};

export default getters;
