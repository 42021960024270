<template>
    <AppHeaderDropdown right class="pr-2">
        <template slot="header">
            <img :src="avatarUrl" class="img-avatar" :alt="name" />
        </template>

        <template slot="dropdown">
            <b-dropdown-item @click="handleProfile"
                ><i class="fas fa-user" /> {{ $t('nav.my_profile') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleTariff"
                ><i class="fas fa-dollar-sign" /> {{ $t('nav.tariff') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleTeams"
                ><i class="fas fa-users" /> {{ $t('nav.teams') }}</b-dropdown-item
            >
            <b-dropdown-item @click="handleSubscriptions"
                ><i class="fas fa-calculator" /> {{ $t('nav.subscriptions') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handlePayments" v-if="isAdmin"
                ><i class="fas fa-donate" /> {{ $t('nav.payments') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleInvoices"
                ><i class="fas fa-file-invoice-dollar" /> {{ $t('nav.invoices') }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="handleLogout"
                ><i class="fas fa-lock" /> {{ $t('nav.logout') }}</b-dropdown-item
            >
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
    import * as userApi from '@/front/shared/api/user';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DefaultHeaderDropdownAccnt',
        components: {
            AppHeaderDropdown,
        },
        computed: {
            ...mapGetters(['avatar', 'name', 'role']),

            avatarUrl() {
                return (this.avatar && this.avatar.url) || '/img/no_avatar.jpg';
            },
            isAdmin() {
                return this.role === 'admin' ? true : false;
            },
        },
        methods: {
            handleProfile() {
                this.$router.push({ name: 'profile' });
            },
            handleTariff() {
                this.$router.push({ name: 'tariff' });
            },
            handleTeams() {
                this.$router.push({ name: 'teams' });
            },
            async handleInvoices() {
                try {
                    await this.$router.push({ name: 'invoices' });
                } catch (e) {}
            },
            handleSubscriptions() {
                this.$router.push({ name: 'subscriptions' });
            },
            handlePayments() {
                this.$router.push({ name: 'payments' });
            },
            handleLogout() {
                this.$store.dispatch('LogOut').then(() => {
                    location.assign('/'); // Re-instantiate the vue-router object to avoid bugs
                });
            },
        },
    };
</script>
