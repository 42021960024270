export default {
    created() {
        this.$root.$on('showModalCollaboration', this.showModalCollaboration);
        this.$root.$once('hook:beforeDestroy', () => {
            this.$root.$off('showModalCollaboration', this.showModalCollaboration);
        });
    },
    methods: {
        showModalCollaboration(playload) {
            this.$refs.modalCollaboration.open(playload);
        },
    },
};
