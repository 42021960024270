export default {
    bind(el, binding) {
        let modKeys = Object.keys(binding.modifiers);
        let wait = modKeys.length ? parseInt(modKeys[0], 10) : 200;
        el.__listenerWindowResize = binding.value;
        window.addEventListener('resize', _.debounce(binding.value, wait));
    },
    unbind(el) {
        if (el.__listenerWindowResize) {
            window.removeEventListener('resize', el.__listenerWindowResize);
            delete el.__listenerWindowResize;
        }
    },
};
