import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function chatPage(id) {
    return request({
        url: `/chat`,
        method: 'get',
    });
}

export function sendMessage(data) {
    return request({
        url: `/chat`,
        method: 'post',
        data,
    });
}

export function loadMore(params) {
    return request({
        url: `/chat/load`,
        method: 'get',
        params,
    });
}

export function setWatched(data) {
    return request({
        url: `/chat/watched`,
        method: 'post',
        data,
    });
}
