import { logout, getInfo } from '@/front/shared/api/auth';
import Subscription from '@/front/shared/classes/Subscription';

const state = {
    id: null,
    name: '',
    avatar: '',
    roles: [],
    is_anon: false,
    role: '',
    teams: [],
    guest: true,
    balance: 0,
    subscription: new Subscription(),
    subscription_ends_in: null,
};

const mutations = {
    UPDATE_USER(state, value) {
        if (!value) {
            state.id = null;
            state.name = null;
            state.teams = [];
            state.guest = true;
            state.avatar = '';
            state.balance = 0;
            state.is_anon = false;
            state.role = '';
            state.subscription.setOptions({});
            state.subscription_ends_in = null;
            return;
        }
        state.id = value.id;
        state.name = value.name;
        state.role = value.role;
        state.teams = value.teams || [];
        state.guest = false;
        state.avatar = value.avatar || '';
        state.balance = value.balance;
        state.is_anon = value.is_anon;
        state.subscription_ends_in = value.subscription_ends_in;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_GUEST: (state, guest) => {
        state.guest = guest;
    },
    SET_BALANCE: (state, balance) => {
        state.balance = balance;
    },
    UPDATE_SUBSCRIPTION(state, data) {
        state.subscription.setOptions(data);
    },
};

const actions = {
    updateUser({ commit }, user) {
        commit('UPDATE_USER', user);
    },
    updateSubscription({ commit }, data) {
        commit('UPDATE_SUBSCRIPTION', data);
    },
    async GetInfo({ commit, state }) {
        const response = getInfo(state.token);
        const data = response.data;
        commit('UPDATE_USER', data);
        commit('SET_AVATAR', data.avatar);
        return response;
    },
    LogOut() {
        return logout();
    },
};

export default {
    state,
    mutations,
    actions,
};
