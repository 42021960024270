<template>
    <el-dialog :visible.sync="dialogVisible" width="320px" :before-close="handleClose">
        <span slot="title">
            <p class="text-center">Уведомление</p>
        </span>
        <p class="text-center">
            <span class="d-block w-100">Ваша</span>
            подписка завершается сегодня
            <span class="d-block w-100"
                >через <strong>{{ time }}</strong> часа</span
            >
        </p>
        <span slot="footer" class="dialog-footer">
            <router-link :to="{ name: 'tariff' }">
                <el-button type="primary" @click="dialogVisible = false"
                    >Перейти в тарифы</el-button
                >
            </router-link>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        name: 'SubscriptionNotification',
        data() {
            return {
                dialogVisible: false,
                time: null,
            };
        },
        mounted() {
            this.handle();
        },
        methods: {
            handle() {
                var name = 'time';
                var matches = document.cookie.match(
                    new RegExp(
                        '(?:^|; )' +
                            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                            '=([^;]*)',
                    ),
                );
                var coockie = matches ? decodeURIComponent(matches[1]) : undefined;
                if (
                    this.$store.state.user.subscription_ends_in < 24 &&
                    this.$store.state.user.subscription.options.code != 'base'
                ) {
                    if (coockie == undefined) {
                        var date = new Date(new Date().getTime() + 120 * 60 * 1000);
                        document.cookie = 'time=value; path=/; expires=' + date.toUTCString();
                        this.showModal();
                    }
                }
            },
            handleClose() {
                this.dialogVisible = false;
            },
            showModal() {
                this.dialogVisible = true;
                this.time = this.$store.state.user.subscription_ends_in;
                localStorage.time = this.$store.state.user.subscription.options.date_end;
            },
        },
    };
</script>

<style>
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header p,
    .el-dialog__body p {
        font-size: 18px;
    }
</style>
