var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppHeaderDropdown",
    { attrs: { right: "" } },
    [
      _c("template", { slot: "header" }, [
        _c("span", { staticStyle: { "text-transform": "uppercase" } }, [
          _vm._v(_vm._s(_vm.language)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "dropdown" },
        [
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSetLanguage("en")
                },
              },
            },
            [_vm._v("EN")]
          ),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSetLanguage("ru")
                },
              },
            },
            [_vm._v("RU")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }