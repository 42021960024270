var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    [
      _c(
        "b-tab",
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "icon-list" }),
          ]),
          _vm._v(" "),
          _c(
            "b-list-group",
            { staticClass: "list-group-accent" },
            [
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small",
                },
                [_vm._v("\n                Today\n            ")]
              ),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-warning list-group-item-divider",
                  attrs: { href: "#" },
                },
                [
                  _c("div", { staticClass: "avatar float-right" }, [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                    Meeting with\n                    "
                    ),
                    _c("strong", [_vm._v("Lucas")]),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted mr-3" }, [
                    _c("i", { staticClass: "icon-calendar" }),
                    _vm._v("  1 - 3pm\n                "),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _c("i", { staticClass: "icon-location-pin" }),
                    _vm._v("  Palo Alto, CA\n                "),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass: "list-group-item-accent-info",
                  attrs: { href: "#" },
                },
                [
                  _c("div", { staticClass: "avatar float-right" }, [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/4.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("Skype with "),
                    _c("strong", [_vm._v("Megan")]),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted mr-3" }, [
                    _c("i", { staticClass: "icon-calendar" }),
                    _vm._v("  4 - 5pm"),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _c("i", { staticClass: "icon-social-skype" }),
                    _vm._v("  On-line"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "transparent mx-3 my-0" }),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small",
                },
                [_vm._v("\n                Tomorrow\n            ")]
              ),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-danger list-group-item-divider",
                  attrs: { href: "#" },
                },
                [
                  _c("div", [
                    _vm._v("New UI Project - "),
                    _c("strong", [_vm._v("deadline")]),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted mr-3" }, [
                    _c("i", { staticClass: "icon-calendar" }),
                    _vm._v("  10 - 11pm"),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _c("i", { staticClass: "icon-home" }),
                    _vm._v("  creativeLabs HQ"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "avatars-stack mt-2" }, [
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/2.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/3.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/4.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/5.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/6.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-success list-group-item-divider",
                  attrs: { href: "#" },
                },
                [
                  _c("div", [
                    _c("strong", [_vm._v("#10 Startups.Garden")]),
                    _vm._v(" Meetup"),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted mr-3" }, [
                    _c("i", { staticClass: "icon-calendar" }),
                    _vm._v("  1 - 3pm"),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _c("i", { staticClass: "icon-location-pin" }),
                    _vm._v("  Palo Alto, CA"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "list-group-item-accent-primary list-group-item-divider",
                  attrs: { href: "#" },
                },
                [
                  _c("div", [_c("strong", [_vm._v("Team meeting")])]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted mr-3" }, [
                    _c("i", { staticClass: "icon-calendar" }),
                    _vm._v("  4 - 6pm"),
                  ]),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _c("i", { staticClass: "icon-home" }),
                    _vm._v("  creativeLabs HQ"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "avatars-stack mt-2" }, [
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/2.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/3.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/4.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/5.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/6.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "avatar avatar-xs" }, [
                      _c("img", {
                        staticClass: "img-avatar",
                        attrs: {
                          src: "img/avatars/8.jpg",
                          alt: "admin@bootstrapmaster.com",
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-tab",
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "icon-speech" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "p-3" }, [
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-badge", {
                      staticClass: "avatar-status",
                      attrs: { variant: "success" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Lukasz Holeczek"),
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet"),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n                    tempor incididunt...\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-badge", {
                      staticClass: "avatar-status",
                      attrs: { variant: "danger" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Lukasz Holeczek"),
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet"),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n                    tempor incididunt...\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-badge", {
                      staticClass: "avatar-status",
                      attrs: { variant: "info" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Lukasz Holeczek"),
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet"),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n                    tempor incididunt...\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-badge", {
                      staticClass: "avatar-status",
                      attrs: { variant: "warning" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Lukasz Holeczek"),
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet"),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n                    tempor incididunt...\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("img", {
                      staticClass: "img-avatar",
                      attrs: {
                        src: "img/avatars/7.jpg",
                        alt: "admin@bootstrapmaster.com",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-badge", {
                      staticClass: "avatar-status",
                      attrs: { variant: "dark" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Lukasz Holeczek"),
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted float-right mt-1" }, [
                  _vm._v("1:52 PM"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                _vm._v("Lorem ipsum dolor sit amet"),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n                    tempor incididunt...\n                "
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-tab",
        [
          _c("template", { slot: "title" }, [
            _c("i", { staticClass: "icon-settings" }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-3" },
            [
              _c("h6", [_vm._v("Settings")]),
              _vm._v(" "),
              _c("div", { staticClass: "aside-options" }, [
                _c(
                  "div",
                  { staticClass: "clearfix mt-4" },
                  [
                    _c("small", [_c("b", [_vm._v("Option 1")])]),
                    _vm._v(" "),
                    _c("c-switch", {
                      staticClass: "float-right",
                      attrs: {
                        color: "success",
                        label: "",
                        variant: "pill",
                        size: "sm",
                        checked: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do\n                        eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "aside-options" }, [
                _c(
                  "div",
                  { staticClass: "clearfix mt-3" },
                  [
                    _c("small", [_c("b", [_vm._v("Option 2")])]),
                    _vm._v(" "),
                    _c("c-switch", {
                      staticClass: "float-right",
                      attrs: {
                        color: "success",
                        label: "",
                        variant: "pill",
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do\n                        eiusmod tempor incididunt ut labore et dolore magna aliqua.\n                    "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "aside-options" }, [
                _c(
                  "div",
                  { staticClass: "clearfix mt-3" },
                  [
                    _c("small", [_c("b", [_vm._v("Option 3")])]),
                    _vm._v(" "),
                    _c("c-switch", {
                      staticClass: "float-right",
                      attrs: {
                        color: "success",
                        label: "",
                        variant: "pill",
                        size: "sm",
                        disabled: "",
                        defaultChecked: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("Disabled option."),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "aside-options" }, [
                _c(
                  "div",
                  { staticClass: "clearfix mt-3" },
                  [
                    _c("small", [_c("b", [_vm._v("Option 4")])]),
                    _vm._v(" "),
                    _c("c-switch", {
                      staticClass: "float-right",
                      attrs: {
                        color: "success",
                        label: "",
                        variant: "pill",
                        size: "sm",
                        checked: "",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("h6", [_vm._v("System Utilization")]),
              _vm._v(" "),
              _c("div", { staticClass: "text-uppercase mb-1 mt-4" }, [
                _c("small", [_c("b", [_vm._v("CPU Usage")])]),
              ]),
              _vm._v(" "),
              _c("b-progress", {
                staticClass: "progress-xs",
                attrs: { height: "{}", variant: "info", value: 25 },
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v("348 Processes. 1/4 Cores."),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                _c("small", [_c("b", [_vm._v("Memory Usage")])]),
              ]),
              _vm._v(" "),
              _c("b-progress", {
                staticClass: "progress-xs",
                attrs: { height: "{}", variant: "warning", value: 70 },
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v("11444GB/16384MB"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                _c("small", [_c("b", [_vm._v("SSD 1 Usage")])]),
              ]),
              _vm._v(" "),
              _c("b-progress", {
                staticClass: "progress-xs",
                attrs: { height: "{}", variant: "danger", value: 95 },
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v("243GB/256GB"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                _c("small", [_c("b", [_vm._v("SSD 2 Usage")])]),
              ]),
              _vm._v(" "),
              _c("b-progress", {
                staticClass: "progress-xs",
                attrs: { height: "{}", variant: "success", value: 10 },
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v("25GB/256GB"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }