import request from '@/front/shared/utils/request';

export function fetchList(blockId, query = {}) {
    return request({
        url: `/extra-column-values`,
        method: 'get',
        params: { ...query, block_id: blockId },
    });
}

export function show(extraColumnId, blockId) {
    return get(extraColumnId, blockId);
}

export function get(extraColumnId, blockId) {
    return request({
        url: `/extra-column-values`,
        method: 'get',
        params: {
            extra_column_id: extraColumnId,
            block_id: blockId,
        },
    });
}

export function update(data) {
    return request({
        url: `/extra-column-values`,
        method: 'post',
        data,
    });
}
