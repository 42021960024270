const GuestLayout = () => import('@/front/shared/views/layouts/GuestLayout');

export default [
    {
        path: '/user/snap_email',
        component: GuestLayout,
        children: [
            {
                path: '',
                name: 'SnapEmail',
                component: () => import('@/front/shared/views/pages/SnapEmail'),
            },
        ],
        meta: { guest: true },
    },
];
