function createAnimator(config, callback, done) {
    if (typeof config !== 'object') throw new TypeError('Arguement config expect an Object');

    let start = config.start,
        mid = { ...start }, //clone object
        math = { ...start }, //precalculate the math
        end = config.end,
        duration = config.duration || 1000,
        startTime,
        endTime;

    //t*(b-d)/(a-c) + (a*d-b*c)/(a-c);
    function precalculate(a, b, c, d) {
        return [(b - d) / (a - c), (a * d - b * c) / (a - c)];
    }

    function calculate(key, t) {
        return t * math[key][0] + math[key][1];
    }

    function step() {
        let t = Date.now();
        let val = end;
        if (t < endTime) {
            val = mid;
            for (let key in mid) {
                mid[key] = calculate(key, t);
            }
            callback(val);
            requestAnimationFrame(step);
        } else {
            callback(val);
            done && done();
        }
    }

    return function () {
        startTime = Date.now();
        endTime = startTime + duration;

        for (let key in math) {
            math[key] = precalculate(startTime, start[key], endTime, end[key]);
        }

        step();
    };
}

const Plugin = {
    install(Vue, options) {
        if (this.installed) {
            return;
        }

        const context = this;
        this.installed = true;
        this.init = false;
        this.$root = null;

        Vue.prototype.$app = {
            jumpTo(selector) {
                const el = document.querySelector(selector);
                let top = el.offsetTop;
                let left = el.offsetLeft;

                let animator = createAnimator(
                    {
                        start: [
                            document.documentElement.scrollLeft,
                            document.documentElement.scrollTop,
                        ],
                        end: [left, top],
                        duration: 400,
                    },
                    function (vals) {
                        window.scrollTo(vals[0], vals[1]);
                    },
                );

                animator();
            },
        };

        Vue.mixin({
            beforeCreate() {
                if (context.init) return;

                context.init = true;
                context.$root = this.$root;
            },
        });
    },
};

export default Plugin;
