import Vue from 'vue';
import Router from 'vue-router';

// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

import { constantRouterMap } from '@/admin/router/routes';

const router = new Router({
    // mode: 'history', // Require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap,
});

export default router;
