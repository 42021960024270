import * as paymentApi from '@/front/shared/api/payment';
import ModalTariffPaymentAdd from '@/front/shared/components/Modals/ModalTariffPaymentAdd';
import { SHOW_MODAL_TARIFF_PAYMENT_ADD } from '@/front/shared/event-names';

async function handleOk(result, playload) {
    const response = await paymentApi.orderPayment({
        order_id: playload.order_id,
        payment_id: playload.payment_id,
        payment_method_code: result.paymentMethodCode,
    });
    window.location.href = response.data.confirmation_url;
}

export default {
    components: {
        ModalTariffPaymentAdd,
    },
    created() {
        this.$root.$on(SHOW_MODAL_TARIFF_PAYMENT_ADD, this[SHOW_MODAL_TARIFF_PAYMENT_ADD]);
        this.$root.$once('hook:beforeDestroy', () => {
            this.$root.$off(SHOW_MODAL_TARIFF_PAYMENT_ADD, this[SHOW_MODAL_TARIFF_PAYMENT_ADD]);
        });
    },
    methods: {
        [SHOW_MODAL_TARIFF_PAYMENT_ADD](playload = {}) {
            this.$refs.modalTariffPaymentAdd.open({ playload, onOk: handleOk });
        },
    },
};
