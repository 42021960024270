export function addParam(url, param, value) {
    param = encodeURIComponent(param);
    let a = document.createElement('a');
    param += (value ? "=" + encodeURIComponent(value) : "");
    a.href = url;
    a.search += (a.search ? "&" : "") + param;
    return a.href;
}

export function addParams(url, params) {
    let paramsText = Object.keys(params).map(function (param) {
        param = encodeURIComponent(param);
        let value = params[param];
        param += (value ? "=" + encodeURIComponent(value) : "");
        return param;
    }).join('&');

    let a = document.createElement('a');
    a.href = url;
    a.search += (a.search ? "&" : "") + paramsText;
    return a.href;
}

function segments(url) {
    return (url || '').split('/').filter(item => item !== '');
}

function segment(url, index, def = null) {
    let _segments = segments(url);
    index = index - 1;
    if (_segments.length <= index) return def;
    return _segments[index];
}

export function getLocale() {
    const defaultLanguage = window.config['locale'];
    const languages = window.config['locales'];

    const uriLanguage = segment(window.location.pathname, 1);
    if (languages.indexOf(uriLanguage) !== -1) {
        if (uriLanguage !== defaultLanguage) {
            return uriLanguage;
        }
    }
    return defaultLanguage;
}

export function changeLocale(locale) {
    const languages = window.config['locales'];
    const uriLanguage = segment(window.location.pathname, 1);
    let _segments = segments(window.location.pathname);

    if (languages.indexOf(uriLanguage) !== -1) {
        _segments.shift();
    }

    _segments.unshift(locale);
    let pathname = _segments.join('/');

    return window.location.origin + '/' + pathname + window.location.hash;
}
