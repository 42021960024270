<template>
    <div
        class="chat-message_block"
        v-bind:class="[isActiveChat ? 'chat-is-openning' : 'chat-is-closing']"
    >
        <div class="chat-message_header d-flex justify-content-between">
            <div class="">
                <!--<i class="fa fa-times"></i>-->
                <i class="fa fa-minus" @click="isActiveChat = !isActiveChat"></i>
            </div>
            <div class="d-flex">
                <div class="ml-1" v-if="newMessages">
                    <i class="fa fa-envelope-o" @click="openUsers"></i>
                </div>
                <div class="ml-2" @click="header.chat = !header.chat" v-if="chatId">
                    <div v-if="isActiveChat">
                        <i class="fa fa-bars" v-if="header.chat"></i>
                        <i class="fa fa-commenting" v-else></i>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="chat-body"
            v-bind:class="[isActiveChat ? '' : 'd-none']"
            style="height: calc(100% - 26px)"
        >
            <div class="chat-message_users" v-if="header.chat === false">
                <ul class="list-group">
                    <li
                        v-for="user in chatUsers"
                        @click="getUserId(user)"
                        class="list-group-item d-flex justify-content-between"
                        :key="user.id"
                    >
                        <div class="chat-message_name">{{ user.name }}</div>
                        <div class="chat-message_queue" v-if="chatWindows[user.id].queue">
                            {{ chatWindows[user.id].queue }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="chat-message_chats" v-if="header.chat === true">
                <div class="h-100">
                    <!-- v-if="chatId"-->
                    <div
                        class="h-100"
                        v-bind:sendid="chatWindows[chatId].senderid"
                        v-bind:name="chatWindows[chatId].name"
                    >
                        <div class="panel panel-primary h-100">
                            <div
                                class="
                                    panel-heading
                                    chat-message_chats-user
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                                id="accordion"
                            >
                                <div
                                    class="chat-message_chats-user-avatar"
                                    :style="{
                                        backgroundImage: `url(${chatWindows[chatId].avatar})`,
                                    }"
                                    v-if="chatWindows[chatId].avatar.length"
                                ></div>
                                <div class="chat-message_chats-user-name ml-1">
                                    {{ chatWindows[chatId].name }}
                                </div>
                            </div>
                            <div class="panel-collapse chat-message_chats-body" id="collapseOne">
                                <div class="panel-body">
                                    <div class="chat h-100 pre-scrollable" id="chat" ref="scroll">
                                        <div
                                            class="text-center chat-loadable"
                                            v-if="chatWindows[chatId].loadable"
                                            @click="loadChatMessages(chatWindows[chatId].senderid)"
                                        >
                                            <i class="fa fa-angle-double-up"></i>
                                        </div>
                                        <div
                                            class="clearfix chat_message"
                                            v-for="chat in chats[chatWindows[chatId].senderid]"
                                            v-bind:message="chat.message"
                                            v-bind:username="chat.name"
                                        >
                                            <div
                                                v-bind:class="userMessageClass(chat.id)"
                                                class="d-flex"
                                            >
                                                <div
                                                    class="
                                                        d-flex
                                                        align-items-center
                                                        chat_message-text-created_date
                                                    "
                                                    v-if="
                                                        userMessageClass(chat.id) ===
                                                        'chat_message-right justify-content-end'
                                                    "
                                                >
                                                    {{ chat.created_date }}
                                                </div>
                                                <div class="chat_message-text">
                                                    {{ chat.message }}
                                                    <div class="chat_message-text-created_at">
                                                        {{ timeFormat(chat.created_at) }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        d-flex
                                                        align-items-center
                                                        chat_message-text-created_date
                                                    "
                                                    v-if="
                                                        userMessageClass(chat.id) ===
                                                        'chat_message-left justify-content-start'
                                                    "
                                                >
                                                    {{ chat.created_date }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-footer">
                                    <div class="d-flex align-items-center border-top p-1">
                                        <div class="chat-message_chats-input w-100">
                                            <input
                                                class="w-100 border-0"
                                                :id="chatWindows[chatId].senderid"
                                                v-model="chatMessage[chatWindows[chatId].senderid]"
                                                v-on:keyup.enter="sendMessage"
                                                type="text"
                                                placeholder="Message.."
                                            />
                                        </div>
                                        <div class="chat-message_chats-send">
                                            <button
                                                type="button"
                                                ref="btnSend"
                                                class=""
                                                :id="chatWindows[chatId].senderid"
                                                @click="sendMessage"
                                            >
                                                <i class="el-icon-caret-right"> </i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="chat-message_free d-flex justify-content-center align-items-center" v-else>
                    <div class="font-italic text-center" @click="header.chat=false">
                        <i class="fa fa-envelope-square"></i>
                        <p>Начать</p>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="hidden-audio">
            <audio id="hidden-audio" src="/img/message_sound.mp3"></audio>
            <button id="hidden-audio-click" @click="audioPlay" ref="audioButton"></button>
        </div>
    </div>
</template>

<script>
    import * as chatApi from '@/front/shared/api/chat';
    import { mapState } from 'vuex';
    import { DateTime } from 'luxon';

    export default {
        name: 'ChatMessage',
        data() {
            return {
                isActiveChat: false,
                chatId: 0,
                newMessages: 0,
                chatMessage: [],
                chats: {},
                chatWindows: {},
                chatWindowStatus: [],
                chatCount: [],
                loading: false,
                chatUsers: [],
                header: {
                    hide: false,
                    close: false,
                    chat: false,
                },
            };
        },
        computed: {
            ...mapState({ userId: (state) => state.user.id }),
        },
        created() {
            this.load();
            this.pusher();
        },
        http: {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        },
        methods: {
            load: function () {
                var avatar;
                this.loading = true;
                const promises = [];
                promises.push(chatApi.chatPage());
                Promise.all(promises)
                    .then(([{ users: users, messages: messages }]) => {
                        this.chatUsers = users;
                        for (let i = 0; i < users.length; i++) {
                            avatar = users[i].avatar ? users[i].avatar.url : '';
                            this.$set(this.chatWindows, users[i].id, {
                                senderid: users[i].id,
                                name: users[i].name,
                                avatar: avatar,
                                queue: 0,
                                loadable: true,
                            });
                        }
                        for (let i = messages.length - 1; i >= 0; i--) {
                            var chatID = messages[i].id;
                            if (chatID === this.userId) {
                                chatID = messages[i].sourceuserid;
                            }
                            if (!this.chats[chatID]) {
                                this.createChatWindow(chatID, false);
                            }
                            if (!messages[i].watched && chatID === messages[i].id)
                                this.chatWindows[chatID].queue = this.chatWindows[chatID].queue + 1;
                            this.chats[chatID].push(messages[i]);
                            this.chatCount[chatID]++;
                        }
                        this.newMessage();
                        this.scrollDown();
                    })
                    .finally(() => (this.loading = false));
            },
            pusher: function () {
                window.Echo.channel('chat-message' + this.userId).listen('ChatMessage', (e) => {
                    console.log(e.user);
                    var userId = e.user.id;
                    if (!this.chats[userId]) {
                        this.createChatWindow(e.user.id, false);
                    }
                    this.chats[userId].push(e.user);
                    this.chatCount[userId]++;
                    if (
                        this.chatId !== userId ||
                        (this.header.chat === false && this.chatId === userId)
                    ) {
                        this.chatWindows[userId].queue = this.chatWindows[userId].queue + 1;
                        var lastUser = [];
                        this.chatUsers.find((o, i) => {
                            if (o.id === userId) {
                                lastUser = this.chatUsers[i];
                                this.chatUsers.splice(i, 1);
                                return true;
                            }
                        });
                        this.chatUsers.unshift(lastUser);
                    } else {
                        this.setWatched(userId);
                    }
                    this.newMessage();
                    this.$refs.audioButton.click();
                    this.scrollDown();
                    console.log(this.chatWindows);
                });
            },
            getUserId(user) {
                this.header.chat = true;
                this.createChatWindow(user.id, true);
                this.scrollDown();
            },
            sendMessage(event) {
                var message = this.chatMessage[this.$refs.btnSend.id];
                var userId = this.$refs.btnSend.id;
                if (!message.trim()) return;
                var params = {
                    userid: userId,
                    message: message,
                };
                const promises = [];
                promises.push(chatApi.sendMessage(params));
                Promise.all(promises)
                    .then(([{ message: data }]) => {
                        this.chatMessage[userId] = '';
                        this.chats[userId].push({
                            message: data.message,
                            sourceuserid: data.sourceuserid,
                            id: data.id,
                            created_at: data.created_at,
                            created_date: data.created_date,
                            messageid: data.messageid,
                            read: data.watched,
                        });
                        this.chatCount[userId]++;
                        this.scrollDown();
                    })
                    .finally(() => (this.loading = false));
            },
            createChatWindow(userid, situation) {
                if (!this.chatWindowStatus[userid]) {
                    this.chatWindowStatus[userid] = 1;
                    this.chatMessage[userid] = '';
                    this.$set(this.chats, userid, []);
                    this.$set(this.chatCount, userid, 0);
                }
                if (situation) {
                    if (this.chatWindows[userid].queue) this.setWatched(userid);
                    this.chatWindows[userid].queue = 0;
                    this.chatId = parseInt(userid);
                    this.newMessage();
                }
            },
            userMessageClass: function (id) {
                if (this.userId === id) return 'chat_message-right justify-content-end';
                return 'chat_message-left justify-content-start';
            },
            newMessage: function () {
                var messagesCount = 0;
                for (var key in this.chatWindows) {
                    messagesCount = messagesCount + this.chatWindows[key].queue;
                }
                this.newMessages = messagesCount;
            },
            audioPlay: function () {
                var audio = document.getElementById('hidden-audio');
                audio.play();
            },
            scrollDown: function () {
                if (this.chatId && this.header.chat)
                    this.$nextTick(() => {
                        this.$refs.scroll.scrollTop =
                            this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight;
                    });
            },
            loadChatMessages: function (sended_to) {
                var last_id = 0;
                if (this.chats[this.chatWindows[sended_to].senderid][0])
                    last_id = this.chats[this.chatWindows[sended_to].senderid][0].messageid;

                var params = {
                    sended_to: sended_to,
                    last_id: last_id,
                };

                const promises = [];

                promises.push(chatApi.loadMore(params));
                Promise.all(promises)
                    .then(([{ data: data }]) => {
                        if (data.length === 0) {
                            this.chatWindows[sended_to].loadable = false;
                        }
                        for (var i = 0; i < data.length; i++) {
                            this.chats[sended_to].unshift({
                                message: data[i].message,
                                sourceuserid: data[i].sourceuserid,
                                id: data[i].id,
                                created_at: data[i].created_at,
                                created_date: data[i].created_date,
                                messageid: data[i].messageid,
                                watched: data[i].read,
                            });
                            this.chatCount[sended_to]++;
                        }
                    })
                    .finally(() => (this.loading = false));
            },
            setWatched: function (sender) {
                var params = {
                    receiver: this.userId,
                    sender: parseInt(sender),
                };
                chatApi.setWatched(params);
            },
            timeFormat: function (date) {
                var date = new Date(DateTime.fromISO(date, { zone: 'utc' }).toLocal());
                var hour = date.getHours();
                var minute = date.getMinutes();
                return (
                    (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute)
                );
            },
            openUsers: function () {
                this.isActiveChat = true;
                this.header.chat = false;
            },
        },
    };
</script>

<style>
    .chat-message_block {
        bottom: 0;
        right: 5px;
        position: fixed;
        width: 300px;
        /*height: 400px;*/
        background-color: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 2px solid #2c497b;
        z-index: 10;
    }

    .chat-message_header {
        background-color: #2c497b;
        padding: 3px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .chat-message_header .fa {
        color: white;
    }

    .chat-message_free {
        height: 100%;
    }

    .chat-message_free .fa {
        font-size: 18px;
    }

    .chat-message_free > div {
        cursor: pointer;
    }

    .chat-message_chats {
        height: 100%;
    }

    .chat-message_chats-user {
        height: 40px;
        background-color: #acc7e6;
        padding: 5px;
        text-align: center;
    }

    .chat-message_chats-body {
        height: calc(100% - 40px);
    }

    .chat-message_chats-body .panel-body {
        padding-bottom: 5px;
        height: calc(100% - 38px);
    }

    .chat_message-left {
        text-align: left;
        margin: 2px auto 2px 5px;
    }

    .chat_message-right {
        text-align: right;
        margin: 2px 5px 2px auto;
    }

    .chat_message-right .chat_message-text {
        background-color: #d9e1ff;
    }

    .chat_message-left .chat_message-text {
        background-color: #fff;
    }

    .chat_message > div {
        max-width: 80%;
    }

    .chat_message-text {
        display: inline-block;
        padding: 3px;
        border-radius: 5px;
        border: 1px solid gainsboro;
    }

    .chat-message_chats-send > button {
        border: none;
        background-color: #fff;
    }

    .chat-message_chats-send > button > i {
        font-size: 28px;
        color: #2c497b;
    }

    .chat-message_chats-user-avatar {
        width: 30px;
        height: 30px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
    }

    .chat_message-text-created_at {
        font-size: 11px;
    }

    .chat_message-text-created_date {
        font-size: 11px;
        margin-left: 0.3em;
        margin-right: 0.3em;
    }

    .chat-message_queue {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background: orange;
        color: #fff;
        text-align: center;
        font-size: 11px;
        padding: 2px;
        font-weight: bold;
    }

    .hidden-audio {
        display: none;
    }

    .chat-is-openning {
        height: 400px;
    }

    .chat-is-closing {
        height: auto;
    }

    .chat {
        scrollbar-width: none;
    }

    .chat::-webkit-scrollbar {
        width: 0;
    }

    .chat-loadable {
        padding-top: 5px;
        cursor: pointer;
    }

    .chat-loadable:hover {
        background-color: #ccd9e6;
    }

    .chat-message_users {
        overflow: auto;
        height: 100%;
    }

    .fa.fa-envelope-o {
        -webkit-animation: blinkMessage 2s linear infinite;
        animation: blinkMessage 2s linear infinite;
    }

    @-webkit-keyframes blinkMessage {
        0% {
            color: rgb(247, 247, 20);
        }
        50% {
            color: rgb(247, 202, 20);
        }
        100% {
            color: rgb(247, 164, 20);
        }
    }

    @keyframes blinkMessage {
        0% {
            color: rgb(247, 247, 20);
        }
        50% {
            color: rgb(247, 202, 20);
        }
        100% {
            color: rgb(247, 164, 20);
        }
    }
</style>
