<template>
    <div class="app">
        <template v-if="isAnon">
            <AppHeader fixed>
                <div class="container">
                    <a class="navbar-brand" :href="site_url">
                        <img src="/img/brand/logo-big.png" width="120" height="35" alt="" />
                    </a>

                    <b-navbar-nav class="ml-auto">
                        <DefaultHeaderLanguage></DefaultHeaderLanguage>
                        <b-nav-item href="/login">{{ $t('common.login') }}</b-nav-item>
                    </b-navbar-nav>
                </div>
            </AppHeader>
        </template>
        <template v-else>
            <AppHeader fixed>
                <SidebarToggler class="d-md-none" display="sm" mobile />
                <SidebarToggler class="d-sm-down-none" display="md" :defaultOpen="true" />

                <a class="navbar-brand" :href="site_url">
                    <img src="/img/brand/logo-big.png" width="120" height="35" alt="" />
                </a>

                <b-navbar-nav class="ml-auto">
                    <DefaultHeaderLanguage />
                    <DefaultHeaderDropdownAccnt />
                </b-navbar-nav>
            </AppHeader>
        </template>

        <div class="app-body">
            <template v-if="!isAnon">
                <AppSidebar fixed>
                    <SidebarHeader />
                    <SidebarForm />
                    <SidebarNav :navItems="nav"></SidebarNav>
                    <SidebarFooter />
                    <SidebarMinimizer />
                </AppSidebar>
            </template>

            <main class="main">
                <div class="main-container">
                    <router-view></router-view>
                </div>
            </main>
        </div>

        <template v-if="!isAnon">
            <chat-message></chat-message>
            <modal-add-invite ref="modalAddInvite"></modal-add-invite>
            <modal-collaboration ref="modalCollaboration"></modal-collaboration>
        </template>
    </div>
</template>

<script>
    import nav from '@/front/sibur/_nav';
    import {
        Header as AppHeader,
        SidebarToggler,
        Sidebar as AppSidebar,
        SidebarFooter,
        SidebarForm,
        SidebarHeader,
        SidebarNav,
        Breadcrumb,
    } from '@coreui/vue';
    import SidebarMinimizer from '@/front/shared/components/coreui/SidebarMinimizer';
    import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
    import DefaultAside from '@/front/shared/views/layouts/partials/DefaultAside';
    import DefaultHeaderDropdownAccnt from '@/front/sibur/views/layouts/partials/DefaultHeaderDropdownAccnt';
    import DefaultHeaderLanguage from '@/front/shared/views/layouts/partials/DefaultHeaderLanguage';
    import ModalAddInvite from '@/front/shared/components/Modals/ModalAddInvite';
    import ModalCollaboration from '@/front/shared/components/Modals/ModalCollaboration';
    import ModalInviteMixin from '@/front/shared/views/layouts/mixins/ModalInviteMixin';
    import ModalCollaborationMixin from '@/front/shared/views/layouts/mixins/ModalCollaborationMixin';
    import DefaultBalanceHeader from '@/front/shared/views/layouts/partials/DefaultBalanceHeader';
    import ChatMessage from '@/front/shared/views/layouts/partials/ChatMessage';
    import { mapGetters } from 'vuex';
    import global from '@/front/shared/global';

    export default {
        name: 'DefaultLayout',
        mixins: [ModalInviteMixin, ModalCollaborationMixin],
        components: {
            DefaultBalanceHeader,
            ModalAddInvite,
            DefaultHeaderLanguage,
            AppHeader,
            AppSidebar,
            Breadcrumb,
            DefaultAside,
            DefaultHeaderDropdownAccnt,
            SidebarForm,
            SidebarFooter,
            SidebarToggler,
            SidebarHeader,
            SidebarNav,
            SidebarMinimizer,
            AppHeaderDropdown,
            ModalCollaboration,
            ChatMessage,
        },
        data() {
            return {
                site_url: global.site_url,
            };
        },
        computed: {
            ...mapGetters(['isAnon', 'subscription']),

            teams() {
                return this.$store.state.user.teams;
            },
            app() {
                return this.$store.state.app;
            },
            nav() {
                let items = this.nestedFilter(nav.items);
                let item = items.find((item) => item.trans === 'nav.teams_sessions');
                if (item) {
                    item.children = this.teams.map((item) => {
                        return {
                            name: item.name,
                            attributes: {
                                disabled: item.locked,
                            },
                            icon: item.locked ? 'icon-lock' : '',
                            url: `/team/${item.id}`,
                        };
                    });

                    if (!item.children.length) {
                        items = items.filter((item) => item.trans !== 'nav.teams_sessions');
                    }
                }

                let self = this;
                nested(items);

                function nested(items) {
                    (items || []).forEach((item) => {
                        item.name = (item.trans && self.$t(item.trans)) || item.name;
                        nested(item.children);
                    });
                }

                return items;
            },
            name() {
                return this.$route.name;
            },
            list() {
                return this.$route.matched.filter((route) => route.name || route.meta.label);
            },
        },
        methods: {
            nestedFilter(items) {
                return (items || [])
                    .filter(
                        (item) =>
                            !item.visible || item.visible(this.subscription, this.app, this.teams),
                    )
                    .map((item) => {
                        if (item.children) {
                            item.children = this.nestedFilter(item.children);
                        }
                        return item;
                    });
            },
        },
    };
</script>
