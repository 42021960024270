import { isAdminOfAnyTeam } from '@/front/sibur/utils/misc';

export default {
    items: [
        {
            name: 'Мои сценарии',
            trans: 'nav.my_sessions',
            url: '/default',
            icon: 'nav-icon icon-user-following',
        },
        {
            name: 'Корпоративные сценарии',
            //url: '/default',
            trans: 'nav.teams_sessions',
            icon: 'nav-icon icon-people',
            children: [],
        },
        {
            name: 'Мастер выбора',
            url: '/selection-wizard',
            trans: 'nav.selection_wizard',
            icon: 'nav-icon icon-people',
        },
        {
            name: 'Заблокированные',
            url: '/locked',
            trans: 'nav.locked',
            icon: 'nav-icon icon-lock',
            visible: function (subscription, app) {
                return app.hasLockedSessions;
            },
        },
        {
            name: 'Инструкции',
            url: '/instructions',
            trans: 'nav.instructions',
            icon: 'nav-icon icon-graduation',
            visible: function (subscription) {
                return subscription.canUseCooperativeScenarios;
            },
        },
        {
            name: 'Корпоративная библиотека',
            url: '/corporate-library',
            trans: 'nav.corporateLibrary',
            icon: 'nav-icon icon-graduation',
            visible: function (subscription, app, teams) {
                return subscription.canUseCooperativeScenarios && isAdminOfAnyTeam(teams);
            },
            children: [
                {
                    name: 'Корпоративные сессии',
                    url: '/corporate-library/sessions',
                    trans: 'nav.corporate_sessions',
                    icon: '',
                },
            ],
        },
    ],
};
