import autosize from 'autosize';

export default {
    bind(el, binding, vnode) {
        el.rows = 1;
    },
    inserted(el) {
        autosize(el);
    },
    unbind(el) {
        autosize.destroy(el);
    },
};
