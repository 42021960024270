import router from './router';
import store from './store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { loadLanguageAsync } from '@/admin/lang';

NProgress.configure({ showSpinner: true }); // NProgress Configuration

const whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

router.beforeEach((to, from, next) => {
    loadLanguageAsync(store.getters.language).then(() => next());
});

router.beforeEach((to, from, next) => {
    NProgress.start();

    if (!store.getters.loggedIn) {
        if (whiteList.includes(to.path)) {
            next();
            NProgress.done();
            return;
        }

        next({
            path: '/login',
            query: { redirect: to.fullPath },
        });

        NProgress.done();
        return;
    }

    if (to.path === '/login') {
        // Skip login page for logged users
        next({ path: '/' });
        NProgress.done();
        return;
    }

    if (to.matched.some((record) => !!record.meta.role)) {
        if (to.matched.some((record) => record.meta.role === store.getters.role)) {
            next();
            return;
        }
        next('/');
        return;
    }

    next();
});

// After router hooks are resolved, finish progress bar
router.afterEach(() => {
    NProgress.done();
});
