import Vue from 'vue';
import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import ElFormItemExtended from '@/admin/components/ElFormItemExtended'
import FormWrapper from '@/admin/components/FormWrapper'

const attributes = {
    __default: 'attributes',
    email: "Email",
    name: "cards.name",
    first_name: "First name",
    last_name: "Last name"
};

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
    i18n: 'validations',
    template: templates.singleErrorExtractor.bootstrap3,
    i18nAttributes: attributes,
});

Vue.component("FormWrapper", FormWrapper);
Vue.component("ElFormItemExtended", ElFormItemExtended);
