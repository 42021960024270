var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-message_block",
      class: [_vm.isActiveChat ? "chat-is-openning" : "chat-is-closing"],
    },
    [
      _c(
        "div",
        { staticClass: "chat-message_header d-flex justify-content-between" },
        [
          _c("div", {}, [
            _c("i", {
              staticClass: "fa fa-minus",
              on: {
                click: function ($event) {
                  _vm.isActiveChat = !_vm.isActiveChat
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _vm.newMessages
              ? _c("div", { staticClass: "ml-1" }, [
                  _c("i", {
                    staticClass: "fa fa-envelope-o",
                    on: { click: _vm.openUsers },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.chatId
              ? _c(
                  "div",
                  {
                    staticClass: "ml-2",
                    on: {
                      click: function ($event) {
                        _vm.header.chat = !_vm.header.chat
                      },
                    },
                  },
                  [
                    _vm.isActiveChat
                      ? _c("div", [
                          _vm.header.chat
                            ? _c("i", { staticClass: "fa fa-bars" })
                            : _c("i", { staticClass: "fa fa-commenting" }),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chat-body",
          class: [_vm.isActiveChat ? "" : "d-none"],
          staticStyle: { height: "calc(100% - 26px)" },
        },
        [
          _vm.header.chat === false
            ? _c("div", { staticClass: "chat-message_users" }, [
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.chatUsers, function (user) {
                    return _c(
                      "li",
                      {
                        key: user.id,
                        staticClass:
                          "list-group-item d-flex justify-content-between",
                        on: {
                          click: function ($event) {
                            return _vm.getUserId(user)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "chat-message_name" }, [
                          _vm._v(_vm._s(user.name)),
                        ]),
                        _vm._v(" "),
                        _vm.chatWindows[user.id].queue
                          ? _c("div", { staticClass: "chat-message_queue" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.chatWindows[user.id].queue) +
                                  "\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.header.chat === true
            ? _c("div", { staticClass: "chat-message_chats" }, [
                _c("div", { staticClass: "h-100" }, [
                  _c(
                    "div",
                    {
                      staticClass: "h-100",
                      attrs: {
                        sendid: _vm.chatWindows[_vm.chatId].senderid,
                        name: _vm.chatWindows[_vm.chatId].name,
                      },
                    },
                    [
                      _c("div", { staticClass: "panel panel-primary h-100" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "panel-heading chat-message_chats-user d-flex justify-content-center align-items-center",
                            attrs: { id: "accordion" },
                          },
                          [
                            _vm.chatWindows[_vm.chatId].avatar.length
                              ? _c("div", {
                                  staticClass: "chat-message_chats-user-avatar",
                                  style: {
                                    backgroundImage: `url(${
                                      _vm.chatWindows[_vm.chatId].avatar
                                    })`,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "chat-message_chats-user-name ml-1",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.chatWindows[_vm.chatId].name) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "panel-collapse chat-message_chats-body",
                            attrs: { id: "collapseOne" },
                          },
                          [
                            _c("div", { staticClass: "panel-body" }, [
                              _c(
                                "div",
                                {
                                  ref: "scroll",
                                  staticClass: "chat h-100 pre-scrollable",
                                  attrs: { id: "chat" },
                                },
                                [
                                  _vm.chatWindows[_vm.chatId].loadable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center chat-loadable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadChatMessages(
                                                _vm.chatWindows[_vm.chatId]
                                                  .senderid
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-angle-double-up",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.chats[
                                      _vm.chatWindows[_vm.chatId].senderid
                                    ],
                                    function (chat) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass: "clearfix chat_message",
                                          attrs: {
                                            message: chat.message,
                                            username: chat.name,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex",
                                              class: _vm.userMessageClass(
                                                chat.id
                                              ),
                                            },
                                            [
                                              _vm.userMessageClass(chat.id) ===
                                              "chat_message-right justify-content-end"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center chat_message-text-created_date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            chat.created_date
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "chat_message-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(chat.message) +
                                                      "\n                                                "
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "chat_message-text-created_at",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.timeFormat(
                                                              chat.created_at
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.userMessageClass(chat.id) ===
                                              "chat_message-left justify-content-start"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center chat_message-text-created_date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            chat.created_date
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "panel-footer" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center border-top p-1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "chat-message_chats-input w-100",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.chatMessage[
                                                _vm.chatWindows[_vm.chatId]
                                                  .senderid
                                              ],
                                            expression:
                                              "chatMessage[chatWindows[chatId].senderid]",
                                          },
                                        ],
                                        staticClass: "w-100 border-0",
                                        attrs: {
                                          id: _vm.chatWindows[_vm.chatId]
                                            .senderid,
                                          type: "text",
                                          placeholder: "Message..",
                                        },
                                        domProps: {
                                          value:
                                            _vm.chatMessage[
                                              _vm.chatWindows[_vm.chatId]
                                                .senderid
                                            ],
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.sendMessage.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.chatMessage,
                                              _vm.chatWindows[_vm.chatId]
                                                .senderid,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "chat-message_chats-send" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          ref: "btnSend",
                                          attrs: {
                                            type: "button",
                                            id: _vm.chatWindows[_vm.chatId]
                                              .senderid,
                                          },
                                          on: { click: _vm.sendMessage },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-caret-right",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "hidden-audio" }, [
        _c("audio", {
          attrs: { id: "hidden-audio", src: "/img/message_sound.mp3" },
        }),
        _vm._v(" "),
        _c("button", {
          ref: "audioButton",
          attrs: { id: "hidden-audio-click" },
          on: { click: _vm.audioPlay },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }