import Cookies from 'js-cookie';

const app = {
    state: {
        language: Cookies.get('language') || 'ru',
        hasLockedSessions: false,
    },
    mutations: {
        SET_LANGUAGE: (state, language) => {
            state.language = language;
            Cookies.set('language', language);
        },
        SET_HAS_LOCKED_SESSIONS: (state, value) => {
            state.hasLockedSessions = value;
        },
    },
    actions: {
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language);
        },
        setHasLockedSessions({ commit }, value) {
            commit('SET_HAS_LOCKED_SESSIONS', value);
        },
    },
};

export default app;
