<template>
    <b-modal
        ref="modal"
        :title="title"
        v-model="visible"
        size="lg"
        :busy="busy"
        @hidden="handleHidden"
        @ok="handleOk"
        :okTitle="$t('dialog.send')"
        :cancelTitle="$t('dialog.close')"
    >
        <form-wrapper :validator="$v.form" v-loading="busy">
            <form-group name="email" :label="$t('form.email')" :attribute="$t('form.email')">
                <b-form-input type="email" v-model="form.email"></b-form-input>
            </form-group>

            <form-group
                name="subject"
                :label="$t('form.email_subject')"
                :attribute="$t('form.email_subject')"
            >
                <b-form-input type="email" v-model="form.subject"></b-form-input>
            </form-group>

            <form-group
                name="message"
                :label="$t('form.email_message')"
                :attribute="$t('form.email_message')"
            >
                <b-form-textarea v-model="form.message" rows="10"></b-form-textarea>
            </form-group>
        </form-wrapper>
    </b-modal>
</template>

<script>
    import FormValidationMixin from '@/front/shared/components/FormValidationMixin';
    import { required, email } from 'vuelidate/lib/validators';
    import * as invitationApi from '@/front/shared/api/invitation';
    import * as teamCollaborationInvitationApi from '@/front/shared/api/teamCollaborationInvitation';
    import * as sessionCollaborationInvitation from '@/front/shared/api/sessionCollaborationInvitation';
    import i18n from '@/front/shared/lang';

    export default {
        name: 'ModalAddInvite',
        extends: FormValidationMixin,
        data() {
            return {
                title: this.$i18n.t('invitation.regular.title'),
                visible: false,
                mode: 'regular',
                form: { ...this.defaultForm() },
                busy: false,
            };
        },
        validations() {
            return {
                form: {
                    email: { required, email },
                    subject: { required },
                    message: { required },
                },
            };
        },
        created() {
            this.playload = null;
            this.onOk = null;
            this.onHidden = null;
        },
        methods: {
            open({ mode = 'regular', playload = null, onOk = null, onHidden = null, email = '' }) {
                this.reset();
                let team = (playload && playload.team) || '';
                let session = (playload && playload.session) || '';
                this.form = {
                    email,
                    subject: i18n.t(`invitation.${mode}.subject`),
                    message: i18n.t(`invitation.${mode}.message`, {
                        user: this.$store.getters.name,
                        team,
                        session,
                    }),
                };
                this.mode = mode;
                this.playload = playload;
                this.onOk = onOk;
                this.onHidden = onHidden;
                this.visible = true;
            },
            close() {
                this.visible = false;
            },
            defaultForm() {
                return {
                    email: '',
                    subject: i18n.t('invitation.regular.subject'),
                    message: i18n.t('invitation.regular.message'),
                };
            },
            reset() {
                this.form = { ...this.defaultForm() };
                this.mode = 'regular';
                this.playload = null;
                this.onOk = null;
                this.onHidden = null;
                this.$v.$reset();
            },
            handleHidden(e) {
                if (_.isFunction(this.onHidden)) {
                    this.onHidden(this, this.playload, this.mode);
                }
                this.reset();
            },
            async handleOk(e) {
                this.busy = true;
                try {
                    e.preventDefault();

                    const valid = await this.validation();
                    if (!valid) {
                        this.$mnotify.warning(this.$t('errors.correct_form_errors'));
                        return false;
                    }

                    let response = null;
                    if (this.mode === 'session') {
                        response = await sessionCollaborationInvitation.store(
                            this.playload.sessionId,
                            {
                                ...this.form,
                            },
                        );
                    } else if (this.mode === 'team') {
                        response = await teamCollaborationInvitationApi.store(
                            this.playload.teamId,
                            {
                                ...this.form,
                            },
                        );
                    } else {
                        response = await invitationApi.create(
                            this.form.email,
                            this.form.subject,
                            this.form.message,
                        );
                    }

                    let result = true;
                    let resultData = response;
                    if (_.isFunction(this.onOk)) {
                        result = await this.onOk(resultData, this, this.playload, this.mode);
                    }

                    if (result === false) return false;
                    this.$emit('ok', {
                        data: resultData,
                        playload: this.playload,
                        mode: this.mode,
                    });
                    this.visible = false;
                } finally {
                    this.busy = false;
                }
            },
        },
    };
</script>
