import { pluralize } from '@/admin/filters';

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }

    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (('' + time).length === 10) {
            time = parseInt(time) * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][value];
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }

        return value || 0;
    });

    return timeStr;
}

export function formatTime(time, option) {
    time = +time * 1000;
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return 'Just now';
    } else if (diff < 3600) {
        // less 1 hour
        return pluralize(Math.ceil(diff / 60), ' minute') + ' ago';
    } else if (diff < 3600 * 24) {
        return pluralize(Math.ceil(diff / 3600), ' hour') + ' ago';
    } else if (diff < 3600 * 24 * 2) {
        return '1 day ago';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            pluralize(d.getMonth() + 1, ' month') + ' ' +
            pluralize(d.getDate(), ' day') + ' ' +
            pluralize(d.getHours(), ' day') + ' ' +
            pluralize(d.getMinutes(), ' minute')
        );
    }
}

const now = Date.now() / 3600;
console.log(formatTime(now - 3600));

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;

    const later = function () {
        // According to the last trigger interval
        const last = new Date().getTime() - timestamp;

        // The last time the wrapped function was called, the interval is last less than the set time interval wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            // If it is set to immediate===true, since the start boundary has already been called, there is no need to call it here.
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) {
                    context = args = null;
                }
            }
        }
    };

    return function (...args) {
        context = this;
        timestamp = new Date().getTime();
        const callNow = immediate && !timeout;
        // If the delay does not exist, reset the delay
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}
