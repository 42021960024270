import request from '@/front/shared/utils/request';

export function recover(data) {
    return request({
        url: '/auth/recover',
        method: 'post',
        data,
    });
}

export function reset(data) {
    return request({
        url: '/auth/reset',
        method: 'post',
        data,
    });
}

export function signup(data) {
    return request({
        url: '/auth/signup',
        method: 'post',
        data,
    });
}

export function login(email, password) {
    return request({
        url: '/auth/login',
        method: 'post',
        data: {
            email,
            password,
        },
    });
}

export function getInfo() {
    return request({
        url: '/auth/user',
        method: 'get',
    });
}

export function logout() {
    return request({
        url: '/auth/logout',
        method: 'post',
    });
}

export function check() {
    return request({
        url: '/auth/check',
        method: 'post',
    });
}
