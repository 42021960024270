const Plugin = {
    install(Vue, options) {
        if (this.installed) {
            return;
        }

        const context = this;
        this.installed = true;
        this.init = false;
        this.$root = null;

        const msgBoxOkOptions = {
            size: 'md',
            headerClass: 'border-bottom-0',
            footerClass: 'border-top-0',
            centered: true,
        };
        const msgBoxConfirmOptions = {
            size: 'md',
            headerClass: 'border-bottom-0',
            footerClass: 'border-top-0',
            centered: true,
            title: 'Подтвердите',
        };
        const toastOptions = {
            timeout: 6000,
            progressbar: false,
            position: 'toast-top-right',
        };

        Vue.prototype.$mnotify = {
            toast(message, options = {}) {
                options = { ...toastOptions, ...options };
                return context.$root.$toastr.Add({ ...options, msg: message });
            },
            toastClose(data) {
                context.$root.$toastr.Close(data);
            },
            notifyWait(promise, message = 'Подождите...', options = {}) {
                const notify = this.notify(message, options);
                return promise
                    .then((data) => {
                        return data;
                    })
                    .finally((data) => {
                        this.toastClose(notify);
                        return data;
                    });
            },
            success(message, options = {}) {
                return this.toast(message, {
                    type: 'success',
                    ...options,
                });
            },
            warning(message, options = {}) {
                return this.toast(message, {
                    type: 'warning',
                    ...options,
                });
            },
            error(message, options = {}) {
                return this.toast(message, {
                    type: 'error',
                    ...options,
                });
            },
            info(message, options = {}) {
                return this.toast(message, {
                    variant: 'info',
                    ...options,
                });
            },
            notify(message, options = {}) {
                return this.toast(message, {
                    type: 'notify',
                    position: 'toast-bottom-left',
                    ...options,
                });
            },
            msgBoxOk(message, options = {}) {
                options = { ...msgBoxOkOptions, ...options };
                return context.$root.$bvModal.msgBoxOk(message, options);
            },
            confirm(message, options = {}) {
                options = { ...msgBoxConfirmOptions, ...options };
                return context.$root.$bvModal.msgBoxConfirm(message, options);
            },
            confirmHtml(message, options = {}) {
                options = { ...msgBoxConfirmOptions, ...options };
                const messageHtml = context.$root.$createElement('div', {
                    domProps: { innerHTML: message },
                });
                return context.$root.$bvModal.msgBoxConfirm([messageHtml], options);
            },
            warningBox(message, options = {}) {
                return this.msgBoxOk(message, {
                    title: 'Предупреджение',
                    headerBgVariant: 'warning',
                    okVariant: 'warning',
                    ...options,
                });
            },
            errorBox(message, options = {}) {
                return this.msgBoxOk(message, {
                    title: 'Ошибка',
                    headerBgVariant: 'danger',
                    okVariant: 'danger',
                    ...options,
                });
            },
            successBox(message, options = {}) {
                return this.msgBoxOk(message, {
                    title: 'Успешно',
                    headerBgVariant: 'success',
                    okVariant: 'success ',
                    ...options,
                });
            },
            infoBox(message, options = {}) {
                return this.msgBoxOk(message, {
                    title: 'Информация',
                    headerBgVariant: 'info',
                    okVariant: 'info ',
                    ...options,
                });
            },
        };

        Vue.mixin({
            beforeCreate() {
                if (context.init) return;

                context.init = true;
                context.$root = this;
            },
        });
    },
};

export default Plugin;
