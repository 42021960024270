<template>
    <div class="tinymce-container editor-container">
        <tinymce-editor
            ref="tinymce"
            :value="value"
            @input="onChange"
            :init="init"
        ></tinymce-editor>
    </div>
</template>

<script>
    import toolbar from './toolbar';
    import { mapGetters } from 'vuex';
    import './plugins/charcount';

    export default {
        name: 'Tinymce',
        props: {
            value: {
                type: String,
                default: '',
            },
            toolbar: {
                type: Array | String,
                required: false,
                default() {
                    return [];
                },
            },
            plugins: {
                type: Array,
                required: false,
                default() {
                    return [];
                },
            },
            menubar: {
                type: [String, Boolean],
                default: 'file edit insert view format table',
            },
            height: {
                type: Number,
                required: false,
                default: 360,
            },
            textColorMap: {
                type: [Array, Boolean],
                required: false,
                default: false,
            },
            maxchar: Number,
            inline: Boolean,
            readonly: Boolean,
            insertToolbar: String,
            placeholder: String,
        },
        data() {
            return {
                init: this.initTinymce(),
            };
        },
        computed: {
            ...mapGetters(['language']),
        },
        watch: {
            readonly() {
                this.updateMode();
            },
        },
        mounted() {
            this.updateMode();
        },
        methods: {
            updateMode() {
                const mode = this.readonly ? 'readonly' : 'design';
                this.$refs.tinymce.editor.setMode(mode);
            },
            initTinymce() {
                let plugins = [...(this.plugins.length > 0 ? this.plugins : plugins)];
                if (this.maxchar) {
                    plugins.push('charcount');
                }

                const config = {
                    height: this.height,
                    content_css: '/css/tinymce-content.css',
                    body_class: 'panel-body ',
                    object_resizing: false,
                    inline: !!this.inline,
                    toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
                    menubar: this.menubar,
                    plugins,
                    end_container_on_empty_block: true,
                    powerpaste_word_import: 'clean',
                    powerpaste_html_import: 'clean',
                    code_dialog_height: 300,
                    code_dialog_width: 1000,
                    default_link_target: '_blank',
                    link_title: false,
                    branding: false,
                    gecko_spellcheck: true,
                    apply_source_formatting: false,
                    // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
                    nonbreaking_force_tab: true,
                    placeholder: this.placeholder ?? '',
                    valid_elements:
                        '@[class],a[href|target=_blank],strong/b,em/i,div,p[style],ol,li,ul,span[style],br,small',
                };

                if (this.textColorMap) {
                    config.textcolor_map = this.textColorMap;
                }

                if (this.insertToolbar != null) {
                    config.insert_toolbar = this.insertToolbar;
                }

                if (this.language !== 'en') {
                    config.language_url = '/js/tinymce/langs/ru.js';
                }

                this.$nextTick(() => {
                    this.$refs.tinymce.$el.removeAttribute('data-maxchar');
                    if (this.maxchar) {
                        this.$refs.tinymce.$el.setAttribute('data-maxchar', this.maxchar);
                    }
                });

                return config;
            },
            onChange(value) {
                this.$emit('input', value);
            },
        },
    };
</script>

<style scoped>
    .tinymce-container {
        position: relative;
        line-height: normal;
    }
</style>
