<template>
    <AppHeaderDropdown right>
        <template slot="header">
            <span style="text-transform: uppercase">{{ language }}</span>
        </template>

        <template slot="dropdown">
            <b-dropdown-item @click="handleSetLanguage('en')">EN</b-dropdown-item>
            <b-dropdown-item @click="handleSetLanguage('ru')">RU</b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DefaultHeaderLanguage',
        components: {
            AppHeaderDropdown,
        },
        computed: {
            ...mapGetters(['language']),
        },
        methods: {
            async handleSetLanguage(lang) {
                this.$i18n.locale = lang;
                this.$store.dispatch('setLanguage', lang);
                await this.$nextTick();
                location.reload();
            },
        },
    };
</script>
