import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(query = {}) {
    return request({
        url: '/card-figures',
        method: 'get',
        params: query,
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/card-figures/${id}`,
        method: 'get',
    });
}
