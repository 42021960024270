<template>
    <ul class="pay-methods">
        <li
            class="pay-methods__item"
            :class="{ 'pay-methods__item--selected': isSelected(item) }"
            v-for="item of list"
            @click="selected = item"
        >
            <div class="pay-methods__logo"><img :src="item.image" :alt="item.name" /></div>
            <div class="pay-methods__text">{{ item.name }}</div>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'PayMethods',
        props: {
            value: {
                type: Object,
            },
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        data() {
            return {
                selected: this.value,
            };
        },
        computed: {
            isSelected() {
                return (item) => item === this.selected;
            },
        },
        watch: {
            value(value) {
                this.selected = value;
            },
            selected() {
                this.$emit('input', this.selected);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pay-methods {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .pay-methods__item {
        display: flex;
        margin-top: 4px;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;

        &:first-child {
            margin-top: 0;
        }

        &--selected {
            background-color: #fffabb;
        }

        &:hover {
            border: 1px solid #f8e71c;
            cursor: pointer;
        }
    }

    .pay-methods__logo {
        display: flex;
        align-items: center;
        height: 45px;
        width: 50px;
        padding-left: 10px;
        padding-right: 10px;

        img {
            max-width: 100%;
            max-height: 30px;
            height: auto;
        }
    }

    .pay-methods__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        line-height: 1.1;
    }
</style>
