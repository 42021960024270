<template>
    <div class="tags-view-container">
        <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container tags-view-wrapper"
                      @wheel.native.prevent="handleScroll">
            <router-link
                    v-for="tag in visitedViews"
                    ref="tag"
                    :class="isActive(tag)?'active':''"
                    :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                    :key="tag.path"
                    tag="span"
                    class="tags-view-item"
                    @click.middle.native="closeSelectedTag(tag)"
                    @contextmenu.prevent.native="openMenu(tag,$event)">
                {{ generateTitle(tag.title) }}
                <span v-if="!tag.meta.affix" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)"/>
            </router-link>
        </el-scrollbar>
        <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
            <li @click="refreshSelectedTag(selectedTag)">{{ $t('tagsView.refresh') }}</li>
            <li v-if="!(selectedTag.meta&&selectedTag.meta.affix)" @click="closeSelectedTag(selectedTag)">{{
                $t('tagsView.close') }}
            </li>
            <li @click="closeOthersTags">{{ $t('tagsView.closeOthers') }}</li>
            <li @click="closeAllTags(selectedTag)">{{ $t('tagsView.closeAll') }}</li>
        </ul>
    </div>
</template>

<script>
    import { generateTitle } from '@/admin/utils/i18n';
    import path from 'path';

    const tagAndTagSpacing = 4; // tagAndTagSpacing
    export default {
        data() {
            return {
                visible: false,
                top: 0,
                left: 0,
                selectedTag: {},
                affixTags: [],
            };
        },
        computed: {
            visitedViews() {
                return this.$store.state.tagsView.visitedViews;
            },
            routers() {
                return this.$store.state.permission.routers;
            },
        },
        watch: {
            $route() {
                this.addTags();
                this.moveToCurrentTag();
            },
            visible(value) {
                if (value) {
                    document.body.addEventListener('click', this.closeMenu);
                } else {
                    document.body.removeEventListener('click', this.closeMenu);
                }
            },
        },
        mounted() {
            this.initTags();
            this.addTags();
        },
        methods: {
            generateTitle, // generateTitle by vue-i18n
            isActive(route) {
                return route.path === this.$route.path;
            },
            filterAffixTags(routes, basePath = '/') {
                let tags = [];
                routes.forEach(route => {
                    if (route.meta && route.meta.affix) {
                        tags.push({
                            fullPath: path.resolve(basePath, route.path),
                            path: path.resolve(basePath, route.path),
                            name: route.name,
                            meta: { ...route.meta },
                        });
                    }
                    if (route.children) {
                        const tempTags = this.filterAffixTags(route.children, route.path);
                        if (tempTags.length >= 1) {
                            tags = [...tags, ...tempTags];
                        }
                    }
                });

                return tags;
            },
            initTags() {
                const affixTags = this.affixTags = this.filterAffixTags(this.routers);
                for (const tag of affixTags) {
                    // Must have tag name
                    if (tag.name) {
                        this.$store.dispatch('addVisitedView', tag);
                    }
                }
            },
            addTags() {
                const { name } = this.$route;
                if (name) {
                    this.$store.dispatch('addView', this.$route);
                }
                return false;
            },
            moveToCurrentTag() {
                const tags = this.$refs.tag;
                this.$nextTick(() => {
                    for (const tag of tags) {
                        if (tag.to.path === this.$route.path) {
                            const $container = this.$refs.scrollContainer.$el;
                            const $containerWidth = $container.offsetWidth;
                            const $scrollWrapper = this.$refs.scrollContainer.$refs.wrap;

                            const firstTag = tags[0];
                            const lastTag = tags[tags.length - 1];

                            if (firstTag === tag) { // Current tag is the first one
                                $scrollWrapper.scrollLeft = 0;
                            } else if (lastTag === tag) { // Current tag is the last one
                                $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth;
                            } else {
                                // find preTag and nextTag
                                const currentIndex = tags.findIndex(item => item === tag);
                                const prevTag = tags[currentIndex - 1];
                                const nextTag = tags[currentIndex + 1];
                                // the tag's offsetLeft after of nextTag
                                const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing;

                                // the tag's offsetLeft before of prevTag
                                const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing;

                                if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
                                    $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth;
                                } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
                                    $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
                                }
                            }

                            // when query is different then update
                            if (tag.to.fullPath !== this.$route.fullPath) {
                                this.$store.dispatch('updateVisitedView', this.$route);
                            }

                            break;
                        }
                    }
                });
            },
            refreshSelectedTag(view) {
                this.$store.dispatch('delCachedView', view).then(() => {
                    const { fullPath } = view;
                    this.$nextTick(() => {
                        this.$router.replace({
                            path: '/redirect' + fullPath,
                        });
                    });
                });
            },
            closeSelectedTag(view) {
                this.$store.dispatch('delView', view).then(({ visitedViews }) => {
                    if (this.isActive(view)) {
                        this.toLastView(visitedViews);
                    }
                });
            },
            closeOthersTags() {
                this.$router.push(this.selectedTag);
                this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
                    this.moveToCurrentTag();
                });
            },
            closeAllTags(view) {
                this.$store.dispatch('delAllViews').then(({ visitedViews }) => {
                    if (this.affixTags.some(tag => tag.path === view.path)) {
                        return;
                    }
                    this.toLastView(visitedViews);
                });
            },
            toLastView(visitedViews) {
                const latestView = visitedViews.slice(-1)[0];
                if (latestView) {
                    this.$router.push(latestView);
                } else {
                    // You can set another route
                    this.$router.push('/');
                }
            },
            openMenu(tag, e) {
                const menuMinWidth = 105;
                const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
                const offsetWidth = this.$el.offsetWidth; // container width
                const maxLeft = offsetWidth - menuMinWidth; // left boundary
                const left = e.clientX - offsetLeft + 15; // 15: margin right

                if (left > maxLeft) {
                    this.left = maxLeft;
                } else {
                    this.left = left;
                }
                this.top = e.clientY;

                this.visible = true;
                this.selectedTag = tag;
            },
            closeMenu() {
                this.visible = false;
            },
            handleScroll(e) {
                const eventDelta = e.wheelDelta || -e.deltaY * 40;
                this.$refs.scrollContainer.$refs.wrap.scrollLeft = this.$refs.scrollContainer.$refs.wrap.scrollLeft + eventDelta / 4;
            },
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .tags-view-container {
        height: 34px;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #d8dce5;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

        .scroll-container {
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            width: 100%;

            > > > {
                .el-scrollbar__bar {
                    bottom: 0px;
                }

                .el-scrollbar__wrap {
                    height: 49px;
                }
            }
        }

        .tags-view-wrapper {
            .tags-view-item {
                display: inline-block;
                position: relative;
                cursor: pointer;
                height: 26px;
                line-height: 26px;
                border: 1px solid #d8dce5;
                color: #495060;
                background: #fff;
                padding: 0 8px;
                font-size: 12px;
                margin-left: 5px;
                margin-top: 4px;

                &:first-of-type {
                    margin-left: 15px;
                }

                &:last-of-type {
                    margin-right: 15px;
                }

                &.active {
                    background-color: #42b983;
                    color: #fff;
                    border-color: #42b983;

                    &::before {
                        content: '';
                        background: #fff;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 2px;
                    }
                }
            }
        }

        .contextmenu {
            margin: 0;
            background: #fff;
            z-index: 100;
            position: absolute;
            list-style-type: none;
            padding: 5px 0;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #333;
            box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

            li {
                margin: 0;
                padding: 7px 16px;
                cursor: pointer;

                &:hover {
                    background: #eee;
                }
            }
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss">
    //reset element css of el-icon-close
    .tags-view-wrapper {
        .tags-view-item {
            .el-icon-close {
                width: 16px;
                height: 16px;
                vertical-align: 2px;
                border-radius: 50%;
                text-align: center;
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
                transform-origin: 100% 50%;

                &:before {
                    transform: scale(.6);
                    display: inline-block;
                    vertical-align: -3px;
                }

                &:hover {
                    background-color: #b4bccc;
                    color: #fff;
                }
            }
        }
    }
</style>
