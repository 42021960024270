import Vue from 'vue';
import { isPlainObject, filter, find, get, first, uniqBy } from 'lodash';
import _ from 'lodash';
import * as apiSession from '@/front/shared/api/session';
import * as apiCard from '@/front/shared/api/card';
import * as apiBlock from '@/front/shared/api/block';
import * as apiComment from '@/front/shared/api/comment';
import * as apiCardFigure from '@/front/shared/api/cardFigure';
import * as apiExtraColumn from '@/front/shared/api/extraColumn';
import * as apiExtraColumnValue from '@/front/shared/api/extraColumnValue';
import { globalSequence, reOrdinal } from '@/front/shared/utils/misc';
import CalculateBlocksTiming from '../../classes/CalculateBlocksTiming';
import { buildSearch } from '@/common/utils';
import {
    createBlockDummy,
    expandBlock,
    extractExtraColumnValue,
    flatten,
    getBlockSelected,
    mapWithFilterIds,
    mapWithFilterIdsDeep,
    transformBlockSyncCard,
    buildNestedBlocks,
    prepareSession,
    isBlockDummy,
} from '@/front/shared/store/utils';
import * as collaborationRoleApi from '@/front/shared/api/collaborationRole';
import { isObjBlock, isObjSession, models } from '@/front/shared/utils/models';

let uidSeq = globalSequence;

const initState = {
    session: null,
    blockRequest: null,
    blockResult: null,
    sessions: {},
    activeDayId: '',
    listCards: [],
    teamCard: {},
    blocks: {},
    collaborations: {},
    blockRequestId: '',
    blockResultId: '',
    comments: {},
    extraColumns: {},
    extraColumnValues: {},
    listCardFigures: [],
    activeBlock: null,
    attachments: {},
    history: [],
    activeTab: 0,
    controlOnStart: false,
    collaborationRoles: [],
};

const state = {
    ..._.cloneDeep(initState),
    mini: false,
    modePullClone: false,
};

const getters = {
    mini: (state) => state.mini,
    isSessionLoad: (state) => (sessionId) => {
        return !!(state.sessions && state.sessions[sessionId]);
    },
    sessionId: (state) => state.session && state.session.id,
    sessionLoaded: (state) => !!(state.session && state.session.id),
    days: (state) => (session) => {
        if (session === undefined) session = state.session.id;
        let sessionId = isPlainObject(session) ? session.id : session;
        return (state.session && _.filter(state.sessions, ['parent_session_id', sessionId])) || [];
    },
    sessions: (state) =>
        (state.session &&
            _.sortBy(_.filter(state.sessions, ['parent_session_id', null]), 'sort_ordered')) ||
        [],
    firstDay: (state) => (session) =>
        _.first(
            _.sortBy(_.filter(state.sessions, ['parent_session_id', session.id]), 'sort_ordered'),
        ) || null,
    getSessionByBlock: (state) => (block) => {
        //console.log('getSessionByBlock', block);
        if (!block) return null;
        let session = state.sessions[block.session_id];
        if (!session) return null;
        if (session.parent_session_id === null) return session;
        return state.sessions[session.parent_session_id] || null;
    },
    getSessionId: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return isPlainObject(session) ? session.id : session;
    },
    activeDay: (state) => state.sessions[state.activeDayId],
    collaborations: (state, getters) => (session) =>
        _.filter(state.collaborations, ['session_id', getters.getSessionId(session)]),
    isCollaborator: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return session && session.is_collaborator;
    },
    isSessionAdmin: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return session && session.is_admin;
    },
    collaborationRoles: (state) => state.collaborationRoles,
    getBlockModuleGroup: (state) => (day) => {
        if (!day || !day.id) return null;

        return find(state.blocks, (item) => {
            return (
                item.kind === 2 &&
                item.type === 'module' &&
                item.parent_block_id === null &&
                item.session_id == day.id
            );
        });
    },
    getBlockModuleGroupActive: (state, getters) => {
        return getters.getBlockModuleGroup(getters.activeDay);
    },
    childBlocks: (state) => (id) => {
        return filter(state.blocks, ['parent_block_id', id]) || [];
    },
    listCardByType: (state) => (type) => state.listCards.filter((item) => item.type === type),
    listCardRequest: (state, getters) => getters.listCardByType('request'),
    listCardResult: (state, getters) => getters.listCardByType('result_format'),
    listCardModule: (state, getters) => getters.listCardByType('module'),
    listCardMethod: (state, getters) => getters.listCardByType('method'),
    hasTeam: (state) => (state.session && state.session.team_id) || false,

    blockRequestSelected: (state) => getBlockSelected(state.blocks, state.blockRequestId),
    blockResultSelected: (state) => getBlockSelected(state.blocks, state.blockResultId),

    blockByParent: (state, getters) => (parentId) => {
        if (!state.blocks) return null;
        return _.find(state.blocks, ['id', parentId]);
    },
    blocksRequest: (state) => {
        if (!state.blocks) return [];
        return _.filter(state.blocks, ['parent_block_id', state.blockRequestId]);
    },
    blocksResult: (state) => {
        if (!state.blocks) return [];
        return _.filter(state.blocks, ['parent_block_id', state.blockResultId]);
    },
    blocksModule: (state, getters) => {
        const blockModuleGroup = getters.getBlockModuleGroupActive;
        if (!state.blocks || !blockModuleGroup) return [];
        return _.filter(state.blocks, ['parent_block_id', blockModuleGroup.id]);
    },
    blocksMethod: (state) => (moduleGroupId) => {
        if (!state.blocks) return [];
        return _.filter(state.blocks, ['parent_block_id', moduleGroupId]);
    },

    blockRequest: (state) => state.blocks[state.blockRequestId],
    blockResult: (state) => state.blocks[state.blockResultId],
    blockExtraColumnValues: (state) => (block) => {
        return state.extraColumnValues[block.id];
    },
    getDayByBlock: (state) => (block) => {
        return recurse(block);

        function recurse(b) {
            if (b == null) {
                return null;
            }

            if (b.parent_block_id == null) {
                return state.sessions[b.session_id];
            }

            return recurse(state.blocks[b.parent_block_id]);
        }
    },

    commentByObject: (state) => (objectId, objectType) =>
        find(state.comments, ['commentable_id', objectId, 'commentable_type', objectType]),
    commentThreads: (state) => {
        let comments = _.cloneDeep(state.comments);
        let threads = _.filter(comments, (item) => item.commentable_type !== 'Comment');

        threads.forEach((thread) => {
            let filtered = _.filter(comments, [
                'commentable_id',
                thread.id,
                'commentable_type',
                'Comment',
            ]);
            thread['replies'] = _.orderBy(filtered, 'created_at', 'asc');
        });
        threads = _.orderBy(threads, 'created_at', 'asc');

        return threads;
    },
    commentSessionCount: (state, getters) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        let filtered = getters.commentThreads.filter(
            (item) =>
                item.commentable_type == 'Session' &&
                item.commentable_id == session.id &&
                item.status != 'new',
        );
        if (!filtered.length) return 0;

        return filtered.reduce((sum, curr) => {
            return sum + curr.replies.length + 1;
        }, 0);
    },
    commentCount: (state, getters) => (block) => {
        let filtered = getters.commentThreads.filter(
            (item) =>
                item.commentable_type == 'Block' &&
                item.commentable_id == block.id &&
                item.status != 'new',
        );
        if (!filtered.length) return 0;

        return filtered.reduce((sum, curr) => {
            return sum + curr.replies.length + 1;
        }, 0);
    },
    hasComments: (getters) => (block) => getters.commentCount(block) > 0,

    attachmentBy: (state) => (id, type) =>
        filter(state.attachments, ['uploadable_id', id, 'uploadable_type', type]),
    attachmentByBlock: (state, getters) => (blockId) => getters.attachmentBy(blockId, 'Block'),
    attachmentByBlockCount: (state, getters) => (blockId) =>
        getters.attachmentByBlock(blockId).length || 0,
    hasAttachmentByBlock: (getters) => (block) => getters.attachmentByBlockCount(block.id) > 0,
    getBlocksWithAttachment: (state, getters) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        let sessionId = session.id;
        let list = filter(state.attachments, ['session_id', sessionId]).map((item) => {
            if (item.uploadable_type == models.Block) {
                return state.blocks[item.uploadable_id];
            }
            return state.sessions[item.uploadable_id];
        });
        return uniqBy(list, 'id');
    },
    attachmentSessionCount: (state, getters) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        let sessionId = session.id;
        let filtered = filter(state.attachments, [
            'uploadable_id',
            sessionId,
            'uploadable_type',
            'Session',
        ]);
        return filtered.length;
    },

    getObjName: (state) => (obj) => {
        if (isObjBlock(obj)) return (state.blocks[obj.id] && state.blocks[obj.id].name) || '';
        if (isObjSession(obj)) return (state.sessions[obj.id] && state.sessions[obj.id].name) || '';
        return '';
    },
    getBlockName: (state) => (id) => (state.blocks[id] && state.blocks[id].name) || '',
    getBlock: (state) => (id) => state.blocks[id] || null,
    getSessionName: (state) => (id) => (state.sessions[id] && state.sessions[id].name) || '',
    sortedExtraColumns: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return (
            (session &&
                (_.filter(state.extraColumns, ['session_id', session.id]) || []).sort(
                    (a, b) => a.ordinal - b.ordinal,
                )) ||
            []
        );
    },
    sortedExtraColumnsByBlock: (state, getters) => (block) => {
        return getters.sortedExtraColumns(getters.getSessionByBlock(block));
    },
    history: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return (session && _.filter(state.history, ['session_id', session.id])) || [];
    },

    authUserId: (state, getters, rootState) => {
        return rootState.user.id;
    },
    canSessionEdit: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return session && session.can_edit;
    },
    canSessionPublish: (state) => (session) => {
        if (session === undefined) {
            session = state.session;
        }
        return session && session.can_publish;
    },
    canEdit: (state, getters) => getters.canSessionEdit(state.session),
    canPublish: (state, getters) => getters.canSessionPublish(state.session),
    isOwner: (state, getters) => {
        let session = state.session;
        return session && session.is_owner;
    },

    isCardRelatedToTeam:
        (state, getters) =>
        (card, team = null) => {
            if (team == null) {
                team = getters.activeIMemberOfTeam;
            }
            if (!team) return false;

            let teamCard = state.teamCard[team.id];
            if (!teamCard) return false;
            return !!teamCard[card.id];
        },
    hasIMemberOfTeam: (state, getters) => {
        return getters.iMemberOfTeam;
    },
    activeIMemberOfTeam: (state, getters) => {
        if (!getters.iMemberOfTeam) return false;
        return state.session.team;
    },
    iMemberOfTeam: (state, getters) => {
        const userId = getters.authUserId;
        let collaborations = state.session?.team?.collaborations || [];
        return collaborations.some((item) => item.user_id == userId);
    },
    isTeamAdminOrOwner:
        (state, getters) =>
        (team = null) => {
            if (team == null) {
                team = getters.activeIMemberOfTeam;
            }
            if (!team) return false;
            return team.is_admin || team.is_owner;
        },
    iAddedCardToTeam:
        (state, getters) =>
        (card, team = null) => {
            if (!card) return false;
            if (team == null) {
                team = getters.activeIMemberOfTeam;
            }
            if (!team || !state.teamCard[team.id]) return false;
            let teamCard = state.teamCard[team.id];
            let addedBy = teamCard[card.id];
            const userId = getters.authUserId;
            return addedBy == userId;
        },
    isBlockDummy: () => (block) => isBlockDummy(block),
};

const mutations = {
    RESET(state) {
        Object.keys(initState).forEach(function (prop) {
            let val = initState[prop];
            if (_.isArray(val)) {
                val = [...val];
            } else if (_.isPlainObject(val)) {
                val = { ...val };
            }
            state[prop] = val;
        });
    },
    SET_SESSION(state, session) {
        session.sort_ordered = uidSeq();
        let data = prepareSession(session);
        Object.keys(data).forEach((prop) => {
            let val = data[prop];
            if (_.isArray(state[prop])) {
                state[prop] = [...state[prop], ...val];
            } else {
                state[prop] = { ...state[prop], ...val };
            }
        });
    },
    SWITCH_ON_SESSION(state, { sessionId = null, dayId = null } = {}) {
        if (!dayId) {
            dayId = get(
                first(
                    _.sortBy(
                        _.filter(state.sessions, ['parent_session_id', sessionId]),
                        'sort_ordered',
                    ),
                ),
                'id',
                '',
            );
        }
        state.activeBlock = null;
        state.blockRequestId = _.find(
            state.blocks,
            (item) => item.session_id == sessionId && item.kind == 2 && item.type === 'request',
        ).id;
        state.blockResultId = _.find(
            state.blocks,
            (item) =>
                item.session_id == sessionId && item.kind == 2 && item.type === 'result_format',
        ).id;
        state.activeDayId = dayId;
        state.session = state.sessions[sessionId];
    },
    ADD_DAY(state, value) {
        flatten(value, { reference: 'blocks', addRoot: false }, state.blocks);
        Vue.set(state.sessions, value.id, value);
    },
    REMOVE_DAY(state, value) {
        const id = isPlainObject(value) ? value.id : value;

        let ids = mapWithFilterIds(state.blocks, 'session_id', id);
        ids.forEach((key) => Vue.delete(state.blocks, key));

        Vue.delete(state.sessions, id);
    },
    ADD_BLOCK(state, value) {
        const added = flatten(value, { reference: 'child_blocks' });
        let extraColumns = _.filter(state.extraColumns, ['session_id', state.session.id]);
        _.forEach(added, (item, key) => {
            expandBlock(item);
            Vue.set(state.extraColumnValues, key, extractExtraColumnValue(item, extraColumns));
            Vue.set(state.blocks, key, item);
        });
    },
    REMOVE_BLOCK(state, value) {
        const id = isPlainObject(value) ? value.id : value;

        const ids = mapWithFilterIdsDeep(state.blocks, 'parent_block_id', id);
        ids.forEach((key) => {
            Vue.delete(state.blocks, key);
            Vue.delete(state.extraColumnValues, key);
        });

        if (state.activeBlock && state.activeBlock.id == id) {
            state.activeBlock = null;
        }

        Vue.delete(state.blocks, id);
        Vue.delete(state.extraColumnValues, id);
        filter(state.attachments, ['block_id', id]).forEach((attachment) => {
            Vue.delete(state.attachments, attachment.id);
        });
    },
    UPDATE_BLOCK_CHILDREN(state, { parentId, sessionId }) {
        updateRecurse(parentId);

        function updateRecurse(parent_id) {
            _.filter(state.blocks, ['parent_block_id', parent_id]).forEach((item) => {
                item.session_id = sessionId;
                updateRecurse(item.id);
            });
        }
    },
    SET_ACTIVE_DAY(state, day) {
        state.activeDayId = isPlainObject(day) ? day.id : day;
    },
    SET_LIST_CARDS(state, value) {
        state.listCards = value || [];
    },
    SET_COLLABORATION_ROLE(state, value) {
        state.collaborationRoles = value || [];
    },
    UPDATE_BLOCK_GROUP(state, value) {
        const ids = mapWithFilterIds(state.blocks, 'parent_block_id', value.id);
        ids.forEach((key) => Vue.delete(state.blocks, key));
        const added = flatten(value.child_blocks, {});
        _.forEach(added, (item, key) => {
            expandBlock(item);
            Vue.set(state.blocks, key, item);
        });
    },
    ADD_COMMENT(state, value) {
        Vue.set(state.comments, value.id, value);
    },
    REMOVE_COMMENT(state, value) {
        const id = isPlainObject(value) ? value.id : value;
        Vue.delete(state.comments, id);
    },
    UPDATE_COMMENT(state, value) {
        if (value?.id) {
            Vue.set(state.comments, value.id, value);
        }
    },
    UPDATE_EXTRA_COLUMNS(state, data = {}) {
        let { sessionId, data: value } = data;

        let extraColumns = _.filter(state.extraColumns, ['session_id', sessionId]);
        let deleted = {};

        extraColumns.forEach((item) => {
            Vue.delete(state.extraColumns, item.id);
            deleted[item.id] = true;
        });
        value.forEach((item) => {
            Vue.set(state.extraColumns, item.id, item);
            delete deleted[item.id];
        });

        _.forEach(state.extraColumnValues, (val) => {
            _.forEach(deleted, (column, key) => {
                Vue.delete(val, key);
            });
        });
    },
    UPDATE_EXTRA_COLUMN_VALUES(state, { sessionId } = {}) {
        let extraColumns = _.filter(state.extraColumns, ['session_id', sessionId]);
        _.forEach(state.extraColumnValues, (colVals, key) => {
            let blockId = key;
            let session = getSession(blockId);
            if (!session) return;
            if (session.id != sessionId) return;

            let store = extraColumns.reduce((colStore, item) => {
                let extraColumnValue = colVals[item.id];
                colStore[item.id] = {
                    extra_column_id: item.id,
                    block_id: blockId,
                    name: item.name,
                    value: (extraColumnValue && extraColumnValue.value) || '',
                };
                return colStore;
            }, {});

            Vue.set(state.extraColumnValues, blockId, store);
        });

        function getSession(blockId) {
            let block = state.blocks[blockId];
            if (!block) return null;
            let session = state.sessions[block.session_id];
            if (!session) return null;
            if (session.parent_session_id === null) return session;
            return state.sessions[session.parent_session_id] || null;
        }
    },
    UPDATE_CARD_FIGURES(state, value) {
        state.listCardFigures = value;
    },
    ADD_CARD(state, value) {
        state.listCards.push(value);
    },
    UPDATE_CARD(state, value) {
        const idx = state.listCards.findIndex((item) => item.id == value.id);
        let removed = state.listCards.splice(idx, 1, value);
        let old = removed[0];

        if (value.type === 'module') {
            sync(old, value, 'methods', 'modules');
        }

        if (value.type === 'method') {
            sync(old, value, 'modules', 'methods');
        }

        function sync(oldCard, newCard, scr, desc) {
            let removeIds = _.difference(oldCard[scr], newCard[scr]);
            let appendIds = _.difference(newCard[scr], oldCard[scr]);

            removeIds.forEach((rid) => {
                const idx = state.listCards.findIndex((item) => item.id == rid);
                if (idx === -1) return;

                const card = state.listCards[idx];
                card[desc].splice(card[desc].indexOf(newCard.id), 1);
            });
            appendIds.forEach((aid) => {
                const idx = state.listCards.findIndex((item) => item.id == aid);
                if (idx === -1) return;

                const card = state.listCards[idx];
                card[desc].indexOf(newCard.id) === -1 && card[desc].push(newCard.id);
            });
        }
    },
    UPDATE_BLOCK_CARD(state, value) {
        const block = _.find(state.blocks, (block) => block.card_id == value.id);
        if (block) {
            block.card = value;
        }
    },
    SET_ACTIVE_BLOCK(state, value) {
        state.activeBlock = value;
    },
    ADD_ATTACHMENT(state, value) {
        Vue.set(state.attachments, value.id, value);
    },
    REMOVE_ATTACHMENT(state, value) {
        Vue.delete(state.attachments, value);
    },
    APPEND_CARD(state, value) {
        if (!_.isArray(value)) {
            value = [value];
        }

        value.forEach((item) => {
            if (state.listCards.find((card) => card.id == item.id)) return;
            state.listCards.push(item);
        });
    },
    SET_ACTIVE_TAB(state, value) {
        state.activeTab = value;
    },
    SET_MINI(state, value) {
        state.mini = !!value;
    },
    SET_CONTROL_ON_START(state, value) {
        state.controlOnStart = value;
    },
    ADD_COLLABORATOR(state, value) {
        if (!value.session_id) {
            console.error('ADD_COLLABORATOR', 'Не указана сессия');
            throw new Error('Не указана сессия');
        }
        Vue.set(state.collaborations, value.id, value);
    },
    UPDATE_COLLABORATOR(state, value) {
        if (!value.session_id) {
            console.error('UPDATE_COLLABORATOR', 'Не указана сессия');
            throw new Error('Не указана сессия');
        }
        Vue.set(state.collaborations, value.id, value);
    },
    REMOVE_COLLABORATOR(state, value) {
        Vue.delete(state.collaborations, value.id);
    },
    ADD_TEAM_CARD(state, { teamId, card }) {
        if (!teamId) return;

        if (!state.teamCard[teamId]) {
            Vue.set(state.teamCard, teamId, {});
        }
        Vue.set(state.teamCard[teamId], card.id, card.team_added_by);
    },
    TOGGLE_TEAM_CARD(state, { teamId, attach, card, addedBy }) {
        if (!teamId) return;

        if (!state.teamCard[teamId]) {
            Vue.set(state.teamCard, teamId, {});
        }

        if (!attach) {
            Vue.delete(state.teamCard[teamId], card.id);
            return;
        }

        Vue.set(state.teamCard[teamId], card.id, addedBy);
    },
    SET_MODE_PULL_CLONE(state, value) {
        state.modePullClone = !!value;
    },
};

const actions = {
    reset({ commit }) {
        commit('RESET');
    },
    switchOnSession({ commit, dispatch }, data = {}) {
        commit('SWITCH_ON_SESSION', data);
        dispatch('updateBlockStartTime', { session: data.sessionId });
    },
    async loadSession(
        { commit, dispatch, getters, state },
        { sessionId, switchOn = true, allCards = false },
    ) {
        const promises = [];
        if (state.sessions[sessionId]) return;

        switchOn && (await dispatch('reset'));
        let cardsLoaded = state.listCards.length > 0;
        if (!cardsLoaded) {
            promises.push(dispatch('loadCards', { sessionId: sessionId }));
            promises.push(dispatch('favorite/load', null, { root: true }));
            promises.push(dispatch('collaborationRoleLoad', sessionId));
        } else {
            promises.push(Promise.resolve({}));
            promises.push(Promise.resolve({}));
            promises.push(Promise.resolve({}));
        }
        promises.push(apiSession.getFull(sessionId));
        const response = await Promise.all(promises);
        let [{}, {}, {}, { data: session }] = response;
        await dispatch('initSession', session);
        if (!cardsLoaded && session.team_id) {
            state.listCards
                .filter((card) => card.is_team)
                .forEach((card) => {
                    commit('ADD_TEAM_CARD', { card, teamId: session.team_id });
                });
        }
        if (!switchOn) {
            dispatch('updateBlockStartTime', { session: session.id });
        } else {
            await dispatch('switchOnSession', { sessionId: session.id });
        }
    },
    async collaborationRoleLoad({ commit }, sessionId) {
        const response = await collaborationRoleApi.list(sessionId);
        commit('SET_COLLABORATION_ROLE', response.data || []);
        return response;
    },
    updateBlockStartTime({ getters, state }, { session = null, block = null } = {}) {
        let days = [];

        if (block != null) {
            let day = getters.getDayByBlock(block);
            days = day ? [day] : [];
        }

        if (!days.length) {
            if (session == null) {
                session = state.session;
            }
            days = getters.days(session);
        }

        _.forEach(days, (item) => {
            const moduleGroup = getters.getBlockModuleGroup(item);
            if (!moduleGroup) return;

            let calc = new CalculateBlocksTiming(
                buildNestedBlocks(moduleGroup, getChildren)[0].child_blocks,
            );
            calc.refreshTotalTime();

            item.totalTime = calc.totalTime;
            item.hasTimeBuffer = calc.hasTimeBuffer;
            item.hasTimeOverlap = calc.hasTimeOverlap;

            //console.log('calc day', calc);

            function getChildren(block) {
                return _.sortBy(getters.childBlocks(block.id), 'ordinal');
            }
        });
    },
    initSession({ commit, dispatch }, data) {
        commit('SET_SESSION', data);
    },
    startSession({ commit, dispatch }, data) {
        if (!data) {
            commit('RESET');
        } else {
            commit('SET_SESSION', data);
            dispatch('updateBlockStartTime');
        }
    },
    async addDay({ commit, state, getters, dispatch }, data = {}) {
        let sessionId = state.session.id;
        let params = data;
        if (data.session) {
            sessionId = data.session.id;
            params = data.data;
        }
        const response = await apiSession.addDay(sessionId, params);
        commit('ADD_DAY', response.data);
        dispatch('loadHistory');

        if (!state.activeDayId) {
            commit('SET_ACTIVE_DAY', getters.days()[0]);
        }
        return response;
    },
    async removeDay({ commit, state, getters, dispatch }, data) {
        const response = await apiSession.remove(data.id);
        const days = getters.days(data.parent_session_id);
        commit('REMOVE_DAY', data);
        let idx = _.findIndex(days, ['id', data.id]);
        idx = idx > 0 ? idx - 1 : idx + 1;
        if (state.session.id === data.parent_session_id) {
            commit('SET_ACTIVE_DAY', days[idx]);
        }

        dispatch('loadHistory');

        return response;
    },
    sessionUpdate({ commit, state, dispatch }, data = {}) {
        let params = data;
        let sessionId = state.session.id;
        if (data.data) {
            params = data.data;
            sessionId = data.sessionId;
        }
        dispatch('loadHistory');
        return apiSession.update(sessionId, params);
    },
    dayUpdate({ commit, state, dispatch }, data = {}) {
        dispatch('loadHistory');
        return apiSession.update(data.id, data.data);
    },
    setActiveDay({ commit }, day) {
        commit('SET_ACTIVE_DAY', day);
    },
    async loadCards({ commit }, { sessionId } = {}) {
        const response = await apiCard.getUsedCards(sessionId);
        commit('SET_LIST_CARDS', response.data || []);
        return response;
    },
    async blockUpdate({ commit, state, dispatch }, { block, data, parent = null }) {
        let sessionId = block.session_id;
        if (parent) {
            sessionId = parent.session_id;
            if (!data.session_id) {
                data.session_id = parent.session_id;
            }
        }
        const response = await apiBlock.update(block.id, sessionId, data);
        if (data?.session_id) {
            commit('UPDATE_BLOCK_CHILDREN', { parentId: block.id, sessionId: data.session_id });
        }

        dispatch('loadHistory');
        return response;
    },
    blockExtraColumnUpdate({}, data) {
        return apiExtraColumnValue.update(data);
    },
    blockCardSync({ state, dispatch }, data) {
        dispatch('loadHistory');

        return apiBlock.syncCard(data.id, state.session.id, { sync: data.data });
    },
    async addBlockRequest({ commit, state, dispatch }, { card_id, ordinal = -1 }) {
        const response = await apiBlock.store(state.session.id, {
            card_id: card_id,
            parent_block_id: state.blockRequestId,
            kind: 1,
            type: 'request',
            ordinal,
        });

        commit('ADD_BLOCK', response.data);
        dispatch('loadHistory');
        return response;
    },
    async addBlockResult({ commit, state, dispatch }, { card_id, ordinal = -1 }) {
        const response = await apiBlock.store(state.session.id, {
            card_id: card_id,
            parent_block_id: state.blockResultId,
            kind: 1,
            type: 'result_format',
            ordinal,
        });

        commit('ADD_BLOCK', response.data);
        dispatch('loadHistory');
        return response;
    },
    async removeBlockSimple({ commit, state, dispatch }, playload) {
        const response = await apiBlock.remove(playload.id, state.session.id);
        commit('REMOVE_BLOCK', playload);
        dispatch('loadHistory');
        return response;
    },
    async updateBlockRequest({ commit, state, dispatch }, playload) {
        const response = await dispatch('blockCardSync', {
            id: state.blockRequestId,
            data: transformBlockSyncCard(playload, 'request'),
        });
        commit('UPDATE_BLOCK_GROUP', response.data);
        dispatch('loadHistory');
    },
    async updateBlockResult({ commit, state, dispatch }, playload) {
        const response = await dispatch('blockCardSync', {
            id: state.blockResultId,
            data: transformBlockSyncCard(playload, 'result'),
        });
        commit('UPDATE_BLOCK_GROUP', response.data);
        dispatch('loadHistory');
    },
    async addBlockSimple({ commit, state, dispatch, getters }, data) {
        let sessionId = state.activeDayId;
        if (data && data.session_id) {
            sessionId = data.session_id;
        }
        const response = await apiBlock.store(sessionId, data);
        commit('ADD_BLOCK', response.data);
        dispatch('loadHistory');

        return response;
    },
    async addBlock({ commit, state, dispatch, getters }, data) {
        const dummy = createBlockDummy(data);
        commit('ADD_BLOCK', dummy);

        let response;
        try {
            response = await apiBlock.store(state.activeDayId, data);
        } finally {
            commit('REMOVE_BLOCK', dummy);
        }

        commit('ADD_BLOCK', response.data);
        reOrdinal(getters.childBlocks(response.data.parent_block_id), response.data, true);
        dispatch('updateBlockStartTime');
        dispatch('loadHistory');

        return response;
    },
    async removeBlock({ commit, state, dispatch, getters }, data) {
        let id = data;
        let sessionId = state.activeDayId;
        if (isPlainObject(data)) {
            id = data.id;
            sessionId = data.session_id;
        }

        const response = await apiBlock.remove(id, sessionId);
        commit('REMOVE_BLOCK', data);
        reOrdinal(getters.childBlocks(data.parent_block_id), data);
        dispatch('updateBlockStartTime');
        dispatch('loadHistory');

        return response;
    },
    async duplicateBlock({ commit, state, dispatch, getters }, block) {
        let id = block;
        let sessionId = state.activeDayId;
        if (isPlainObject(block)) {
            id = block.id;
            sessionId = block.session_id;
        }

        const dummy = createBlockDummy(block);
        commit('ADD_BLOCK', dummy);
        let response;
        try {
            response = await apiBlock.duplicate(id, sessionId);
        } finally {
            commit('REMOVE_BLOCK', dummy);
        }

        commit('ADD_BLOCK', response.data);
        reOrdinal(getters.childBlocks(response.data.parent_block_id), response.data, true);
        dispatch('updateBlockStartTime');
        dispatch('loadHistory');

        return response;
    },
    async copyBlock({ commit, state, dispatch, getters }, data) {
        const { block, sessionId = state.activeDayId, data: dataRequest } = data;
        const { id } = block;

        const dummy = createBlockDummy(block, dataRequest);
        commit('ADD_BLOCK', dummy);
        reOrdinal(getters.childBlocks(dummy.parent_block_id), dummy, true);

        let response;
        try {
            response = await apiBlock.copy(id, sessionId, dataRequest);
        } finally {
            commit('REMOVE_BLOCK', dummy);
        }

        commit('ADD_BLOCK', response.data);
        reOrdinal(getters.childBlocks(response.data.parent_block_id), response.data, true);
        dispatch('updateBlockStartTime', { block: response.data });
        dispatch('loadHistory');

        return response;
    },
    async addComment({ state, commit }, value) {
        const response = await apiComment.store(value);
        commit('ADD_COMMENT', response.data);
        let newComment = _.find(state.comments, (item) => item.status == 'new');
        if (newComment) {
            if (
                newComment.commentable_id == response.data.commentable_id &&
                newComment.commentable_type == response.data.commentable_type
            ) {
                commit('REMOVE_COMMENT', newComment.id);
            }
        }
    },
    async addFakeNewComment({ state, commit, getters, rootGetters }, value) {
        let type = 'Block';
        if ('parent_session_id' in value) {
            type = 'Session';
        }

        let comment = _.find(
            state.comments,
            (item) => item.commentable_id == value.id && item.commentable_type == type,
        );
        if (comment) return comment;

        if (rootGetters.isAnon) return;
        let newComment = _.find(state.comments, (item) => item.status == 'new');
        if (newComment) {
            if (newComment.commentable_id == value.id && newComment.commentable_type == type) {
                return newComment;
            }
            commit('REMOVE_COMMENT', newComment.id);
        }

        newComment = {
            id: Date.now(),
            commentable_id: value.id,
            commentable_type: type,
            status: 'new',
        };
        commit('ADD_COMMENT', newComment);
        return newComment;
    },
    async removeComment({ state, commit }, value) {
        const id = isPlainObject(value) ? value.id : value;
        await apiComment.remove(id);
        commit('REMOVE_COMMENT', id);
    },
    cancelComment({ state, commit }) {
        let newComment = _.find(state.comments, (item) => item.status == 'new');
        if (newComment) {
            commit('REMOVE_COMMENT', newComment.id);
        }
    },
    async resolveComment({ state, commit }, value) {
        const id = isPlainObject(value) ? value.id : value;
        await apiComment.resolve(id);
        commit('REMOVE_COMMENT', id);
    },
    async updateComment({ state, commit }, { id, data }) {
        const response = await apiComment.update(id, data);
        commit('UPDATE_COMMENT', response.data);
    },
    async syncExtraColumns({ state, commit, dispatch }, data) {
        let sessionId = state.session.id;
        let response = await apiExtraColumn.sync(state.session.id, { sync: data });
        commit('UPDATE_EXTRA_COLUMNS', { sessionId, data: response.data });
        commit('UPDATE_EXTRA_COLUMN_VALUES', { sessionId });
        dispatch('loadHistory');
    },
    async loadCardFigures({ state, commit, dispatch }, forced) {
        if (!forced && state.listCardFigures.length) return state.listCardFigures;

        let response = await apiCardFigure.fetchList({ all: true });
        commit('UPDATE_CARD_FIGURES', response.data);
        return state.listCardFigures;
    },
    async newCard({ commit }, data) {
        let response = await apiCard.store({
            ...data,
            is_rendered: true,
        });
        commit('ADD_CARD', response.data);
        return response.data;
    },
    async updateCard({ commit, dispatch }, data) {
        let response = await apiCard.update(data.id, {
            ...data,
            is_rendered: true,
        });
        commit('UPDATE_CARD', response.data);
        commit('UPDATE_BLOCK_CARD', response.data);
        dispatch('loadHistory');

        return response.data;
    },
    removeAttachment({ commit }, data) {
        commit('REMOVE_ATTACHMENT', data.id);
    },
    async fetchCards({ commit }, type) {
        const response = await apiCard.fetchList({ all: true, search: buildSearch({ type }) });
        commit('APPEND_CARD', response.data || []);
        return response;
    },
    async loadHistory({ state }, data) {
        if (state.activeTab != 3 || state.mini) return;

        let historyId = 0;
        let sessionId = state.session.id;
        if (state.history.length) {
            state.history.forEach((item) => {
                if (item.session_id != sessionId) return;
                if (item.id > historyId) {
                    historyId = item.id;
                }
            });
        }

        const response = await apiSession.freshHistory({
            sessionId: sessionId,
            historyId: historyId,
        });
        if (response.data.length) {
            state.history = response.data.concat(state.history);
        }
    },
    setActiveTab({ commit, dispatch }, data) {
        commit('SET_ACTIVE_TAB', data);
        if (data == 3) {
            dispatch('loadHistory');
        }
    },
    addCollaborator({ commit, dispatch }, data) {},
    async toggleTeamCard({ commit, dispatch, state }, data) {
        let { cardId, teamId, attach } = data;
        let response = await apiCard.toggleTeam(cardId, teamId, attach);
        let { added_by: addedBy = null } = response.data?.attached || {};
        commit('TOGGLE_TEAM_CARD', {
            cardId,
            teamId,
            attach,
            card: _.find(state.listCards, ['id', cardId]),
            addedBy,
        });
        return response.data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
