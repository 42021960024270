<template>
    <button class="sidebar-minimizer" type="button" @click="onClick()">
        <span class="sidebar-minimizer__text">{{ text }}</span>
    </button>
</template>
<script>
    import { togglePs } from '@coreui/vue/src/mixins/togglePs';

    export default {
        name: 'sidebar-minimizer',
        mixins: [togglePs],
        props: {
            text: {
                type: String,
                default: '',
            },
        },
        mounted: function () {
            const isMinimized = document.body.classList.contains('sidebar-minimized');
            this.togglePs(!isMinimized);
        },
        methods: {
            onClick() {
                this.sidebarMinimize();
                this.brandMinimize();
            },
            sidebarMinimize() {
                const isMinimized = document.body.classList.toggle('sidebar-minimized');
                this.$emit('cui-sidebar-minimize', isMinimized);
                this.togglePs(!isMinimized);
            },
            brandMinimize() {
                document.body.classList.toggle('brand-minimized');
            },
        },
    };
</script>

<style lang="scss">
    .sidebar-minimizer {
        text-align: left;
        opacity: 1;
        transition: opacity 0.25s;
    }

    .sidebar-minimizer__text {
        color: #fff;
        font-size: 0.8rem;
    }

    .sidebar-minimized .sidebar-minimizer__text {
        display: none;
        opacity: 0;
    }
</style>
