<template>
    <b-modal
        ref="modal"
        :title="$t('collaborators.title')"
        v-model="visible"
        size="md"
        :busy="busy"
        @hidden="handleHidden"
        okOnly
        :okTitle="$t('dialog.close')"
    >
        <div v-loading="loading">
            <collaborators
                :list="collaborators"
                is-collaborator
                :roles="collaborationRoles"
                :session-id="sessionId"
            ></collaborators>
        </div>
    </b-modal>
</template>

<script>
    import * as sessionCollaborationApi from '@/front/shared/api/sessionCollaboration';
    import * as collaborationRoleApi from '@/front/shared/api/collaborationRole';
    import Collaborators from '@/front/shared/components/Collaborators';

    export default {
        name: 'ModalCollaboration',
        components: { Collaborators },
        data() {
            return {
                title: this.$i18n.t('invitation.regular.title'),
                visible: false,
                busy: false,
                collaborators: [],
                loading: false,
            };
        },
        computed: {
            filteredCollaborationRoles() {},
        },
        created() {
            this.playload = null;
            this.onOk = null;
            this.sessionId = null;
            this.collaborationRoles = [];
        },
        methods: {
            open({ sessionId = null } = {}) {
                this.reset();
                this.sessionId = sessionId;
                this.collaborators = [];
                this.fetchData();
                this.visible = true;
            },
            fetchData() {
                this.loading = true;
                const promises = [];
                promises.push(collaborationRoleApi.list(this.sessionId));
                promises.push(sessionCollaborationApi.list(this.sessionId));
                Promise.all(promises)
                    .then(([{ data: collaborationRoles }, { data: collaborations }]) => {
                        this.collaborationRoles = collaborationRoles;
                        this.collaborators = collaborations;
                    })
                    .finally(() => (this.loading = false));
            },
            reset() {
                this.playload = null;
                this.sessionId = null;
                this.collaborators = [];
            },
            handleHidden(e) {
                this.$root.$emit('closeModalCollaboration', this.sessionId, [
                    ...this.collaborators,
                ]);
                this.reset();
            },
        },
    };
</script>
