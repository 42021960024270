function select(e) {
    e.target.select();
}

export default {
    inserted(el) {
        el.addEventListener('focus', select);
    },
    unbind(el) {
        el.removeEventListener('focus', select);
    },
};
