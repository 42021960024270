import ModalPaymentAdd from '@/front/shared/components/Modals/ModalPaymentAdd';
import * as paymentApi from '@/front/shared/api/payment';

async function handleOk(result, playload) {
    const response = await paymentApi.newPayment({
        currency: result.currency,
        amount: result.amount,
        payment_method_code: result.paymentMethodCode,
    });
    window.location.href = response.data.confirmation_url;
}

export default {
    components: {
        ModalPaymentAdd,
    },
    created() {
        this.$root.$on('showModalPaymentAdd', this.showModalPaymentAdd);
        this.$root.$once('hook:beforeDestroy', () => {
            this.$root.$off('showModalPaymentAdd', this.showModalPaymentAdd);
        });
    },
    methods: {
        showModalPaymentAdd(playload = {}) {
            this.$refs.modalPaymentAdd.open({ playload, onOk: handleOk });
        },
    },
};
