export default {
    data() {
        return {
            serverErrors: {},
        };
    },
    methods: {
        validation(reset = true, form = 'form') {
            const $form = form ? this.$v[form] : this.$v;
            reset && $form.$reset();
            this.serverErrors = {};
            $form.$touch();

            return new Promise((resolve) => {
                let relieve = false;
                const unwatch = this.$watch(
                    () => !$form.$pending,
                    (isNotPending) => {
                        if (isNotPending) {
                            unwatch && unwatch();
                            relieve = true;
                            resolve(!$form.$invalid);
                        }
                    },
                    { immediate: true },
                );
                if (!relieve) unwatch();
            });
        },
    },
};
