import Vue from 'vue';
import { pluralize, formatDate } from '@/common/utils';
import lang from '@/front/shared/lang';

//Vue.filter('timeAgo', timeAgo);
Vue.filter('pluralize', pluralize);
Vue.filter('formatDate', formatDate);

Vue.filter('tCurrency', function (value, currency = 'rub') {
    currency = currency.toLowerCase();
    let locales = {
        rub: 'ru',
        usd: 'en',
        eur: 'eu',
    };
    let locale = locales[currency];
    return lang.n(value, 'currency', locale);
});
