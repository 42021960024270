var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button-group",
    { attrs: { size: _vm.size } },
    _vm._l(_vm.list, function (currency, idx) {
      return _c(
        "b-button",
        {
          key: idx,
          staticStyle: { "text-transform": "uppercase" },
          attrs: {
            disabled: _vm.disabled,
            pressed: _vm.isSelectedCurrency(currency),
            variant: _vm.variant,
          },
          on: {
            "update:pressed": function ($event) {
              return _vm.handlePressed(currency, $event)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(currency) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }