var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c(
        "div",
        [
          _c("svg-icon", {
            attrs: {
              "class-name": "international-icon",
              "icon-class": "language",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "en", command: "en" } },
            [_vm._v("English")]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-item",
            { attrs: { disabled: _vm.language === "ru", command: "ru" } },
            [_vm._v("Русский")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }