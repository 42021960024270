<template>
    <el-menu class="navbar" mode="horizontal">
        <hamburger
            :toggle-click="toggleSideBar"
            :is-active="sidebar.opened"
            class="hamburger-container"
        />
        <breadcrumb />
        <div class="right-menu">
            <template v-if="device !== 'mobile'">
                <lang-select class="international right-menu-item" />
            </template>

            <el-dropdown class="avatar-container" trigger="click">
                <div class="avatar-wrapper">
                    <img :src="avatarUrl" class="user-avatar" />
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <el-dropdown-item>
                        <a href="/default" target="_blank">Перейти на сайт</a>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                        <span style="display: block" @click="logout">Выйти</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-menu>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Breadcrumb from '@/admin/components/Breadcrumb/index';
    import Hamburger from '@/admin/components/Hamburger/index';
    import LangSelect from '@/admin/components/LangSelect/index';

    export default {
        name: 'LNavbar',
        components: {
            Breadcrumb,
            LangSelect,
            Hamburger,
        },
        computed: {
            ...mapGetters(['sidebar', 'avatar', 'device']),

            avatarUrl() {
                return (this.avatar && this.avatar.url) || '/img/no_avatar.jpg';
            },
        },
        methods: {
            toggleSideBar() {
                this.$store.dispatch('ToggleSideBar');
            },
            async logout() {
                await this.$store.dispatch('LogOut');
                window.location.assign('/admin#/login');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .user-dropdown {
        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .navbar {
        height: 50px;
        line-height: 50px;
        border-radius: 0px !important;

        .hamburger-container {
            line-height: 58px;
            height: 50px;
            float: left;
            padding: 0 10px;
        }

        .screenfull {
            position: absolute;
            right: 90px;
            top: 16px;
            color: red;
        }

        .right-menu {
            float: right;
            height: 100%;

            &:focus {
                outline: none;
            }

            .right-menu-item {
                display: inline-block;
                margin: 0 8px;
            }

            .screenfull {
                height: 20px;
            }

            .international {
                vertical-align: top;
            }

            .theme-switch {
                vertical-align: 15px;
            }

            .avatar-container {
                height: 50px;
                margin-right: 30px;

                .avatar-wrapper {
                    margin-top: 5px;
                    position: relative;

                    .user-avatar {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                    }

                    .el-icon-caret-bottom {
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 25px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>
