let i18n = null;

export function useI18n(value) {
    i18n = value;
}

export function translate(list, lexicon, method = 't') {
    if (i18n == null) return list;
    if (!Array.isArray(list)) throw '';

    return list.map(item => {
        return {
            id: item.id,
            value: item.value,
            text: i18n[method](`${ lexicon }.${ item.value }`),
        }
    });
}
