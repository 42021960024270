<template>
    <div right>Баланс: {{ balance }}</div>
</template>

<script>
    export default {
        name: 'DefaultBalanceHeader',
        data() {
            return {
                // balance: 0,
            };
        },
        computed: {
            balance() {
                return this.$store.state.user.balance;
            },
        },
        methods: {
            getBalance() {
                this.balance = this.$store.state.user.balance;
            },
        },
    };
</script>
