import Layout from '../views/layout/Layout';

export const constantRouterMap = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/admin/views/redirect/index'),
            },
        ],
    },
    {
        path: '/login',
        component: () => import('@/admin/views/login/index'),
        hidden: true,
    },
    {
        path: '/auth-redirect',
        component: () => import('@/admin/views/login/AuthRedirect'),
        hidden: true,
    },
    {
        path: '/404',
        component: () => import('@/admin/views/ErrorPage/404'),
        hidden: true,
    },
    {
        path: '/401',
        component: () => import('@/admin/views/ErrorPage/401'),
        hidden: true,
    },
    {
        name: 'cards',
        path: '',
        component: Layout,
        redirect: { name: 'cards.index' },
        meta: { title: 'Карты', icon: 'fa fa-user', noCache: true },
        children: [
            {
                name: 'cards.index',
                path: 'cards/index',
                component: () => import('@/admin/views/cards/index'),
                meta: {
                    title: 'Карты',
                    icon: 'fa fa-address-card-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
            {
                path: 'cards/:id?/:lang?',
                name: 'cards.show',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/cards/show'),
                meta: { title: 'Карта', icon: 'fa fa-address-card-o', noCache: true },
            },
        ],
    },

    {
        name: 'card-category-methods',
        path: '/card-category-methods',
        component: Layout,
        redirect: '/card-category-methods/index',
        meta: { title: 'Категории методов', icon: 'fa fa-sitemap', noCache: true },
        children: [
            {
                name: 'card-category-methods.index',
                path: 'index',
                component: () => import('@/admin/views/card-category-methods'),
                meta: {
                    title: 'Категории методов',
                    icon: 'fa fa-sitemap',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },

    {
        name: 'card-figures',
        path: '/card-figures',
        component: Layout,
        redirect: '/card-figures/index',
        meta: { title: 'Фигуры карт', icon: 'fa fa-picture-o', noCache: true },
        children: [
            {
                name: 'card-figures.index',
                path: 'index',
                component: () => import('@/admin/views/card-figures/index'),
                meta: {
                    title: 'Фигуры карт',
                    icon: 'fa fa-picture-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    {
        name: 'question-category-requests',
        path: '/question-category-requests',
        component: Layout,
        redirect: '/question-category-requests/index',
        meta: { title: 'Категории вопросов', icon: 'fa fa-picture-o', noCache: true },
        children: [
            {
                name: 'question-category-requests.index',
                path: 'index',
                hidden: true,
                component: () => import('@/admin/views/question-category-requests/index'),
                meta: {
                    title: 'Категории вопросов',
                    icon: 'fa fa-picture-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
            {
                name: 'question-category-requests.edit',
                path: 'edit/:id?',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/question-category-requests/edit'),
                meta: {
                    title: 'Категория вопросов',
                    icon: 'fa fa-picture-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    {
        name: 'question-requests',
        path: '/question-requests',
        component: Layout,
        redirect: '/question-requests/index',
        meta: { title: 'Вопросы к запросам', icon: 'fa fa-picture-o', noCache: true },
        children: [
            {
                name: 'question-requests.index',
                path: 'index',
                component: () => import('@/admin/views/question-requests/index'),
                meta: {
                    title: 'Вопросы к запросам',
                    icon: 'fa fa-picture-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    {
        path: '/library',
        component: Layout,
        redirect: '/library',
        meta: { title: 'Библиотека', icon: 'fa fa-archive', noCache: true },
        children: [
            /*{
                path: 'modules',
                component: () => import('@/admin/views/library/modules'),
                name: 'library.modules',
                meta: { title: 'Модули', icon: 'fa fa-braille', noCache: true },
            },*/
            {
                path: 'methods',
                component: () => import('@/admin/views/library/methods'),
                name: 'library.methods',
                meta: { title: 'Методы', icon: 'fa fa-id-card-o', noCache: true },
            },
            {
                path: 'sessions',
                component: () => import('@/admin/views/library/sessions'),
                name: 'library.sessions',
                meta: { title: 'Сценарии', icon: 'fa fa-object-group', noCache: true },
            },
            {
                path: 'manual-categories',
                component: () => import('@/admin/views/manual-categories/index'),
                name: 'manual-categories.index',
                meta: { title: 'Категории инструкций', icon: 'fa fa-object-group', noCache: true },
            },
            {
                path: 'manual-categories/edit/:id?',
                name: 'manual-categories.edit',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/manual-categories/edit'),
                meta: {
                    title: 'Категория инструкции',
                    icon: 'fa fa-picture-o',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
            {
                path: 'beine',
                component: () => import('@/admin/views/library/beine'),
                name: 'library.beine',
                meta: { title: 'Видео инструкции', icon: 'fa fa-object-group', noCache: true },
            },
            {
                path: 'instructions',
                component: () => import('@/admin/views/library/instructions'),
                name: 'library.instructions',
                meta: { title: 'Инструкции', icon: 'fa fa-object-group', noCache: true },
            },
            {
                path: 'method/:id?',
                hidden: true,
                name: 'method.show',
                props: true,
                component: () => import('@/admin/views/library/components/method'),
                meta: { title: 'Method', icon: 'dashboard', noCache: true },
            },
            {
                path: 'session/:id?',
                name: 'session.show',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/library/components/Session'),
                meta: { title: 'Session', icon: 'dashboard', noCache: true },
            },
        ],
    },
    {
        path: '/faq',
        component: Layout,
        redirect: '/faq/index',
        children: [
            {
                path: 'index',
                component: () => import('@/admin/views/faq/index'),
                name: 'faq.index',
                meta: { title: 'FAQ', icon: 'el-icon-chat-line-round', noCache: true },
            },
        ],
    },
    {
        path: '/helpdesk',
        component: Layout,
        redirect: '/helpdesk/index',
        children: [
            {
                path: 'index',
                component: () => import('@/admin/views/helpdesk/index'),
                name: 'helpdesk.index',
                meta: { title: 'Помощь', icon: 'fa fa-comments', noCache: true },
            },
        ],
    },
    {
        path: '/tariff',
        component: Layout,
        redirect: '/tariff/index',
        children: [
            {
                path: 'index',
                component: () => import('@/admin/views/tariff/index'),
                name: 'tariff.index',
                meta: { title: 'Тарифы', icon: 'fa fa-money', noCache: true },
            },
            {
                path: 'new',
                name: 'tariff.store',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/tariff/tariff'),
                meta: {
                    title: 'Новый тариф',
                    icon: 'dashboard',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
            {
                path: ':id',
                name: 'tariff.show',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/tariff/tariff'),
                meta: {
                    title: 'Тариф',
                    icon: 'dashboard',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    {
        name: 'users',
        path: '/users',
        component: Layout,
        redirect: '/users/index',
        meta: { title: 'Пользователи', icon: 'peoples', noCache: true, svg: true },
        children: [
            {
                name: 'users.index',
                path: 'index',
                component: () => import('@/admin/views/users/index'),
                meta: {
                    title: 'Пользователи',
                    icon: 'peoples',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                    svg: true,
                },
            },

            {
                path: ':id?',
                name: 'users.show',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/users/show'),
                meta: {
                    title: 'Пользователь',
                    icon: 'peoples',
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    /*{
        name: 'payments',
        path: '/payments',
        component: Layout,
        children: [
            {
                name: 'payments.index',
                path: 'index',
                component: () => import('@/admin/views/payments/index'),
                meta: { title: 'Оплата', icon: 'money', noCache: true, svg: true },
            },
        ],
    },*/
    {
        name: 'rates',
        path: '/rates',
        component: Layout,
        children: [
            {
                name: 'rates.index',
                path: 'index',
                component: () => import('@/admin/views/rate/index'),
                meta: { title: 'Курс', icon: 'fa fa-line-chart', noCache: true },
            },
            {
                path: 'rates/:id?',
                name: 'rates.show',
                hidden: true,
                props: true,
                component: () => import('@/admin/views/rate/show'),
                meta: { title: 'Курс', noCache: true },
            },
        ],
    },
    {
        name: 'mail',
        path: '/mail',
        component: Layout,
        children: [
            {
                name: 'mail.index',
                path: 'index',
                component: () => import('@/admin/views/mail/index'),
                meta: { title: 'Почта', icon: 'fa fa-envelope', noCache: true },
            },
        ],
    },
    {
        name: 'invoices',
        path: '/invoices',
        component: Layout,
        redirect: '/invoices/index',
        meta: { title: 'Счета', icon: 'bill', svg: true, noCache: true },
        children: [
            {
                name: 'invoices.index',
                path: 'index',
                component: () => import('@/admin/views/invoices/index'),
                meta: {
                    title: 'Счета',
                    icon: 'bill',
                    svg: true,
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
    {
        name: 'payments',
        path: '/payments',
        component: Layout,
        redirect: '/payments/index',
        meta: { title: 'Платежи', icon: 'fa fa-credit-card', svg: false, noCache: true },
        children: [
            {
                name: 'payments.index',
                path: 'index',
                component: () => import('@/admin/views/payments/index'),
                meta: {
                    title: 'Платежи',
                    icon: 'fa fa-credit-card',
                    svg: false,
                    noCache: true,
                    hiddenBreadcrumbParent: true,
                },
            },
        ],
    },
];

export const asyncRouterMap = [{ path: '*', redirect: '/404', hidden: true }];
