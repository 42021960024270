import i18n from '@/front/shared/lang';

window.tinymce.PluginManager.add('charcount', (editor) => {
    const goodColor = 'green';
    const warningColor = '#e08000';
    const dangerColor = 'red';

    const charsTitle = i18n.t('charcount.characters');
    const charsLimit = editor.targetElm.getAttribute('data-maxchar');

    const sepStyle = 'border-right:1px solid #ced5e2;padding-right:6px;margin-right:6px;';

    function getCharacterLength() {
        let text = editor.getContent({ format: 'text' });
        // trim and transform newlines ("\n" has a length of 2) to spaces (length = 1)
        text = text.trim().replace(/(\n)+/g, ' ');
        return text.length;
    }

    function getColor(count, limit) {
        if (count > limit) {
            if (((count - limit) / limit) * 100 > 33) {
                return dangerColor;
            }
            return warningColor;
        }
        return goodColor;
    }

    function update() {
        let charsCount = getCharacterLength();
        let charsColor = '';

        if (charsLimit) {
            charsColor = getColor(charsCount, charsLimit);
            charsCount += ` / ${charsLimit}`;
        }

        const text = `${charsTitle}: <span style="${sepStyle}font-size:inherit;color:${charsColor}">${charsCount}</span>`;

        editor.theme.panel.find('#charcount')[0].innerHtml(text);
    }

    editor.on('init', () => {
        const Delay = tinymce.util.Delay;
        const debouncedUpdate = Delay.debounce(update, 100);
        const statusbar = editor.theme.panel && editor.theme.panel.find('#statusbar')[0];

        if (statusbar) {
            Delay.setEditorTimeout(
                editor,
                () => {
                    statusbar.insert(
                        {
                            type: 'label',
                            name: 'charcount',
                            classes: 'wordcount charcount',
                            disabled: editor.settings.readonly,
                        },
                        0,
                    );

                    update();
                    editor.on('undo redo keyup', debouncedUpdate);
                },
                0,
            );
        }
    });
});
