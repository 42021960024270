var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("table", [
        _c("tbody", [
          _vm.controls
            ? _c("tr", { staticClass: "text-center" }, [
                _c(
                  "td",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "link",
                          size: "sm",
                          disabled: _vm.readonly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTime(1, 1)
                          },
                        },
                      },
                      [_c("i", { class: _vm.iconControlUp })]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "link",
                          size: "sm",
                          disabled: _vm.readonly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTime(0, 1)
                          },
                        },
                      },
                      [_c("i", { class: _vm.iconControlUp })]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.hoursText,
                    expression: "hoursText",
                    modifiers: { lazy: true },
                  },
                ],
                ref: "hoursInput",
                staticClass: "form-control text-center",
                staticStyle: { width: "50px" },
                attrs: {
                  type: "tel",
                  pattern: "\\d*",
                  placeholder: "HH",
                  maxlength: "2",
                  size: "2",
                },
                domProps: { value: _vm.hoursText },
                on: {
                  mouseup: _vm.selectInputValue,
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.changeTime(1, 1)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.changeTime(1, 0)
                    },
                  ],
                  wheel: function ($event) {
                    return _vm.onWheel($event, true)
                  },
                  change: function ($event) {
                    _vm.hoursText = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("td", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.lazy",
                    value: _vm.minutesText,
                    expression: "minutesText",
                    modifiers: { lazy: true },
                  },
                ],
                ref: "minutesInput",
                staticClass: "form-control text-center",
                staticStyle: { width: "50px" },
                attrs: {
                  type: "tel",
                  pattern: "\\d*",
                  placeholder: "MM",
                  readonly: _vm.readonly,
                  maxlength: "2",
                  size: "2",
                },
                domProps: { value: _vm.minutesText },
                on: {
                  mouseup: _vm.selectInputValue,
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.changeTime(0, 1)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _vm.changeTime(0, 0)
                    },
                  ],
                  wheel: function ($event) {
                    return _vm.onWheel($event, false)
                  },
                  change: function ($event) {
                    _vm.minutesText = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm.controls
            ? _c("tr", { staticClass: "text-center" }, [
                _c(
                  "td",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "link",
                          size: "sm",
                          disabled: _vm.readonly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTime(1, 0)
                          },
                        },
                      },
                      [_c("i", { class: _vm.iconControlDown })]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "link",
                          size: "sm",
                          disabled: _vm.readonly,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTime(0, 0)
                          },
                        },
                      },
                      [_c("i", { class: _vm.iconControlDown })]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_vm._v(" "), _c("b", [_vm._v(":")]), _vm._v(" ")])
  },
]
render._withStripped = true

export { render, staticRenderFns }