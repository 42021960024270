import axios from 'axios';
import { Message } from 'element-ui';
import { ERROR_MSG_ABORTED } from '@/common/utils/request';
import i18n from '@/front/shared/lang';

const service = axios.create({
    baseURL: 'web/api/admin',
    //timeout: 20000, // Request timeout
});

export const serviceCommon = axios.create({
    baseURL: 'web/api',
});

export const requestCommon = serviceCommon;

function useRequest(config) {
    return config;
}

function useRequestError(error) {
    console.error(error); // for debug
    return Promise.reject(error);
}

service.interceptors.request.use(useRequest, useRequestError);
serviceCommon.interceptors.request.use(useRequest, useRequestError);

function useResponse(response) {
    return response.data;
}

function useResponseError(error) {
    console.error('admin response error', error); // for debug
    if (isCancel(error)) {
        return Promise.reject(error);
    }

    const url = error.config.url || '';
    if (url.indexOf('/auth/login') === -1) {
        if (error.response && error.response.status === 401) {
            location.assign('/admin#/login');
            return Promise.reject(error);
        }
    }

    if (error.message === ERROR_MSG_ABORTED) {
        return Promise.reject(error);
    }

    let message = (error.response && error.response.data.message) || error.message;
    message = i18n.t(message);

    Message({
        message: message,
        type: 'error',
        duration: 5 * 1000,
    });
    return Promise.reject(error);
}

service.interceptors.response.use(useResponse, useResponseError);
serviceCommon.interceptors.response.use(useResponse, useResponseError);

export default service;

export const CancelToken = axios.CancelToken;

export const isCancel = axios.isCancel;
