import _ from 'lodash'
import { DateTime } from "luxon";

export function transformError(errors) {
    if (!errors) return {};

    return _.reduce(errors, (result, value, key) => {
        result[key] = value[0];
        return result;
    }, {});
}

export function isObject(value) {
    return _.isObject(value);
}

export function mdt(value, format = undefined, options = {}) {
    if (value instanceof DateTime) {
        return value;
    }

    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }

    if (typeof value === 'string') {
        if (format) {
            return DateTime.fromFormat(value, format, options);
        }
        if (value.indexOf('T') === -1) {
            return DateTime.fromSQL(value, options);
        }
        return DateTime.fromISO(value, options);
    }

    if (typeof value === 'number') {
        return DateTime.fromJSDate(new Date(value), options);
    }

    return null;
}
