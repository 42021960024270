var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        size: "lg",
        busy: _vm.busy,
        okTitle: _vm.$t("dialog.send"),
        cancelTitle: _vm.$t("dialog.close"),
      },
      on: { hidden: _vm.handleHidden, ok: _vm.handleOk },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "form-wrapper",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.busy,
              expression: "busy",
            },
          ],
          attrs: { validator: _vm.$v.form },
        },
        [
          _c(
            "form-group",
            {
              attrs: {
                name: "email",
                label: _vm.$t("form.email"),
                attribute: _vm.$t("form.email"),
              },
            },
            [
              _c("b-form-input", {
                attrs: { type: "email" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "form-group",
            {
              attrs: {
                name: "subject",
                label: _vm.$t("form.email_subject"),
                attribute: _vm.$t("form.email_subject"),
              },
            },
            [
              _c("b-form-input", {
                attrs: { type: "email" },
                model: {
                  value: _vm.form.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "subject", $$v)
                  },
                  expression: "form.subject",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "form-group",
            {
              attrs: {
                name: "message",
                label: _vm.$t("form.email_message"),
                attribute: _vm.$t("form.email_message"),
              },
            },
            [
              _c("b-form-textarea", {
                attrs: { rows: "10" },
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }