import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import FormGroup from '@/front/shared/components/FormGroup';
import FormSummary from '@/front/shared/components/FormSummary';

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
    i18n: 'validations',
    /*template: templates.singleErrorExtractor.bootstrap4,*/
    i18nAttributes: {},
});

Vue.component('FormGroup', FormGroup);
Vue.component('FormSummary', FormSummary);
Vue.component('FormWrapper', templates.FormWrapper);
