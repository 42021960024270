var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        size: "md",
        busy: _vm.pendingPaymentGetAddInfo || _vm.pendingOk,
        okTitle: _vm.$t("dialog.tariff_payment_add.ok", {
          amount: _vm.$options.filters.tCurrency(
            _vm.form.amount,
            _vm.form.currency
          ),
        }),
        cancelTitle: _vm.$t("dialog.cancel"),
        okOnly: "",
      },
      on: { hidden: _vm.reset, ok: _vm.handleOk },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pendingPaymentGetAddInfo,
              expression: "pendingPaymentGetAddInfo",
            },
          ],
        },
        [
          _c("b-form-group", [
            _c("label", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.$t("dialog.tariff_payment_add.amount"))),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm._f("tCurrency")(_vm.form.amount, _vm.form.currency))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-form-group",
            { staticClass: "mb-0" },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("dialog.payment_add.payment_method"))),
              ]),
              _vm._v(" "),
              _c("pay-methods", {
                attrs: { list: _vm.payMethods },
                model: {
                  value: _vm.form.payMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "payMethod", $$v)
                  },
                  expression: "form.payMethod",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }