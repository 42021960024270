export const ADD_BLOCK = 'ADD_BLOCK';
export const REMOVE_BLOCK = 'REMOVE_BLOCK';

export const ADD_TRACK = 'ADD_TRACK';
export const ADD_TRACK_SUCCESS = 'ADD_TRACK_SUCCESS';
export const ADD_TRACK_FAILED = 'ADD_TRACK_FAILED';

export const REMOVE_TRACK = 'REMOVE_BLOCK';
export const REMOVE_TRACK_SUCCESS = 'REMOVE_TRACK_SUCCESS';
export const REMOVE_TRACK_FAILED = 'REMOVE_TRACK_FAILED';

export const SHOW_MODAL_TARIFF_PAYMENT_ADD = 'SHOW_MODAL_TARIFF_PAYMENT_ADD';

export const LOAD_LANGUAGE = 'LOAD_LANGUAGE';
