import { CancelToken, isCancel } from "@/common/utils/request";

export default function mixinRequestWithCancel(namespace, apiMethod) {
    const nsUpper = _.upperFirst(namespace);
    const nsLower = _.lowerFirst(namespace);

    const pendingCounter = `pending${nsUpper}Counter`;
    const dError = `error${nsUpper}`;
    const pending = `pending${nsUpper}`;
    const pCancel = `${nsLower}Cancel`;

    const mFetch = `fetch${nsUpper}`;
    const mCancel = `cancel${nsUpper}`;

    return {
        data() {
            return {
                [pendingCounter]: 0,
                [dError]: false,
            }
        },
        computed: {
            [pending]() {
                return this[pendingCounter] > 0;
            }
        },
        created() {
            this[pCancel] = null;
        },
        methods: {
            async [mFetch](data) {
                this[mCancel]();
                this[pendingCounter]++;

                try {
                    this[pCancel] = CancelToken.source();
                    const response = await apiMethod(data, { cancelToken: this[pCancel].token });
                    this[dError] = false;
                    return response.data;
                } catch (error) {
                    if (!isCancel(error)) {
                        this[dError] = true;
                    }
                } finally {
                    if (this[pendingCounter] > 0) {
                        this[pendingCounter]--;
                    }
                }
            },
            [mCancel]() {
                if (this[pCancel]) {
                    this[pCancel].cancel();
                    this[pCancel] = null;
                    return true;
                }
                return false;
            },
        }
    };
}
