import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import sessionEditor from './modules/session-editor';
import folder from './modules/folder';
import favorite from './modules/favorite';
import user from './modules/user';
import app from './modules/app';

Vue.use(Vuex);

let store = new Vuex.Store({
    modules: {
        app,
        user,
        sessionEditor,
        folder,
        favorite,
    },
    getters,
});

export default store;
