var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-input-number" }, [
    _vm.arrow
      ? _c("div", { staticClass: "v-input-number-arrows" }, [
          _c(
            "a",
            {
              staticClass: "v-input-number-up",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClick($event, "up")
                },
                mouseup: _vm.onMouseup,
                mousedown: function ($event) {
                  return _vm.onMousedown($event, "up")
                },
              },
            },
            [_c("i", { staticClass: "v-input-number-icon" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "v-input-number-down",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onClick($event, "down")
                },
                mouseup: _vm.onMouseup,
                mousedown: function ($event) {
                  return _vm.onMousedown($event, "down")
                },
              },
            },
            [_c("i", { staticClass: "v-input-number-icon" })]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.number",
          value: _vm.quantity,
          expression: "quantity",
          modifiers: { number: true },
        },
      ],
      class: _vm.inputclass,
      attrs: {
        type: "text",
        maxlength: _vm.maxlength,
        autocomplete: "off",
        placeholder: _vm.placeholder,
        readonly: !_vm.editable,
      },
      domProps: { value: _vm.quantity },
      on: {
        keyup: function ($event) {
          return _vm.onKeyup($event)
        },
        keydown: function ($event) {
          return _vm.onKeydown($event)
        },
        blur: [
          _vm.onBlur,
          function ($event) {
            return _vm.$forceUpdate()
          },
        ],
        input: function ($event) {
          if ($event.target.composing) return
          _vm.quantity = _vm._n($event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }