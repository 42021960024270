import '../shared/bootstrap';
import Vue from 'vue';
import App from '@/front/shared/views/App';
import router from './router';
import store from '../shared/store';
import i18n from '../shared/lang';
import VuePageTitle from 'vue-page-title';
import '@/front/shared/components';
import '@/front/shared/filters';
import { initRouterPermission } from '@/front/shared/permission';
import '@/front/shared/validate';
import '@/icons';
import '@/front/shared/directives';
import '@/front/shared/plugins';
import { useI18n } from '@/common/utils/transList';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Vue.config.productionTip = false;

Sentry.init({
    dsn: window.config.sentry.dsn,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    environment: window.config.env,
    enabled: window.config.sentry.enable,
});

Vue.use(VuePageTitle, { router });
useI18n(i18n);

readConfig();

function readConfig() {
    const config = window.config;
    store.dispatch('updateUser', config.currentUser);
    store.dispatch('updateSubscription', config.subscription);
    store.dispatch('setHasLockedSessions', config.state?.has_locked_sessions ?? false);
}

initRouterPermission(() => app, router);

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    mounted() {
        document.querySelector('#preloader').style.display = 'none';
    },
    render: (h) => h(App),
});

export default app;
