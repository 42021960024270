export default class CalculateBlocksTiming {
    constructor(blocks) {
        this.totalTime = 0;
        this.hasTimeBuffer = false;
        this.hasTimeOverlap = false;
        this.blocks = blocks;
    }

    startTimeTransform(time) {
        //time = new Date(time);
        //time = time.getHours() * 60 + time.getMinutes() + time.getTimezoneOffset();
        if (time < 0) {
            time = time + 24 * 60;
        }
        return time;
    }

    clearTimeInfo(block) {
        block.startTime = null;
        block.time_buffer = null;
        block.time_overlap = null;
        _.each(block.child_blocks, (nestedBlock) => this.clearTimeInfo(nestedBlock));
    }

    calculateBlocksOverlap(blocks, currentTime) {
        return _.reduceRight(
            blocks,
            (memo, block) => {
                let endTime, result;
                block.time_overlap = null;
                result = {
                    hasTimeOverlap: memo.hasTimeOverlap,
                };

                if (block.kind == 1) {
                    endTime = block.startTime;
                    if (!isNaN(parseInt(block.duration))) {
                        endTime += parseInt(block.duration, 10);
                    }
                    if (endTime > memo.currentTime) {
                        block.time_overlap = endTime - memo.currentTime;
                        result.hasTimeOverlap = true;
                    }
                    block.relativeLength = endTime - block.startTime - (block.time_overlap || 0);
                    result.currentTime = block.startTime - block.time_buffer;
                } else if (block.kind == 2) {
                    result = this.calculateBlocksOverlap(block.child_blocks, memo.currentTime);
                    result.currentTime = _.min([block.startTime, result.currentTime]);
                    result.hasTimeOverlap || (result.hasTimeOverlap = memo.hasTimeOverlap);
                    block.relativeLength =
                        _.min([block.endTime, memo.currentTime]) - result.currentTime;
                } else if (block.kind == 3) {
                    result = _.reduce(
                        block.child_blocks,
                        (trackMemo, track) => {
                            track.hasTimeOverlap = null;
                            let trackCurrentTime = _.min([track.endTime, memo.currentTime]);
                            let trackResult = this.calculateBlocksOverlap(
                                track.child_blocks,
                                trackCurrentTime,
                            );
                            track.hasTimeOverlap = trackResult.hasTimeOverlap;
                            trackResult.currentTime = _.min([
                                trackMemo.currentTime,
                                trackResult.currentTime,
                                block.startTime,
                            ]);
                            trackResult.hasTimeOverlap =
                                trackResult.hasTimeOverlap || trackMemo.hasTimeOverlap;
                            trackResult.endTime = _.min([trackMemo.endTime, trackCurrentTime]);
                            track.relativeLength = trackCurrentTime - trackResult.currentTime;
                            return trackResult;
                        },
                        {
                            currentTime: memo.currentTime,
                            hasTimeOverlap: memo.hasTimeOverlap,
                            endTime: memo.currentTime,
                        },
                    );
                    block.relativeLength = memo.currentTime - result.currentTime;
                    block.endTime = block.startTime + block.relativeLength;
                }
                return result;
            },
            {
                currentTime: currentTime,
                hasTimeOverlap: false,
            },
        );
    }

    calculateBlocksTime(blocks, initialTotal) {
        initialTotal = initialTotal ? { ...initialTotal } : { current: null, overall: 0 };
        initialTotal.startTime = null;

        return _.reduce(
            blocks,
            (total, block) => {
                let candidateTotals,
                    difference,
                    groupTotal,
                    maxTotal,
                    mergedValues,
                    minStart,
                    newStartTime,
                    startTime;
                block.time_buffer = null;
                if (block.start_time) {
                    startTime = this.startTimeTransform(block.start_time);
                    if (total.current === null) {
                        total.current = startTime;
                    }
                    difference = startTime - total.current;
                    if (difference > 0) {
                        block.time_buffer = difference;
                    }
                    total.current = startTime;
                }

                // Мы используем эту возможность для обновления начала блока и относительного времени
                block.relativeTime = total.overall;

                if (!isNaN(difference)) {
                    block.relativeTime = block.relativeTime + difference;
                }

                if (total.current === null) {
                    total.current = 0;
                }
                block.startTime = total.current;
                if (!isNaN(parseInt(block.duration))) {
                    // Добавить длину блока к общей сумме
                    total.current += parseInt(block.duration, 10);
                }

                if (!isNaN(parseInt(block.duration))) {
                    total.overall += parseInt(block.duration, 10);
                }

                if (!isNaN(difference)) {
                    //&& difference > 0
                    total.overall += difference;
                }

                if (block.time_buffer) {
                    total.hasTimeBuffer = true;
                }

                if (block.kind == 1) {
                    block.endTime = block.startTime + block.duration;
                }

                if (block.kind == 2) {
                    groupTotal = this.calculateBlocksTime(block.child_blocks, total);
                    total.current = groupTotal.current;
                    total.overall = groupTotal.overall;

                    if (groupTotal.startTime) {
                        newStartTime = _.min([block.startTime, groupTotal.startTime]);
                        difference = block.startTime - newStartTime;
                        block.relativeTime -= difference;
                        if (difference > 0) {
                            block.time_buffer -= difference;
                            if (block.time_buffer <= 0) {
                                block.time_buffer = null;
                            }
                            block.timeShifted = difference;
                        }
                        block.startTime = newStartTime;
                    }
                    block.endTime = groupTotal.current;
                }

                if (block.kind == 3) {
                    block.timeShifted = null;
                    // Первый запуск, чтобы получить самое раннее время начала
                    candidateTotals = [];
                    _.each(block.child_blocks, (track) => {
                        let tmpTotal;
                        tmpTotal = this.calculateBlocksTime(track.child_blocks, total);
                        candidateTotals.push(tmpTotal);
                    });

                    minStart = _.min(
                        _.compact(
                            _.map(candidateTotals, function (t) {
                                return t.startTime;
                            }),
                        ),
                    );
                    if (minStart && minStart < block.startTime) {
                        difference = block.startTime - minStart;
                        total.overall -= difference;
                        total.current = minStart;
                        block.time_buffer -= difference;
                        if (block.time_buffer <= 0) {
                            block.time_buffer = null;
                        }
                        block.timeShifted = difference;
                        block.startTime = minStart;
                        block.relativeTime = total.overall;
                    }

                    // Второй запуск, чтобы фактически настроить все блоки, основанные на самом раннем времени запуска
                    candidateTotals = [];
                    _.each(block.child_blocks, (track) => {
                        let tmpTotal;
                        tmpTotal = { ...total, hasTimeBuffer: false };
                        tmpTotal = this.calculateBlocksTime(track.child_blocks, tmpTotal);
                        track.endTime = tmpTotal.current;
                        candidateTotals.push(tmpTotal);
                        track.hasTimeBuffer = tmpTotal.hasTimeBuffer;
                        track.startTime = tmpTotal.startTime;
                    });

                    maxTotal = _.reduce(
                        candidateTotals,
                        function (memo, current) {
                            if (current.overall >= memo.overall) {
                                return current;
                            } else {
                                return memo;
                            }
                        },
                        total,
                    );

                    mergedValues = {
                        hasTimeBuffer: total.hasTimeBuffer || maxTotal.hasTimeBuffer,
                    };
                    _.extend(total, maxTotal, mergedValues);
                }

                if (total.startTime === null) {
                    total.startTime = block.startTime;
                }

                return total;
            },
            initialTotal,
        );
    }

    refreshTotalTime() {
        let totalTime = this.calculateBlocksTime(this.blocks);
        this.totalTime = totalTime.overall;
        this.hasTimeBuffer = totalTime.hasTimeBuffer;
        let overlapResult = this.calculateBlocksOverlap(this.blocks, totalTime.current);
        this.hasTimeOverlap = overlapResult.hasTimeOverlap;
    }
}
