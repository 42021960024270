import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(query = {}) {
    return request({
        url: '/cards',
        method: 'get',
        params: query,
    });
}

export function remove(id) {
    return request({
        url: `/cards/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/cards/${id}`,
        method: 'get',
    });
}

export function update(id, data) {
    return request({
        url: `/cards/${id}`,
        method: 'put',
        data,
    });
}

export function store(data) {
    return request({
        url: '/cards',
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}

export function getCardsByType(type) {
    return request({
        url: `/cards/type/${type}`,
        method: 'get',
    });
}

export function getModules() {
    return request({
        url: '/cards/modules',
        method: 'get',
    });
}

export function getRequests() {
    return request({
        url: '/cards/requests',
        method: 'get',
    });
}

export function getResultFormats() {
    return request({
        url: '/cards/resultFormats',
        method: 'get',
    });
}

export function getCardModules(params) {
    return request({
        url: '/cards/card/modules',
        method: 'get',
        params,
    });
}

export function searchMethods(params) {
    return request({
        url: '/cards/methods/search',
        method: 'get',
        params,
    });
}

export function searchModules(params) {
    return request({
        url: '/cards/modules/search',
        method: 'get',
        params,
    });
}

export function getMethodsCategories(params) {
    return request({
        url: '/card-category-methods',
        method: 'get',
        params,
    });
}

export function getModulesByCategories(category, params) {
    return request({
        url: `cards/${category}/modules`,
        method: 'get',
        params,
    });
}

export function attachedFiles(id, data) {
    return request({
        url: `/cards/${id}/attachment`,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    });
}

export function getModuleCards(id) {
    return request({
        url: `cards/modules/${id}/cards`,
        method: 'get',
    });
}

export function attachTeam(cardId, teamId) {
    return request({
        url: `cards/${cardId}/teams/${teamId}/attach`,
        method: 'get',
    });
}

export function detachTeam(cardId, teamId) {
    return request({
        url: `cards/${cardId}/teams/${teamId}/detach`,
        method: 'get',
    });
}

export function toggleTeam(cardId, teamId, attach) {
    return request({
        url: `cards/${cardId}/teams/${teamId}/toggle`,
        method: 'get',
        params: {
            attach: !!attach,
        },
    });
}

export function getModuleByCardCards(id) {
    return request({
        url: `cards/card/${id}/modules`,
        method: 'get',
    });
}

export function getUsedCards(sessionId) {
    return request({
        url: `cards/session/${sessionId}/used-cards`,
        method: 'get',
    });
}

export function cooperativeList(params) {
    return request({
        url: `/cards/cooperative/list`,
        method: 'get',
        params,
    });
}

export function cooperativeTeams() {
    return request({
        url: `/cards/cooperative/teams`,
        method: 'get',
    });
}
