import Vue from 'vue';
import i18n from './../lang';
import store from './../store';
import Cookies from 'js-cookie';

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import ElementUI from 'element-ui';
Vue.use(ElementUI, {
    size: Cookies.get('size') || 'medium',
    i18n: (key, value) => i18n.t(key, value),
});

import LiquorTree from 'liquor-tree';
Vue.use(LiquorTree);

import CommentEmbedded from '@/front/shared/plugins/CommentEmbedded';
Vue.use(CommentEmbedded);

import mApp from '@/front/shared/plugins/App';
Vue.use(mApp);

import VueToastr from 'vue-toastr';
Vue.use(VueToastr, {
    progressbar: false,
});

import { Settings as luxonSettings } from 'luxon';
//luxonSettings.defaultZoneName = "utc";

import AwesomeSwiper from '@/common/components/awesome-swiper';
Vue.use(AwesomeSwiper);

import Mnotify from '@/front/shared/plugins/Mnotify';
Vue.use(Mnotify);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

/*import BvSelect from '@alfsnd/vue-bootstrap-select'
Vue.component('bv-select', BvSelect);*/

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import Datetime from 'vue-datetime';
Vue.use(Datetime);

import VueUploadComponent from 'vue-upload-component';
Vue.component('file-upload', VueUploadComponent);

import DatePicker from 'vue2-datepicker';
DatePicker.props.lang.default = store.getters.language;
Vue.use(DatePicker);

import { register as registerTimeAgo } from 'timeago.js';
import timeAgoRu from 'timeago.js/lib/lang/ru';
registerTimeAgo('ru', timeAgoRu);

import { VueMaskDirective, VueMaskFilter } from 'v-mask';
Vue.directive('mask', VueMaskDirective);
Vue.filter('VMask', VueMaskFilter);
