import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function remove(id) {
    return request({
        url: `/users/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/users/${id}`,
        method: 'get',
    });
}

export function update(id, data) {
    return request({
        url: `/users/${id}`,
        method: 'put',
        data,
    });
}

export function store(data) {
    return request({
        url: `/users`,
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}

export function changePassword(id, data) {
    return request({
        url: `/users/change-password/${id}`,
        method: 'post',
        data,
    });
}

export function find(params, cancelToken = null) {
    return request({
        url: `/users/find`,
        method: 'get',
        params,
        cancelToken: cancelToken ? cancelToken.token : null,
    });
}

export function changeAvatar(id, data) {
    return request({
        url: `/users/${id}/avatar`,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    });
}

export function getSubscriptions(id) {
    return request({
        url: `/users/${id}/subscriptions`,
        method: 'get',
    });
}

export function getTariff(id) {
    return request({
        url: `/users/${id}/tariff`,
        method: 'get',
    });
}

export function getBalance() {
    return request({
        url: `/users/balance`,
        method: 'get',
    });
}

export function getTariffInfo() {
    return request({
        url: `/users/tariff-info`,
        method: 'get',
    });
}

export function updateSubscription(data) {
    return request({
        url: `/users/update-subscription`,
        method: 'post',
        data,
    });
}

export function cancelSubscription() {
    return request({
        url: `/users/cancel-subscription`,
        method: 'post',
    });
}

export function prolongSubscription(data) {
    return request({
        url: `/users/prolong-subscription`,
        method: 'post',
        data,
    });
}

export function changeSubscription(data) {
    return request({
        url: `/users/change-subscription`,
        method: 'post',
        data,
    });
}

export function changeSubscriptionUsages(data) {
    return request({
        url: `/users/change-subscription-usages`,
        method: 'post',
        data,
    });
}
