export function identifyModel(obj) {
    if (obj == null) return false;

    if ('kind' in obj) return 'Block';
    if ('company_name' in obj) return 'Session';

    return false;
}

export function isObjModel(obj, model) {
    return identifyModel(obj) == model;
}

export function isObjBlock(obj) {
    return isObjModel(obj, models.Block);
}

export function isObjSession(obj) {
    return isObjModel(obj, models.Session);
}

export const models = {
    Block: 'Block',
    Session: 'Session',
};
