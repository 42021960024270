import store from '@/front/shared/store';
import NProgress from 'nprogress';
import { loadLanguageAsync } from '@/front/shared/lang';
import { LOAD_LANGUAGE } from '@/front/shared/event-names';

NProgress.configure({ showSpinner: false });

function isForAnon(route) {
    return route.matched.some((record) => record.meta.anon);
}

function isForGuests(route) {
    return route.matched.some((record) => record.meta.guest);
}

export function initRouterPermission(getApp, router) {
    router.beforeEach((to, from, next) => {
        loadLanguageAsync(store.getters.language).then((lang) => {
            next();
            const app = getApp();
            app?.$emit(LOAD_LANGUAGE, lang);
        });
    });

    router.beforeEach((to, from, next) => {
        NProgress.start();

        if (!/^\/sessions/.test(to.path)) {
            store.dispatch('sessionEditor/reset');
        }

        if (!store.getters.loggedIn) {
            if (!to.matched.some((record) => record.meta.auth)) {
                next();
                return;
            }

            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });

            NProgress.done();
            return;
        }

        if (store.getters.isAnon) {
            if (!isForAnon(to)) {
                next({ path: '/login' });
                NProgress.done();
                return;
            }
        } else {
            if (isForGuests(to)) {
                next('/');
                NProgress.done();
                return;
            }
        }

        if (to.matched.some((record) => !!record.meta.role)) {
            if (to.matched.some((record) => record.meta.role === store.getters.role)) {
                next();
                return;
            }
            next('/');
            NProgress.done();
            return;
        }

        next();
    });

    router.afterEach(() => {
        NProgress.done();
    });
}
