var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    _vm._b(
      { attrs: { label: _vm.label, error: _vm.errorMessage } },
      "el-form-item",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }