<template>
    <AppHeaderDropdown right class="pr-2">
        <template slot="header">
            <img :src="avatarUrl" class="img-avatar" :alt="name" />
        </template>

        <template slot="dropdown">
            <b-dropdown-item @click="handleProfile">
                <i class="fas fa-user" /> {{ $t('nav.my_profile') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleTariff" v-if="visibleTariff">
                <i class="fas fa-dollar-sign" /> {{ $t('nav.tariff') }}
            </b-dropdown-item>
            <b-dropdown-item @click="handleTeams">
                <i class="fas fa-users" /> {{ $t('nav.teams') }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="handleLogout">
                <i class="fas fa-lock" /> {{ $t('nav.logout') }}
            </b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
    import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue';
    import { mapGetters } from 'vuex';
    import { isAdminOfAnyTeam } from '@/front/sibur/utils/misc';

    export default {
        name: 'DefaultHeaderDropdownAccnt',
        components: {
            AppHeaderDropdown,
        },
        computed: {
            ...mapGetters(['avatar', 'name', 'role', 'subscription']),

            teams() {
                return this.$store.state.user.teams;
            },
            avatarUrl() {
                return (this.avatar && this.avatar.url) || '/img/no_avatar.jpg';
            },
            isAdmin() {
                return this.role === 'admin' ? true : false;
            },
            visibleTariff() {
                return this.subscription.canUseCooperativeScenarios && isAdminOfAnyTeam(this.teams);
            },
        },
        methods: {
            handleProfile() {
                this.$router.push({ name: 'profile' });
            },
            handleTariff() {
                this.$router.push({ name: 'tariff' });
            },
            handleTeams() {
                this.$router.push({ name: 'teams' });
            },
            handleLogout() {
                this.$store.dispatch('LogOut').then(() => {
                    location.assign('/'); // Re-instantiate the vue-router object to avoid bugs
                });
            },
        },
    };
</script>
