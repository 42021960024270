import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function list(params) {
    return request({
        url: `payments`,
        method: 'get',
        params,
    });
}

export function newPayment(data) {
    return request({
        url: 'payments/new-payment',
        method: 'post',
        data,
    });
}

export function orderPayment(data) {
    return request({
        url: 'payments/order-payment',
        method: 'post',
        data,
    });
}

export function getAddInfo() {
    return request({
        url: 'payments/add-info',
        method: 'get',
    });
}

export function checkInvoice(invoiceId) {
    return request({
        url: `payments/check-invoice/${invoiceId}`,
        method: 'post',
    });
}

export function updateInvoice(invoiceId, data) {
    return request({
        url: `payments/update-invoice/${invoiceId}`,
        method: 'post',
        data,
    });
}
