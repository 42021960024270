import { isPlainObject } from 'lodash';

const getters = {
    avatar: (state) => state.user.avatar,
    balance: (state) => state.user.balance,
    name: (state) => state.user.name,
    subscription: (state) => state.user.subscription,
    roles: (state) => state.user.roles,
    role: (state) => state.user.role,
    language: (state) => state.app.language,
    loggedIn: (state) => !!state.user.id,
    authUserId: (state) => state.user.id,
    isAnon: (state) => state.user.is_anon,
    isAdmin: (state) => state.user.role === 'admin',
    checkAdmin:
        (state, getters, rootState, rootGetters) =>
        (user = undefined) => {
            if (user === undefined) {
                user = getters.authUserId;
            }
            let userId = isPlainObject(user) ? user.id : user;
            return rootState.user?.id == userId && rootState.user?.role === 'admin';
        },
};

export default getters;
