var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tinymce", {
    ref: "tinymceinline",
    attrs: {
      value: _vm.value,
      height: _vm.height,
      plugins: _vm.plugins,
      maxchar: _vm.maxchar,
      menubar: false,
      inline: "",
      "insert-toolbar": "",
      toolbar: "undo redo | bold italic underline bullist numlist anchor",
      readonly: _vm.readonly,
      placeholder: _vm.placeholder,
    },
    on: { input: _vm.onChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }