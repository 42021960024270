import Vue from 'vue';
import Cookies from 'js-cookie';
import ElementUI from 'element-ui';
import App from './views/App';
import store from './store';
import router from '@/admin/router';
import i18n from './lang'; // Internationalization
import '@/common/filters';
import '@/admin/validate';
import '@/icons'; // icon
import '@/admin/permission'; // permission control
import { useI18n } from '@/common/utils/transList';

import 'swiper/css/swiper.css';
//import 'swiper/swiper-bundle.css';
import AwesomeSwiper from '@/common/components/awesome-swiper';

Vue.use(AwesomeSwiper);

import '@/admin/bootstrap';

import * as filters from '@/admin/filters'; // global filters

useI18n(i18n);

Vue.use(ElementUI, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    i18n: (key, value) => i18n.t(key, value),
});

// register global utility filters.
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

readConfig();
async function readConfig() {
    await store.dispatch('updateUser', window.config.currentUser);
    if (window.config.currentUser) {
        await store.dispatch('GenerateRoutes', { roles: window.config.currentUser.role });
        router.addRoutes(store.getters.addRouters);
    }
}

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: (h) => h(App),
});
