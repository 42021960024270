import * as favoriteApi from '@/front/shared/api/favorite';

const state = {
    list: [],
};

const getters = {
    favoriteByType: (state) => (type) =>
        state.list.filter(
            (item) =>
                item.favoriteable_type === 'Card' &&
                item.favoriteable &&
                item.favoriteable.type === type,
        ),
    favoriteRequests: (state, getters) => getters.favoriteByType('request'),
    favoriteResults: (state, getters) => getters.favoriteByType('result_format'),
    favoriteModules: (state, getters) => getters.favoriteByType('module'),
    favoriteMethods: (state, getters) => getters.favoriteByType('method'),
    hasFavorite: (state) => (favoriteable_id) =>
        state.list.some(
            (item) => item.favoriteable_type === 'Card' && item.favoriteable_id === favoriteable_id,
        ),
    getFavorite: (state) => (favoriteable_id) =>
        state.list.find(
            (item) => item.favoriteable_type === 'Card' && item.favoriteable_id === favoriteable_id,
        ),
};

const mutations = {
    ADD(state, value) {
        state.list.push(value);
    },
    UPDATE_LIST(state, value) {
        state.list = value || [];
    },
    REMOVE(state, value) {
        const idx = state.list.findIndex((item) => item.id === value.id);
        state.list.splice(idx, 1);
    },
};

const actions = {
    async add({ commit }, data) {
        const response = await favoriteApi.store(data);
        commit('ADD', response.data);
        return response;
    },
    async load({ commit }) {
        const response = await favoriteApi.fetchList();
        commit('UPDATE_LIST', response.data);
        return response;
    },
    async remove({ commit }, data) {
        const response = await favoriteApi.remove(data.id);
        commit('REMOVE', data);
        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
