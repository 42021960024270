import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(id, query = {}) {
    return Promise.resolve();
}

export function remove(id) {
    return request({
        url: `/comments/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/comments/${id}`,
        method: 'get',
    });
}

export function update(id, data) {
    return request({
        url: `/comments/${id}`,
        method: 'put',
        data,
    });
}

export function store(data) {
    return request({
        url: `/comments`,
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}

export function resolve(id) {
    return request({
        url: `/comments/${id}/resolve`,
        method: 'get',
    });
}
