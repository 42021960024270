import { render, staticRenderFns } from "./CurrencySwitcher.vue?vue&type=template&id=bcb543f6"
import script from "./CurrencySwitcher.vue?vue&type=script&lang=js"
export * from "./CurrencySwitcher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/www-root/data/www/view.sessiondesign.ru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bcb543f6')) {
      api.createRecord('bcb543f6', component.options)
    } else {
      api.reload('bcb543f6', component.options)
    }
    module.hot.accept("./CurrencySwitcher.vue?vue&type=template&id=bcb543f6", function () {
      api.rerender('bcb543f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/front/shared/components/CurrencySwitcher.vue"
export default component.exports