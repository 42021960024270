var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pay-methods" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "li",
        {
          staticClass: "pay-methods__item",
          class: { "pay-methods__item--selected": _vm.isSelected(item) },
          on: {
            click: function ($event) {
              _vm.selected = item
            },
          },
        },
        [
          _c("div", { staticClass: "pay-methods__logo" }, [
            _c("img", { attrs: { src: item.image, alt: item.name } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pay-methods__text" }, [
            _vm._v(_vm._s(item.name)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }