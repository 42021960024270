var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        title: _vm.$t("collaborators.title"),
        size: "md",
        busy: _vm.busy,
        okOnly: "",
        okTitle: _vm.$t("dialog.close"),
      },
      on: { hidden: _vm.handleHidden },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("collaborators", {
            attrs: {
              list: _vm.collaborators,
              "is-collaborator": "",
              roles: _vm.collaborationRoles,
              "session-id": _vm.sessionId,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }