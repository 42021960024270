import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function list(sessionId) {
    return request({
        url: `/sessions/${sessionId}/collaborations`,
        method: 'get',
    });
}

export function remove(id, sessionId) {
    return request({
        url: `/sessions/${sessionId}/collaborations/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id, sessionId) {
    return request({
        url: `/sessions/${sessionId}/collaborations/${id}`,
        method: 'get',
    });
}

export function update(id, sessionId, data) {
    return request({
        url: `/sessions/${sessionId}/collaborations/${id}`,
        method: 'put',
        data,
    });
}

export function store(sessionId, data) {
    return request({
        url: `/sessions/${sessionId}/collaborations`,
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}
