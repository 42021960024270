var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pending,
          expression: "pending",
        },
      ],
      staticClass: "collaborators-box",
    },
    [
      _c("p", { staticClass: "mb-1" }, [
        _vm._v(_vm._s(_vm.$t("collaborators.access_levels"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "collaborators-list" },
        [
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "collaborator-item" },
              [
                _c(
                  "div",
                  { staticClass: "collaborator-item__icon" },
                  [
                    _vm.isInvitation(item)
                      ? [_c("i", { staticClass: "fa fa-2x fa-envelope" })]
                      : [
                          _c("img", {
                            staticClass: "collaborator-item__icon-avatar",
                            attrs: {
                              src: _vm.avatarUrl(item.user),
                              alt: item.user.name,
                            },
                          }),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "collaborator-item__body" },
                  [
                    _vm.isInvitation(item)
                      ? [_vm._v(_vm._s(item.invitation.email))]
                      : [
                          _c("span", [_vm._v(_vm._s(item.user.name))]),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(item.user.email)),
                          ]),
                        ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "collaborator-item__actions" },
                  [
                    _vm.isOwner(item)
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("collaborators.owner")) +
                              "\n                "
                          ),
                        ]
                      : [
                          _c(
                            "b-button-group",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "icon-btn",
                                  attrs: {
                                    right: "",
                                    variant: "link",
                                    "toggle-tag": "span",
                                    "no-caret": "",
                                    disabled: !_vm.isCollaborator,
                                    title: _vm.collaborationRoleName(item),
                                  },
                                },
                                [
                                  _c("template", { slot: "button-content" }, [
                                    item.role_id == 3
                                      ? _c("i", {
                                          staticClass: "fa fa-pencil fa-fw",
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-eye fa-fw",
                                        }),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasRole(4) && item.role_id == 3
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditCollaborator(
                                                item,
                                                4
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-eye fa-fw",
                                          }),
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.collaborationRoleName({
                                                  role_id: 4,
                                                })
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasRole(3) && item.role_id == 4
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditCollaborator(
                                                item,
                                                3
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil fa-fw",
                                          }),
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.collaborationRoleName({
                                                  role_id: 3,
                                                })
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemoveCollaborator(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times fa-fw",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("form.delete")) +
                                          "...\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.team
            ? [
                _c("div", { staticClass: "collaborator-item" }, [
                  _c(
                    "div",
                    { staticClass: "collaborator-item__icon" },
                    [[_c("i", { staticClass: "fa fa-2x fa-users" })]],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "collaborator-item__body" }, [
                    _c("span", [_vm._v(_vm._s(_vm.team.name))]),
                    _c("br"),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isCollaborator
        ? _c(
            "div",
            { staticClass: "collaborator-new" },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.$t("collaborators.invite_user")) + ":"),
              ]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  options: _vm.users,
                  placeholder: _vm.$t(
                    "collaborators.placeholder_invite_user_by_email"
                  ),
                  label: "name",
                  "track-by": "id",
                  loading: _vm.isLoading,
                  "internal-search": false,
                  "clear-on-select": false,
                  "close-on-select": true,
                  "show-no-results": false,
                  "show-no-options": false,
                  "hide-selected": true,
                  "reset-after": "",
                  "select-label": "",
                  "deselect-label": "",
                },
                on: {
                  select: _vm.handleSelectUser,
                  "search-change": _vm.handleSearchUser,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          !props.option.invite
                            ? _c("span", [_vm._v(_vm._s(props.option.name))])
                            : _c("span", [
                                _vm._v("invite "),
                                _c("em", [_vm._v(_vm._s(props.option.name))]),
                                _vm._v(" to SessionDesign"),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1850099508
                ),
                model: {
                  value: _vm.selectedUser,
                  callback: function ($$v) {
                    _vm.selectedUser = $$v
                  },
                  expression: "selectedUser",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }