import { login, logout } from '@/admin/api/login';
import { getToken, setToken, removeToken } from '@/admin/utils/auth';

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        roles: [],
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        UPDATE_USER(state, value) {
            if (!value) {
                state.id = null;
                state.name = null;
                state.avatar = '';
                state.role = '';
                return;
            }
            state.id = value.id;
            state.name = value.name;
            state.role = value.role;
            state.avatar = value.avatar || '';
        },
    },

    actions: {
        updateUser({ commit }, user) {
            commit('UPDATE_USER', user);
        },
        async Login({ commit }, userInfo) {
            const email = userInfo.email.trim();
            const response = await login(email, userInfo.password);
            console.log('Login response', response);
            return response;
        },
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit('SET_TOKEN', '');
                        commit('SET_ROLES', []);
                        removeToken();
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '');
                removeToken();
                resolve();
            });
        },
        ChangeRoles({ commit, dispatch }, role) {
            return new Promise(resolve => {
                commit('SET_ROLES', [role]);
                dispatch('GenerateRoutes', { roles: [role] }); // Re-render sidebar menu with new permission
                resolve();
            });
        },
    },
};

export default user;
