import _, { isArray, chain } from 'lodash';
import { mdt } from '@/common/utils';

// строим из вложенных объектов плоский объект
export function flatten(input, options = {}, output = {}) {
    const defaults = {
        key: 'id',
        reference: '',
        addRoot: true,
    };

    options = { ...defaults, ...options };
    const { key, reference, addRoot } = options;
    const references = reference.split('.');
    const [refRoot, refSub = refRoot] = references;

    input = input || [];
    if (!isArray(input)) {
        input = [input];
    }

    _flatten(input);
    return output;

    function _flatten(values, level = 1) {
        values.forEach((item) => {
            if (level === 1) {
                if (addRoot) _update(item);

                if (_referenceNotEmpty(item, refRoot)) {
                    _flatten(item[refRoot], level + 1);
                    item[refRoot] = null;
                }
                return;
            }

            _update(item);
            if (_referenceNotEmpty(item, refSub)) {
                _flatten(item[refSub], level + 1);
                item[refSub] = null;
            }
        });
    }

    function _referenceNotEmpty(item, ref) {
        return ref && item[ref] && item[ref].length > 0;
    }

    function _update(item) {
        output[item[key]] = item;
    }
}

export function mapWithFilterIds(values, prop, value, key = 'id') {
    if (!values) return [];
    return chain(values)
        .filter([prop, value])
        .map(key)
        .filter((item) => item != null)
        .value();
}

export function mapWithFilterIdsDeep(values, prop, value, key = 'id', deep = 4) {
    if (!values) return [];

    const set = new Set();
    _process(value);
    return [...set];

    function _process(elem, level = 1) {
        const ids = mapWithFilterIds(values, prop, elem, key);
        ids.forEach((item) => {
            set.add(item);

            if (level < deep) {
                _process(item, level + 1);
            }
        });
    }
}

export function getBlockSelected(values, id) {
    return mapWithFilterIds(values, 'parent_block_id', id, 'card');
}

export function transformBlockSyncCard(data, type) {
    return data.map((item) => {
        return {
            card_id: item.id,
            kind: 1,
            type: type,
        };
    });
}

export function expandBlock(block) {
    block.startTime = 0;
    block.endTime = 0;
    block.relativeLength = null;
    block.relativeTime = null;
    block.time_buffer = null;
    block.time_overlap = null;
    block.timeShifted = null;
}

export function extractExtraColumnValue(block, columns) {
    let extraColumnValues = block.extraColumnValues || [];

    let store = columns.reduce((colStore, item) => {
        let extraColumnValue = extraColumnValues.find(
            (column) => column.extra_column_id == item.id,
        );
        colStore[item.id] = {
            extra_column_id: item.id,
            block_id: block.id,
            value: (extraColumnValue && extraColumnValue.value) || '',
        };
        return colStore;
    }, {});

    delete block.extraColumnValues;
    return store;
}

export function extractExtraColumnValues(blocks, columns) {
    return _.reduce(
        blocks,
        (store, block) => {
            store[block.id] = extractExtraColumnValue(block, columns);
            return store;
        },
        {},
    );
}

export function createBlockDummy(data, mixin = {}) {
    return {
        ...data,
        id: _.now(),
        dummy: true,
        ...mixin,
    };
}

export function isBlockDummy(data) {
    return data && data.dummy;
}

export function buildNestedBlocks(root, getChildBlocks) {
    return nested([root]);

    function nested(blocks) {
        blocks.forEach((block) => {
            block.child_blocks = nested(getChildBlocks(block));
        });
        return blocks;
    }
}

export function prepareSession(session) {
    const copy = { ...session };
    const sessionId = session.id;
    copy.totalTime = mdt(0);
    const { blocks, child_sessions } = copy;
    delete copy.blocks;

    const fBlocks = flatten(child_sessions, { reference: 'blocks.child_blocks', addRoot: false });
    flatten(blocks, { reference: 'child_blocks' }, fBlocks);
    _.forEach(fBlocks, (item) => expandBlock(item));
    let extraColumnValues = extractExtraColumnValues(fBlocks, copy.extra_columns || []);

    let comments = _.reduce(
        fBlocks,
        (store, item) => {
            (item.comments || []).forEach((comment) => {
                store[comment.id] = _.cloneDeep(comment);
            });
            delete item.comments;
            return store;
        },
        {},
    );

    (copy.comments || []).forEach((comment) => {
        comments[comment.id] = _.cloneDeep(comment);
    });
    delete copy.comments;

    _.reduce(
        child_sessions,
        (store, item) => {
            (item.comments || []).forEach((comment) => {
                store[comment.id] = _.cloneDeep(comment);
            });
            delete item.comments;
            return store;
        },
        comments,
    );

    let attachments = _.reduce(
        fBlocks,
        (store, item) => {
            (item.attachments || []).forEach((attachment) => {
                store[attachment.id] = _.cloneDeep(attachment);
                store[attachment.id].session_id = sessionId;
            });
            delete item.attachments;
            return store;
        },
        {},
    );
    (copy.attachments || []).forEach((attachment) => {
        attachments[attachment.id] = _.cloneDeep(attachment);
        attachments[attachment.id].session_id = sessionId;
    });
    delete copy.attachments;

    let extraColumns = _.reduce(
        copy.extra_columns,
        (store, item) => {
            store[item.id] = item;
            return store;
        },
        {},
    );
    delete copy.extra_columns;

    let history = session.history;

    let collaborations = _.reduce(
        copy.collaborations,
        (store, item) => {
            item.session_id = sessionId;
            store[item.id] = item;
            return store;
        },
        {},
    );

    let sessions = flatten(copy, { reference: 'child_sessions' });
    _.forEach(sessions, (item) => {
        item.totalTime = null;
        item.hasTimeBuffer = false;
        item.hasTimeOverlap = false;
    });

    return {
        extraColumnValues,
        comments,
        attachments,
        extraColumns,
        blocks: fBlocks,
        history,
        collaborations,
        sessions,
    };
}
