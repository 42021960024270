<template>
    <el-form-item v-bind="$attrs" :label="label" :error="errorMessage">
        <slot />
    </el-form-item>
</template>

<script>
    import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

    export default {
        name: 'ElFormItemExtended',
        extends: singleErrorExtractorMixin,
        inheritAttrs: false,

        props: {
            serverErrors: {
                type: Array,
                default: () => [],
            },
        },

        computed: {
            hasAnyErrors() {
                return this.hasErrors || this.serverErrors.length;
            },
            isFullyValid() {
                return this.isValid && !this.serverErrors.length;
            },
            errorMessage() {
                return (
                    (this.hasAnyErrors && (this.firstErrorMessage || this.serverErrors[0])) || ''
                );
            },
        },
    };
</script>
