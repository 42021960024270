import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(query = {}) {
    return request({
        url: '/sessions',
        method: 'get',
        params: query,
    });
}

export function remove(id) {
    return request({
        url: `/sessions/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/sessions/${id}`,
        method: 'get',
    });
}

export function update(id, data) {
    return request({
        url: `/sessions/${id}`,
        method: 'put',
        data,
    });
}

export function store(data) {
    return request({
        url: '/sessions',
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}

export function getFull(id, token = null) {
    return request({
        url: `/sessions/full/${id}`,
        method: 'get',
        params: {
            t: token,
        },
    });
}

export function newSession(data) {
    return request({
        url: `/sessions/new`,
        method: 'post',
        data,
    });
}

export function addDay(id, data = {}) {
    return request({
        url: `/sessions/${id}/add-day`,
        method: 'post',
        data,
    });
}

export function duplicate(id, data) {
    return request({
        url: `/sessions/${id}/duplicate`,
        method: 'post',
        data,
    });
}

export function leave(id) {
    return request({
        url: `/sessions/${id}/leave`,
        method: 'get',
    });
}

export function freshHistory(params) {
    return request({
        url: `/session/history/fresh`,
        method: 'get',
        params,
    });
}

export function attach(id, object_id, object_type) {
    return request({
        url: `/sessions/${id}/attachments/attach`,
        method: 'post',
        params: {
            object_id,
            object_type,
        },
    });
}

export function detach(id, attachment_id) {
    return request({
        url: `/sessions/${id}/attachments/detach`,
        method: 'delete',
        params: {
            attachment_id,
        },
    });
}

export function duplicatePublication(id) {
    return request({
        url: `/sessions/${id}/duplicate/publication`,
        method: 'post',
    });
}

export function sessionPublish(id, saveAuthor = false) {
    return request({
        url: `/sessions/${id}/duplicate/publish`,
        method: 'post',
        data: {
            save_author: saveAuthor,
        },
    });
}

export function duplicateToCooperative(id) {
    return request({
        url: `/sessions/${id}/duplicate/cooperative`,
        method: 'post',
    });
}

export function cooperativeList(params) {
    return request({
        url: `/sessions/cooperative/list`,
        method: 'get',
        params,
    });
}

export function cooperativeTeams() {
    return request({
        url: `/cards/cooperative/teams`,
        method: 'get',
    });
}

export function getAuthorOriginalSession(id) {
    return request({
        url: `/sessions/${id}/author-original`,
        method: 'get',
    });
}

export function unlock(ids) {
    return request({
        url: `/sessions/unlock`,
        method: 'post',
        data: {
            ids,
        },
    });
}

export function addCorporateLink(id) {
    return request({
        url: `/sessions/${id}/corporate/add-link`,
        method: 'post',
    });
}

export function removeCorporateLink(id) {
    return request({
        url: `/sessions/${id}/corporate/remove-link`,
        method: 'delete',
    });
}
