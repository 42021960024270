import { DateTime } from 'luxon';

export function pluralize(time, label) {
    if (time === 1) {
        return time + label;
    }
    return time + label + 's';
}

export function timeAgo(time) {
    if (!time) return '';

    let between = mdt(new Date()).diff(mdt(time)).toObject().milliseconds / 1000;
    if (between < 60) {
        return 'a few seconds ago';
    }
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute');
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour');
    } else {
        return pluralize(~~(between / 86400), ' day');
    }
}

export function formatDate(data) {
    if (!data) return '';

    return mdt(data).toFormat('yyyy-MM-dd');
}

export function mdt(value, format = undefined, options = { zone: 'utc' }) {
    if (value instanceof DateTime) {
        return value;
    }

    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }

    if (typeof value === 'string') {
        return format
            ? DateTime.fromFormat(value, format, options)
            : DateTime.fromISO(value, options);
    }

    if (typeof value === 'number') {
        return DateTime.fromJSDate(new Date(value), options);
    }

    return null;
}

export function buildSearch(params = {}) {
    return encodeURIComponent(JSON.stringify(params));
}

export function loadImage(src) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = function () {
            resolve(src);
        };
        img.onerror = function (error) {
            reject(error);
        };
        img.src = src;
    });
}
