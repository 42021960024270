var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-container" },
    [
      _vm._t("parallax-bg"),
      _vm._v(" "),
      _c(
        "div",
        { ref: "slides", class: _vm.classes.wrapperClass },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm._t("pagination"),
      _vm._v(" "),
      _vm._t("button-prev"),
      _vm._v(" "),
      _vm._t("button-next"),
      _vm._v(" "),
      _vm._t("scrollbar"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }