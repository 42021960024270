var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppHeaderDropdown",
    { staticClass: "pr-2", attrs: { right: "" } },
    [
      _c("template", { slot: "header" }, [
        _c("img", {
          staticClass: "img-avatar",
          attrs: { src: _vm.avatarUrl, alt: _vm.name },
        }),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "dropdown" },
        [
          _c("b-dropdown-item", { on: { click: _vm.handleProfile } }, [
            _c("i", { staticClass: "fas fa-user" }),
            _vm._v(" " + _vm._s(_vm.$t("nav.my_profile")) + "\n        "),
          ]),
          _vm._v(" "),
          _vm.visibleTariff
            ? _c("b-dropdown-item", { on: { click: _vm.handleTariff } }, [
                _c("i", { staticClass: "fas fa-dollar-sign" }),
                _vm._v(" " + _vm._s(_vm.$t("nav.tariff")) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("b-dropdown-item", { on: { click: _vm.handleTeams } }, [
            _c("i", { staticClass: "fas fa-users" }),
            _vm._v(" " + _vm._s(_vm.$t("nav.teams")) + "\n        "),
          ]),
          _vm._v(" "),
          _c("b-dropdown-divider"),
          _vm._v(" "),
          _c("b-dropdown-item", { on: { click: _vm.handleLogout } }, [
            _c("i", { staticClass: "fas fa-lock" }),
            _vm._v(" " + _vm._s(_vm.$t("nav.logout")) + "\n        "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }