<template>
    <section @click.stop>
        <table>
            <tbody>
                <tr v-if="controls" class="text-center">
                    <td>
                        <b-button
                            variant="link"
                            size="sm"
                            @click="changeTime(1, 1)"
                            :disabled="readonly"
                        >
                            <i :class="iconControlUp"></i>
                        </b-button>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                        <b-button
                            variant="link"
                            size="sm"
                            @click="changeTime(0, 1)"
                            :disabled="readonly"
                        >
                            <i :class="iconControlUp"></i>
                        </b-button>
                    </td>
                </tr>
                <tr>
                    <td class="form-group">
                        <input
                            ref="hoursInput"
                            type="tel"
                            pattern="\d*"
                            class="form-control text-center"
                            style="width: 50px"
                            placeholder="HH"
                            @mouseup="selectInputValue"
                            @keydown.prevent.up="changeTime(1, 1)"
                            @keydown.prevent.down="changeTime(1, 0)"
                            @wheel="onWheel($event, true)"
                            v-model.lazy="hoursText"
                            maxlength="2"
                            size="2"
                        />
                    </td>
                    <td>&nbsp;<b>:</b>&nbsp;</td>
                    <td class="form-group">
                        <input
                            ref="minutesInput"
                            type="tel"
                            pattern="\d*"
                            class="form-control text-center"
                            style="width: 50px"
                            placeholder="MM"
                            @mouseup="selectInputValue"
                            @keydown.prevent.up="changeTime(0, 1)"
                            @keydown.prevent.down="changeTime(0, 0)"
                            @wheel="onWheel($event, false)"
                            v-model.lazy="minutesText"
                            :readonly="readonly"
                            maxlength="2"
                            size="2"
                        />
                    </td>
                </tr>
                <tr v-if="controls" class="text-center">
                    <td>
                        <b-button
                            variant="link"
                            size="sm"
                            @click="changeTime(1, 0)"
                            :disabled="readonly"
                        >
                            <i :class="iconControlDown"></i>
                        </b-button>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                        <b-button
                            variant="link"
                            size="sm"
                            @click="changeTime(0, 0)"
                            :disabled="readonly"
                        >
                            <i :class="iconControlDown"></i>
                        </b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    import { pad } from '@/front/shared/utils/stringUtils';

    const maxHours = 23;
    const zero = 0;
    const maxMinutes = 59;
    const reTime = /([0-1][0-9]|2[0-3]):([0-5][0-9])/;
    const defaultMinTime = {
        hours: zero,
        minutes: zero,
    };

    function validTime(value, minimum) {
        let { hours: minHour, minutes: minMinute } = minimum || defaultMinTime;
        let { hours: hour, minutes: minute } = value || defaultMinTime;

        let hours = minHour;
        if (hour >= minHour && hour <= maxHours) {
            hours = hour;
        }

        minMinute = hours > minHour ? zero : minMinute;
        let minutes = minMinute;
        if (minute >= minMinute && minute <= maxMinutes) {
            minutes = minute;
        }

        return {
            hours,
            minutes,
        };
    }

    export default {
        props: {
            value: {
                type: String,
                required: true,
            },
            min: String,
            max: String,
            hourStep: {
                type: Number,
                default: 1,
            },
            minStep: {
                type: Number,
                default: 1,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            controls: {
                type: Boolean,
                default: true,
            },
            iconControlUp: {
                type: String,
                default: 'fa fa-chevron-up',
            },
            iconControlDown: {
                type: String,
                default: 'fa fa-chevron-down',
            },
        },
        data() {
            return {
                hoursText: '',
                minutesText: '',
            };
        },
        mounted() {
            this.updateByValue(this.value);
        },
        computed: {
            timeValue() {
                let hour = parseInt(this.hoursText);
                let minute = parseInt(this.minutesText);
                return {
                    hours: isNaN(hour) ? 0 : hour,
                    minutes: isNaN(minute) ? 0 : minute,
                };
            },
            hours: {
                get() {
                    return validTime(this.timeValue, this.minValue).hours;
                },
                set(value) {
                    this.hoursText = value + '';
                },
            },
            minutes: {
                get() {
                    return validTime(this.timeValue, this.minValue).minutes;
                },
                set(value) {
                    this.minutesText = value + '';
                },
            },
            minValue() {
                let match = this.min && reTime.exec(this.min);
                if (!match) return defaultMinTime;

                return {
                    hours: parseInt(match[1]),
                    minutes: parseInt(match[2]),
                };
            },
        },
        watch: {
            value(value) {
                this.updateByValue(value);
            },
            min() {
                this.updateTime();
            },
            hoursText(value) {
                if (value !== '' && value !== pad(this.hours, 2)) {
                    this.hoursText = pad(this.hours, 2);
                    return;
                }
                this.updateTime();
            },
            minutesText(value) {
                if (value !== '' && value !== pad(this.minutes, 2)) {
                    this.minutesText = pad(this.minutes, 2);
                    return;
                }
                this.updateTime();
            },
        },
        methods: {
            updateByValue(value) {
                if (!value) return this.reset();

                let match = reTime.exec(value);
                if (!match) return this.reset();

                this.hours = parseInt(match[1]);
                this.minutes = parseInt(match[2]);
                this.updateTime();
            },
            reset() {
                this.hoursText = '';
                this.minutesText = '';
            },
            updateTime() {
                this.hoursText = this.hoursText && pad(this.hours, 2);
                this.minutesText = this.minutesText && pad(this.minutes, 2);
                this.setTime();
            },
            addHour(step) {
                step = step || this.hourStep;
                this.hours = this.hours >= maxHours ? zero : this.hours + step;
            },
            reduceHour(step) {
                step = step || this.hourStep;
                this.hours = this.hours <= zero ? maxHours : this.hours - step;
            },
            addMinute() {
                if (this.minutes >= maxMinutes) {
                    this.minutes = zero;
                    this.addHour(1);
                } else {
                    this.minutes += this.minStep;
                }
            },
            reduceMinute() {
                if (this.minutes <= zero) {
                    this.minutes = maxMinutes + 1 - this.minStep;
                    this.reduceHour(1);
                } else {
                    this.minutes -= this.minStep;
                }
            },
            changeTime(isHour, isPlus) {
                if (!this.readonly) {
                    if (isHour && isPlus) {
                        this.addHour();
                    } else if (isHour && !isPlus) {
                        this.reduceHour();
                    } else if (!isHour && isPlus) {
                        this.addMinute();
                    } else {
                        this.reduceMinute();
                    }
                }
            },
            onWheel(e, isHour) {
                if (!this.readonly) {
                    e.preventDefault();
                    this.changeTime(isHour, e.deltaY < 0);
                }
            },
            getTimeValue() {
                if (!this.hoursText || !this.minutesText) {
                    return '';
                }
                return `${pad(this.hours, 2)}:${pad(this.minutes, 2)}`;
            },
            setTime() {
                let value = this.getTimeValue();
                if (value === this.oldValue) return;
                this.oldValue = value;
                this.$emit('input', this.oldValue);
            },
            selectInputValue(e) {
                e.target.setSelectionRange(0, 2);
            },
        },
    };
</script>
