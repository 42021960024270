var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tinymce-container editor-container" },
    [
      _c("tinymce-editor", {
        ref: "tinymce",
        attrs: { value: _vm.value, init: _vm.init },
        on: { input: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }