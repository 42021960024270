<template>
    <div class="collaborators-box" v-loading="pending">
        <p class="mb-1">{{ $t('collaborators.access_levels') }}</p>
        <div class="collaborators-list">
            <div class="collaborator-item" v-for="item in list" :key="item.id">
                <div class="collaborator-item__icon">
                    <template v-if="isInvitation(item)"
                        ><i class="fa fa-2x fa-envelope"></i
                    ></template>
                    <template v-else>
                        <img
                            class="collaborator-item__icon-avatar"
                            :src="avatarUrl(item.user)"
                            :alt="item.user.name"
                        />
                    </template>
                </div>
                <div class="collaborator-item__body">
                    <template v-if="isInvitation(item)">{{ item.invitation.email }}</template>
                    <template v-else>
                        <span>{{ item.user.name }}</span
                        ><br />
                        <span class="text-muted">{{ item.user.email }}</span>
                    </template>
                </div>
                <div class="collaborator-item__actions">
                    <template v-if="isOwner(item)">
                        {{ $t('collaborators.owner') }}
                    </template>
                    <template v-else>
                        <b-button-group>
                            <b-dropdown
                                right
                                variant="link"
                                toggle-tag="span"
                                no-caret
                                class="icon-btn"
                                :disabled="!isCollaborator"
                                :title="collaborationRoleName(item)"
                            >
                                <template slot="button-content">
                                    <i v-if="item.role_id == 3" class="fa fa-pencil fa-fw"></i>
                                    <i v-else class="fa fa-eye fa-fw"></i>
                                </template>

                                <b-dropdown-item
                                    v-if="hasRole(4) && item.role_id == 3"
                                    @click="handleEditCollaborator(item, 4)"
                                >
                                    <i class="fa fa-eye fa-fw"></i>
                                    {{ collaborationRoleName({ role_id: 4 }) }}
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="hasRole(3) && item.role_id == 4"
                                    @click="handleEditCollaborator(item, 3)"
                                >
                                    <i class="fa fa-pencil fa-fw"></i>
                                    {{ collaborationRoleName({ role_id: 3 }) }}
                                </b-dropdown-item>

                                <b-dropdown-item @click="handleRemoveCollaborator(item)">
                                    <i class="fa fa-times fa-fw"></i> {{ $t('form.delete') }}...
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                    </template>
                </div>
            </div>

            <template v-if="team">
                <div class="collaborator-item">
                    <div class="collaborator-item__icon">
                        <template><i class="fa fa-2x fa-users"></i></template>
                    </div>
                    <div class="collaborator-item__body">
                        <span>{{ team.name }}</span
                        ><br />
                    </div>
                </div>
            </template>
        </div>

        <div v-if="isCollaborator" class="collaborator-new">
            <p class="mb-1">{{ $t('collaborators.invite_user') }}:</p>

            <multiselect
                v-model="selectedUser"
                :options="users"
                :placeholder="$t('collaborators.placeholder_invite_user_by_email')"
                label="name"
                track-by="id"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :show-no-results="false"
                :show-no-options="false"
                :hide-selected="true"
                reset-after
                select-label=""
                deselect-label=""
                @select="handleSelectUser"
                @search-change="handleSearchUser"
            >
                <template slot="option" slot-scope="props">
                    <span v-if="!props.option.invite">{{ props.option.name }}</span>
                    <span v-else
                        >invite <em>{{ props.option.name }}</em> to SessionDesign</span
                    >
                </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
    import { email } from 'vuelidate/lib/validators';
    import * as userApi from '@/front/shared/api/user';
    import * as sessionCollaborationApi from '@/front/shared/api/sessionCollaboration';
    import * as sessionCollaborationInvitationApi from '@/front/shared/api/sessionCollaborationInvitation';
    import { CancelToken } from '@/front/shared/utils/request';
    import { collaborationRoles } from '@/front/shared/lists/collaborationRoles';

    export default {
        name: 'Collaborators',
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            roles: {
                type: Array,
                default() {
                    return [];
                },
            },
            push: {
                Boolean,
                default: true,
            },
            team: Object,
            isCollaborator: Boolean,
            sessionId: [String, Number],
        },
        data() {
            return {
                query: '',
                users: [],
                selectedUser: null,
                isLoading: false,
                pending: false,
            };
        },
        computed: {},
        created() {
            this.cancelFind = null;
        },
        methods: {
            isInvitation(item) {
                return item.user_id == null;
            },
            isOwner(item) {
                return item.role_id == collaborationRoles.owner;
            },
            collaborationRoleName(item) {
                let role = this.roles.find((role) => role.id == item.role_id);
                return role ? role.name : '';
            },
            handleSelectUser(item) {
                if (!item.invite) {
                    this.addCollaborator(item);
                    return;
                }

                this.$root.$emit('showModalAddInvite', {
                    mode: 'session',
                    playload: { sessionId: this.sessionId },
                    onOk: this.onAddInviteOk,
                    email: item.name,
                });
            },
            onAddInviteOk(response) {
                this.list.push(response.data);
            },
            handleSearchUser(query) {
                if (!query || query.length < 3) {
                    this.users = [];
                    return;
                }

                this.isLoading = true;
                this.cancelFind && this.cancelFind.cancel();
                this.cancelFind = CancelToken.source();
                userApi
                    .find({ q: query })
                    .then((response) => {
                        this.users = response.data.filter(
                            (item) =>
                                !this.list.find((collaborator) => item.id == collaborator.user_id),
                        );
                        if (!response.data.length && email(query)) {
                            this.users = [
                                {
                                    id: null,
                                    name: query,
                                    email: query,
                                    invite: true,
                                },
                            ];
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.cancelFind = null;
                    });
            },
            async addCollaborator(item) {
                this.pending = true;
                try {
                    const response = await sessionCollaborationApi.store(this.sessionId, {
                        user_id: item.id,
                        collaboration_role_id: collaborationRoles.viewer,
                    });

                    if (this.push) {
                        this.list.push(response.data);
                    } else {
                        this.$emit('add-collaborator', response.data);
                    }
                } finally {
                    this.pending = false;
                }
            },
            async handleEditCollaborator(collaborator, role) {
                this.loading = true;
                try {
                    let fnUpdate = this.isInvitation(collaborator)
                        ? sessionCollaborationInvitationApi.update
                        : sessionCollaborationApi.update;
                    const response = await fnUpdate(collaborator.id, this.sessionId, {
                        collaboration_role_id: role,
                    });

                    if (this.push) {
                        const idx = this.list.findIndex((item) => item.id === collaborator.id);
                        this.list.splice(idx, 1, response.data);
                    } else {
                        this.$emit('update-collaborator', response.data);
                    }
                } finally {
                    this.loading = false;
                }
            },
            async handleRemoveCollaborator(collaborator) {
                this.loading = true;
                try {
                    let fnUpdate = this.isInvitation(collaborator)
                        ? sessionCollaborationInvitationApi.remove
                        : sessionCollaborationApi.remove;
                    await fnUpdate(collaborator.id, this.sessionId);

                    if (this.push) {
                        const idx = this.list.findIndex((item) => item.id === collaborator.id);
                        this.list.splice(idx, 1);
                    } else {
                        this.$emit('remove-collaborator', collaborator);
                    }
                } finally {
                    this.loading = false;
                }
            },
            avatarUrl(user) {
                return (user && user.avatar && user.avatar.url) || '/img/no_avatar.jpg';
            },
            hasRole(roleId) {
                return this.roles.some((item) => item.id == roleId);
            },
        },
    };
</script>
