<template>
    <b-button-group :size="size">
        <b-button
            :disabled="disabled"
            v-for="(currency, idx) in list"
            :key="idx"
            :pressed="isSelectedCurrency(currency)"
            :variant="variant"
            style="text-transform: uppercase"
            @update:pressed="handlePressed(currency, $event)"
        >
            {{ currency }}
        </b-button>
    </b-button-group>
</template>

<script>
    export default {
        name: 'CurrencySwitcher',
        props: {
            value: {
                type: String,
            },
            size: {
                type: String,
                default: 'md',
            },
            variant: {
                type: String,
                default: 'outline-secondary',
            },
            list: {
                type: Array,
                default() {
                    return [];
                },
            },
            disabled: Boolean,
        },
        data() {
            return {
                selectedCurrency: this.value,
            };
        },
        watch: {
            value(value) {
                this.selectedCurrency = value;
            },
            selectedCurrency() {
                this.$emit('input', this.selectedCurrency);
            },
        },
        computed: {
            isSelectedCurrency() {
                return (currency) => currency === this.selectedCurrency;
            },
        },
        methods: {
            handlePressed(currency) {
                this.selectedCurrency = currency;
            },
        },
    };
</script>
