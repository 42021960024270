import request from '@/front/shared/utils/request';

export function create(email, subject, message) {
    return request({
        url: '/invitations',
        method: 'post',
        data: {
            email,
            subject,
            message,
        },
    });
}
