import singleErrorExtractor from './SingleErrorExtractorServerErrorsMixin';

export default {
    name: 'FormGroup',
    extends: singleErrorExtractor,
    render(h) {
        const exclude = [
            'validator',
            'messages',
            'validatorParams',
            'name',
            'showSingleError',
            'serverErrors',
        ];

        const propsGroup = {
            invalidFeedback: this.errorMessage,
            state: this.isFullyValid,
        };

        for (let propName in this.$props) {
            if (exclude.indexOf(propName) !== -1) continue;
            propsGroup[propName] = this.$props[propName];
        }

        for (let propName in this.$attrs) {
            if (!this.$attrs.hasOwnProperty(propName)) continue;
            propsGroup[propName] = this.$attrs[propName];
        }

        return h(
            'b-form-group',
            {
                props: propsGroup,
            },
            this.$scopedSlots.default({
                attrs: {
                    state: this.isFullyValid,
                },
                listeners: {
                    input: () => this.preferredValidator.$touch && this.preferredValidator.$touch(),
                },
            }),
        );
    },
};
