import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import VueI18nFilter from 'vue-i18n-filter';

Vue.use(VueI18n);
Vue.use(VueI18nFilter);

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
    },
    ru: {
        currency: {
            style: 'currency',
            currency: 'RUB',
            currencyDisplay: 'symbol',
        },
    },
    eu: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
        },
    },
};

const oldGetChoiceIndex = VueI18n.prototype.getChoiceIndex;
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    if (this.locale !== 'ru') {
        return oldGetChoiceIndex.call(this, choice, choicesLength);
    }

    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
        return 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return choicesLength < 4 ? 2 : 3;
};

const i18n = new VueI18n({
    locale: Cookies.get('language') || 'ru',
    fallbackLocale: 'en',
    numberFormats,
});

const loadedLanguages = [];

export function loadLanguageAsync(lang) {
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(lang);
    }

    return import(/* webpackChunkName: "lang-[request]" */ `@/admin/lang/${lang}.js`).then(
        (messages) => {
            i18n.setLocaleMessage(lang, messages.default);
            loadedLanguages.push(lang);
            return lang;
        },
    );
}

export default i18n;
