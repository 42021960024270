import DefaultLayout from '@/front/shared/views/layouts/DefaultLayout';

const PrintLayout = () => import('@/front/shared/views/layouts/PrintLayout');

import auth from './auth';
import mail from './mail';

const routes = [
    ...auth,
    ...mail,
    {
        path: '',
        redirect: 'default',
        component: DefaultLayout,
        children: [
            {
                path: 'default',
                name: 'default.show',
                component: () => import('@/front/shared/views/Default'),
                props: { type: 'session' },
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/folders',
        redirect: 'folders.show',
        component: DefaultLayout,
        children: [
            {
                path: ':folderId',
                name: 'folders.show',
                component: () => import('@/front/shared/views/Default'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/team',
        redirect: 'team.show',
        component: DefaultLayout,
        children: [
            {
                path: ':teamId',
                name: 'team.show',
                component: () => import('@/front/shared/views/Default'),
                props: { type: 'team' },
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/locked',
        redirect: 'locked.show',
        component: DefaultLayout,
        children: [
            {
                path: '/locked',
                name: 'locked.show',
                component: () => import('@/front/shared/views/Default'),
                props: { type: 'locked' },
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/user',
        redirect: '/profile',
        name: 'User',
        component: DefaultLayout,
        children: [
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/front/shared/views/user/Profile'),
            },
            {
                path: 'tariff',
                name: 'tariff',
                component: () => import('@/front/shared/views/user/Tariff'),
            },
            {
                path: 'teams',
                name: 'teams',
                component: () => import('@/front/shared/views/user/Teams'),
            },
            {
                path: 'subscriptions',
                name: 'subscriptions',
                component: () => import('@/front/shared/views/subscription'),
            },
            {
                path: 'payments',
                name: 'payments',
                component: () => import('@/front/shared/views/user/Payments'),
            },
            {
                path: 'tariff-change',
                name: 'tariff-change',
                component: () => import('@/front/shared/views/user/TariffChange'),
            },
            {
                path: 'invoices',
                name: 'invoices',
                component: () => import('@/front/shared/views/user/Invoices'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/library',
        redirect: '/methods',
        name: 'Library',
        component: DefaultLayout,
        children: [
            {
                path: 'methods',
                name: 'Methods',
                component: () => import('@/front/shared/views/library/Methods'),
            },
            {
                path: 'scenarios',
                name: 'Scenarios',
                component: () => import('@/front/shared/views/library/Scenarios'),
            },
            {
                path: 'videoLibrary',
                name: 'VideoLibrary',
                component: () => import('@/front/shared/views/library/VideoLibrary'),
            },
            {
                path: 'modules',
                name: 'Modules',
                component: () => import('@/front/shared/views/library/Modules'),
            },
            {
                path: 'methods/:cardId',
                name: 'Method',
                component: () => import('@/front/shared/views/library/Method'),
            },
            {
                path: 'scenarios/:sessionId',
                name: 'Scenario',
                component: () => import('@/front/shared/views/library/partials/Scenario'),
            },
            {
                path: 'modules/:moduleId',
                name: 'Module',
                component: () => import('@/front/shared/views/library/partials/Module'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/corporate-library',
        redirect: '/sessions',
        name: 'corporateLibrary',
        component: DefaultLayout,
        children: [
            {
                path: 'sessions',
                name: 'corporateLibrary.sessions',
                component: () => import('@/front/shared/views/library/CorporateSessions'),
            },
            {
                path: 'methods',
                name: 'corporateLibrary.methods',
                component: () => import('@/front/shared/views/library/CorporateMethods'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/sessions',
        redirect: 'sessions.show',
        component: DefaultLayout,
        children: [
            {
                path: ':sessionId/multi',
                name: 'sessions.multi',
                component: () => import('@/front/shared/views/session-multi/SessionMultiContainer'),
            },
            {
                path: ':sessionId',
                name: 'sessions.show',
                component: () => import('@/front/shared/views/sessions/SessionEditor'),
                meta: { anon: true },
            },
            {
                path: ':sessionId/stepwise',
                name: 'sessions.stepwise',
                component: () =>
                    import('@/front/shared/views/session-stepwise/SessionStepwiseContainer'),
            },
            {
                path: ':sessionId/interactive-board',
                name: 'sessions.interactive-board',
                component: () =>
                    import(
                        '@/front/shared/views/session-interactive-board/SessionInteractiveBoardContainer'
                    ),
                meta: { anon: true },
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/sessions',
        redirect: 'print',
        component: PrintLayout,
        children: [
            {
                path: ':sessionId/print',
                name: 'sessions.print',
                component: () => import('@/front/shared/views/session-print/SessionPrint'),
            },
        ],
        meta: { auth: true, anon: true },
    },
    {
        path: '/payment',
        redirect: 'payment.add',
        component: DefaultLayout,
        children: [
            {
                path: '/payment/add/:invoiceId',
                name: 'payment.add',
                component: () => import('@/front/shared/views/payment/add'),
            },
            {
                path: '/payment/done/:invoiceId',
                name: 'payment.done',
                component: () => import('@/front/shared/views/payment/done'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/thank-registering',
        component: DefaultLayout,
        children: [
            {
                path: '/thank-registering',
                name: 'thankRegistering',
                component: () => import('@/front/shared/views/pages/ThankRegistering'),
            },
        ],
        meta: { auth: true },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/front/shared/views/pages/Page404'),
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/front/shared/views/pages/Page403'),
    },
    {
        path: '/500',
        name: '500',
        component: () => import('@/front/shared/views/pages/Page500'),
    },
    { path: '*', redirect: '/404' },
];

export default routes;
