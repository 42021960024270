<template>
    <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
        <div>
            <svg-icon class-name="international-icon" icon-class="language"/>
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="language === 'en'" command="en">English</el-dropdown-item>
            <el-dropdown-item :disabled="language === 'ru'" command="ru">Русский</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import {changeLocale} from "@/common/utils/url";

    export default {
        computed: {
            language() {
                return this.$store.getters.language;
            },
            languageText() {
                switch (this.language) {
                    case 'en': return  'English';
                    case 'ru': return  'Русский';
                }
                return '';
            },
        },
        methods: {
            handleSetLanguage(lang) {
                this.$i18n.locale = lang;
                this.$store.dispatch('setLanguage', lang);
                /*this.$message({
                    message: this.$i18n.t('lang.switchSuccess'),
                    type: 'success',
                });*/

                this.$nextTick(() => location.assign(changeLocale(lang)));
            },
        },
    };
</script>

<style scoped>
    .international-icon {
        font-size: 20px;
        cursor: pointer;
        vertical-align: -5px !important;
    }
</style>
