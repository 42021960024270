export function generate() {
    let chars = [
        "abcdefghijklmnopqrstuvwxyz",
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "0123456789",
        "!@#$%&*_"
    ];
    let string_password = '';
    chars.forEach(function (element) {
        let str_part = _.shuffle(element);
        str_part = str_part.join('');
        string_password += str_part.slice(0, 3);
    });
    return _.shuffle(string_password).join('');
}

export function strongPassword(password) {
    let chars = [
        "abcdefghijklmnopqrstuvwxyz",
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "0123456789",
        "!@#$%&*_"
    ];
    let result = [];
    let spl_password = password.split('');
    let i = 0;
    chars.forEach(function (element) {
        let spl_element = element.split('');
        result[i] = false;
        spl_password.forEach(function (password_char) {
            if (spl_element.find(item => item === password_char) !== undefined && result[i] === false) {
                result[i] = true;
            }
        });
        i++;
    });
    return (result.find(item => item === false) === undefined);
}

export function checkPasswordFormat(password) {
    password = password || '';
    return /^[a-zA-Z0-9`!@#$%^?&*(){}\[\]=~+\-_\\\/|";:.,]*$/.test(password);
}

/**
 * Difficulty 0 - 10
 */
export function getPasswordDifficulty(password) {
    password = password || '';
    let expressions = [/[a-z]/, /[A-Z]/, /[0-9]/, /[`!@#$%^?&*(){}\[\]=~+\-_\\\/|";:.,]/];
    let difficulty = 0;
    for (let i = 0; i < expressions.length; i++) {
        if (expressions[i].test(password)) {
            difficulty++;
        }
    }

    let pl = password.length;
    if (pl === 0) return 0;
    if (pl < 8) return difficulty;
    if (pl >= 8 && pl <= 11) return 4 + difficulty;
    if (pl >= 12) return 7 + difficulty - 1;

    return 0;
}
