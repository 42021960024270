import { asyncRouterMap, constantRouterMap } from '@/admin/router/routes';

/**
 * Check if it matches the current user right by meta.role
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some((role) => route.meta.roles.includes(role));
    }

    return true;
}

/**
 * Find all routers of this role
 * @param routes asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(routes, roles) {
    const res = [];

    routes.forEach((route) => {
        const tmp = { ...route };

        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRouter(tmp.children, roles);
            }

            res.push(tmp);
        }
    });

    return res;
}

const permission = {
    state: {
        routers: [],
        addRouters: [],
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers;
            state.routers = constantRouterMap.concat(routers);
        },
    },
    actions: {
        async GenerateRoutes({ commit }, data) {
            const { roles } = data;
            let accessedRouters;

            if (roles.includes('admin')) {
                accessedRouters = asyncRouterMap;
            } else {
                accessedRouters = filterAsyncRouter(asyncRouterMap, roles);
            }

            commit('SET_ROUTERS', accessedRouters);
        },
    },
};

export default permission;
