var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { right: "" } }, [
    _vm._v("Баланс: " + _vm._s(_vm.balance)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }