var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app" },
    [
      _vm.isAnon
        ? [
            _c("AppHeader", { attrs: { fixed: "" } }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "navbar-brand",
                      attrs: { href: _vm.site_url },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/img/brand/logo-big.png",
                          width: "120",
                          height: "35",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c("DefaultHeaderLanguage"),
                      _vm._v(" "),
                      _c("b-nav-item", { attrs: { href: "/login" } }, [
                        _vm._v(_vm._s(_vm.$t("common.login"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : [
            _c(
              "AppHeader",
              { attrs: { fixed: "" } },
              [
                _c("SidebarToggler", {
                  staticClass: "d-md-none",
                  attrs: { display: "sm", mobile: "" },
                }),
                _vm._v(" "),
                _c("SidebarToggler", {
                  staticClass: "d-sm-down-none",
                  attrs: { display: "md", defaultOpen: true },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "navbar-brand",
                    attrs: { href: _vm.site_url },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/brand/logo-big.png",
                        width: "120",
                        height: "35",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-navbar-nav",
                  { staticClass: "ml-auto" },
                  [
                    _c("DefaultHeaderLanguage"),
                    _vm._v(" "),
                    _c("DefaultHeaderDropdownAccnt"),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          !_vm.isAnon
            ? [
                _c(
                  "AppSidebar",
                  { attrs: { fixed: "" } },
                  [
                    _c("SidebarHeader"),
                    _vm._v(" "),
                    _c("SidebarForm"),
                    _vm._v(" "),
                    _c("SidebarNav", { attrs: { navItems: _vm.nav } }),
                    _vm._v(" "),
                    _c("SidebarFooter"),
                    _vm._v(" "),
                    _c("SidebarMinimizer"),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("main", { staticClass: "main" }, [
            _c(
              "div",
              { staticClass: "main-container" },
              [_c("router-view")],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isAnon
        ? [
            _c("chat-message"),
            _vm._v(" "),
            _c("modal-add-invite", { ref: "modalAddInvite" }),
            _vm._v(" "),
            _c("modal-collaboration", { ref: "modalCollaboration" }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }