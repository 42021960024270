<template>
    <b-modal
        ref="modal"
        :title="title"
        v-model="visible"
        size="md"
        @hidden="reset"
        @ok="handleOk"
        :busy="pendingPaymentGetAddInfo || pendingOk"
        :okTitle="
            $t('dialog.tariff_payment_add.ok', {
                amount: $options.filters.tCurrency(form.amount, form.currency),
            })
        "
        :cancelTitle="$t('dialog.cancel')"
        okOnly
    >
        <div v-loading="pendingPaymentGetAddInfo">
            <b-form-group>
                <label class="font-bold">{{ $t('dialog.tariff_payment_add.amount') }}</label>
                <span>{{ form.amount | tCurrency(form.currency) }}</span>
            </b-form-group>

            <b-form-group class="mb-0">
                <p class="mb-2">{{ $t('dialog.payment_add.payment_method') }}</p>
                <pay-methods v-model="form.payMethod" :list="payMethods" />
            </b-form-group>
        </div>
    </b-modal>
</template>

<script>
    import CurrencySwitcher from '@/front/shared/components/CurrencySwitcher';
    import FormValidationMixin from '@/front/shared/components/FormValidationMixin';
    import * as paymentApi from '@/front/shared/api/payment';
    import { minAmount } from '@/front/shared/validate/validators';
    import mixinRequestWithCancel from '@/common/mixins/mixinRequestWithCancel';
    import PayMethods from '@/front/shared/components/PayMethods';
    import { required } from 'vuelidate/lib/validators';

    const defaults = {
        currency: 'rub',
        amount: 10,
        payMethod: null,
    };

    const paymentGetAddInfoMixin = mixinRequestWithCancel(
        'paymentGetAddInfo',
        paymentApi.getAddInfo,
    );

    export default {
        name: 'ModalTariffPaymentAdd',
        mixins: [paymentGetAddInfoMixin],
        components: { PayMethods, CurrencySwitcher },
        props: {
            title: {
                type: String,
                default() {
                    return this.$t('dialog.tariff_payment_add.title');
                },
            },
            label: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                visible: false,
                loading: false,
                pendingOk: false,
                value: null,
                payMethods: [],
                form: {
                    ...defaults,
                },
            };
        },
        created() {
            this.playload = {};
            this.onOk = null;
        },
        methods: {
            open({ playload = {}, onOk = null } = {}) {
                this.reset();
                this.preparePlayload(playload);
                this.onOk = onOk;
                this.load();
                this.visible = true;
            },
            preparePlayload(playload) {
                this.playload = playload ?? {};
                const tmp = { ...defaults, ...playload };

                Object.keys(defaults).forEach((key) => {
                    this.form[key] = tmp[key];
                });
            },
            close() {
                this.visible = false;
            },
            reset() {
                this.cancelPaymentGetAddInfo();
                this.playload = {};
                this.onOk = null;
                this.payMethods = [];
            },
            async load() {
                const response = await this.fetchPaymentGetAddInfo();
                if (response === undefined) {
                    return;
                }

                this.form.payMethod = response.payment_methods.find(
                    (item) => item.code === response.payment_method_default,
                );
                this.payMethods = response.payment_methods;
            },
            changeAmount(event) {
                this.form.amount = event;
            },
            async handleOk(e) {
                e.preventDefault();

                let result = {
                    currency: this.form.currency,
                    amount: this.form.amount,
                    paymentMethodCode: this.form.payMethod.code,
                };
                if (_.isFunction(this.onOk)) {
                    this.pendingOk = true;
                    try {
                        result = await this.onOk(result, this.playload);
                    } catch (e) {
                        return false;
                    } finally {
                        this.pendingOk = false;
                    }
                }

                if (result === false) return false;
                this.$emit('ok', result, this.playload);
                this.visible = false;
            },
        },
    };
</script>
