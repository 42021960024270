import request from '@/front/shared/utils/request';

export const primaryKey = 'id';

export function fetchList(query = {}) {
    return request({
        url: `/favorites`,
        method: 'get',
        params: query,
    });
}

export function remove(id) {
    return request({
        url: `/favorites/${id}`,
        method: 'delete',
    });
}

export function show(id) {
    return get(id);
}

export function get(id) {
    return request({
        url: `/favorites/${id}`,
        method: 'get',
    });
}

export function update(id, data) {
    return request({
        url: `/favorites/${id}`,
        method: 'put',
        data,
    });
}

export function store(data) {
    return request({
        url: `/favorites`,
        method: 'post',
        data,
    });
}

export function save(data) {
    if (data.hasOwnProperty(primaryKey) && data[primaryKey] != null) {
        return update(data[primaryKey], data);
    }

    return store(data);
}
export function getFavoriteCardByType(type) {
    return request({
        url: `/favorites/card-by-type`,
        method: 'get',
        params: { type },
    });
}
