import Vue from 'vue';
import { mdt, minuteToDate } from '@/front/shared/utils/misc';
import { format as formatTimeAgo } from 'timeago.js';
import '@/common/filters';
import store from './../store';
import lang from './../lang';

function timeAgo(lang, value) {
    return formatTimeAgo(value, lang);
}

Vue.filter('timeAgo', timeAgo.bind(this, store.getters.language));
Vue.filter('durationToTime', function (value) {
    let min = Math.max(value, 0);
    let hours = Math.trunc(min / 60);
    let minutes = min - hours * 60;
    return lang.t('misc.filters.durationToTime.' + (hours > 0 ? 'long' : 'short'), {
        hours,
        minutes,
    });
});

Vue.filter('formatTime', function (value) {
    if (value == null) return '';
    return minuteToDate(value).toFormat('HH:mm');
});

Vue.filter('formatTimeMin', function (value) {
    return value;
});

Vue.filter('formatTimeHourMin', function (value, $t) {
    const diff = minuteToDate(value).diff(mdt(0), ['hours', 'minutes']).toObject();

    let negSign = diff.hours < 0 || diff.minutes < 0;
    diff.hours = Math.abs(diff.hours);
    diff.minutes = Math.abs(diff.minutes);
    let hours = diff.hours < 10 ? '0' + diff.hours : '' + diff.hours;
    let minutes = diff.minutes < 10 ? '0' + diff.minutes : '' + diff.minutes;
    return $t('session.duration_day_format', { sing: negSign ? '-' : '', hours, minutes });
});

Vue.filter('noZero', function (value) {
    return value == 0 ? '' : value.toString();
});

Vue.filter('nl2br', function (value) {
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
});
