export default class Subscription {
    constructor(options = {}) {
        this.setOptions(options);
    }
    setOptions(options) {
        this.options = _.cloneDeep(options || {});
    }
    get code() {
        return this.options.code || '';
    }
    get name() {
        return this.options.name || '';
    }
    get dateStart() {
        return this.options.date_start || '';
    }
    get dateEnd() {
        return this.options.date_end || '';
    }
    canUse(code) {
        if (!this.hasFeature(code)) return false;
        return this.getFeature(code).access;
    }
    getFeature(code) {
        return (this.options.items && this.options.items[code]) || null;
    }
    hasFeature(code) {
        return !!this.getFeature(code);
    }
    getValue(code, def = null) {
        if (!this.hasFeature(code)) return def;
        return this.getFeature(code).value;
    }
    getCount(code, def = 0) {
        if (!this.hasFeature(code)) return def;
        return this.getFeature(code).count;
    }
    getUsageOf(code) {
        if (!this.hasFeature(code)) return 0;
        return this.getFeature(code).used;
    }
    get canUseConstructorSession() {
        return this.canUse(FeatureCodes.CONSTRUCTOR_SESSION);
    }
    get canScenariosShare() {
        return this.canUse(FeatureCodes.SCENARIOS_SHARE);
    }
    get canUseAccessMethods() {
        return this.canUse(FeatureCodes.ACCESS_METHODS);
    }
    get canUseAccessScenarios() {
        return this.canUse(FeatureCodes.ACCESS_SCENARIOS);
    }
    get canUseCooperativeScenarios() {
        return this.canUse(FeatureCodes.COOPERATIVE_LIBRARY);
    }
    get canUseCooperativeRealWork() {
        return this.canUse(FeatureCodes.COOPERATIVE_REAL_WORK);
    }
    get canUseDesignExport() {
        return this.canUse(FeatureCodes.DESIGN_EXPORT);
    }
    get canUseAdditionalTeam() {
        return this.canUse(FeatureCodes.ADDITIONAL_TEAM);
    }
    get canUseAdditionalTeamMember() {
        return this.canUse(FeatureCodes.ADDITIONAL_TEAM_MEMBER);
    }
    get canUseDesignExportPdf() {
        if (!this.canUseDesignExport) return false;
        let value = parseInt(this.getCount(FeatureCodes.DESIGN_EXPORT));
        return value > 0;
    }
    get canUseDesignExportWord() {
        if (!this.canUseDesignExport) return false;
        let value = parseInt(this.getCount(FeatureCodes.DESIGN_EXPORT));
        return value > 1;
    }
    get canUseDesignExportExcel() {
        if (!this.canUseDesignExport) return false;
        let value = parseInt(this.getCount(FeatureCodes.DESIGN_EXPORT));
        return value > 2;
    }
    get canUseBaseNumberOfTeams() {
        return this.canUse(FeatureCodes.BASE_NUMBER_OF_TEAMS);
    }
    get canAppointmentOfEditors() {
        return this.canUse(FeatureCodes.APPOINTMENT_OF_EDITORS);
    }
    get canCreateYourOwnCards() {
        return this.canUse(FeatureCodes.CREATE_YOUR_OWN_CARDS);
    }
    get canUseBaseNumberOfTeamMembers() {
        return this.canUse(FeatureCodes.BASE_NUMBER_OF_TEAM_MEMBERS);
    }
    get canCreateSessions() {
        return this.canUse(FeatureCodes.CREATE_SESSIONS);
    }
    get baseTeams() {
        if (!this.canUseBaseNumberOfTeams) return 0;
        return this.getCount(FeatureCodes.BASE_NUMBER_OF_TEAMS);
    }
    get numberOfTeams() {
        if (!this.canUseAdditionalTeam) return this.baseTeams;
        return this.baseTeams + this.getUsageOf(FeatureCodes.ADDITIONAL_TEAM);
    }
    get baseTeamMembers() {
        if (!this.canUseBaseNumberOfTeamMembers) return 0;
        return this.getCount(FeatureCodes.BASE_NUMBER_OF_TEAM_MEMBERS);
    }
    get numberOfSessions() {
        if (!this.canCreateSessions) return 0;
        return this.getCount(FeatureCodes.CREATE_SESSIONS);
    }
}

export const FeatureCodes = {
    ACCESS_METHODS: 'access_methods',
    ACCESS_SCENARIOS: 'access_scenarios',
    CABINET_TYPE: 'cabinet_type',
    CONSTRUCTOR_SESSION: 'constructor_session',
    COOPERATIVE_LIBRARY: 'cooperative_library',
    COOPERATIVE_REAL_WORK: 'cooperative_real_work',
    COOPERATIVE_WORK_HISTORY: 'cooperative_work_history',
    CREATE_SESSIONS: 'create_sessions',
    DESIGN_EXPORT: 'design_export',
    DIFFERENT_ACCESS_TEAMS: 'different_access_teams',
    EXTENDED_TEAMS: 'extended_teams',
    EXTENDED_USERS: 'extended_users',
    PRINT_LOGO: 'print_logo',
    SCENARIOS_SHARE: 'scenarios_share',
    ADDITIONAL_TEAM: 'additional_team',
    BASE_NUMBER_OF_TEAMS: 'base_number_of_teams',
    ADDITIONAL_TEAM_MEMBER: 'additional_team_member',
    BASE_NUMBER_OF_TEAM_MEMBERS: 'base_number_of_team_members',
    APPOINTMENT_OF_EDITORS: 'appointment_of_editors',
    CREATE_YOUR_OWN_CARDS: 'create_your_own_cards',
};
