import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
    extends: singleErrorExtractorMixin,
    props: {
        serverErrors: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        internalServerErrors() {
            return this.serverErrors[this.name] || [];
        },
        hasAnyErrors() {
            return this.hasErrors || !!this.internalServerErrors.length;
        },
        isFullyValid() {
            return this.isValid && !this.internalServerErrors.length;
        },
        errorMessage() {
            return (
                (this.hasAnyErrors && (this.firstErrorMessage || this.internalServerErrors[0])) ||
                ''
            );
        },
    },
};
