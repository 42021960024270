import * as validators from 'vuelidate/lib/validators';
import { checkPasswordFormat } from '@/common/utils/password';
import { helpers, minValue } from 'vuelidate/lib/validators';

export function maxLengthProp(prop, length) {
    return validators.helpers.withParams(
        {
            type: 'maxLengthProp',
            prop,
            max: length,
        },
        function (value, parentVm) {
            let ref = validators.helpers.ref(prop, this, parentVm);
            if (ref) {
                return !validators.helpers.req(ref) || validators.helpers.len(ref) <= length;
            }
            return true;
        },
    );
}

export function passwordFormat(value) {
    return checkPasswordFormat(value);
}

export function minAmount(min, currency) {
    return helpers.withParams({ type: 'minAmount', min, currency }, (value) =>
        minValue(min)(value),
    );
}
