const Plugin = {
    install(Vue, options) {
        if (this.installed) {
            return;
        }

        const context = this;
        this.installed = true;
        this.init = false;

        const state = Vue.observable({
            commentsPending: [],
            pendings: [],
        });

        Vue.prototype.$commentPending = {
            add({ object_id, object_type }) {
                state.commentsPending.push({ object_id, object_type });
            },
            remove({ object_id, object_type }) {
                state.commentsPending = _.filter(
                    state.commentsPending,
                    (item) => item.object_id != object_id && item.object_type !== object_type,
                );
            },
            find({ object_id, object_type }) {
                return (
                    _.findIndex(state.commentsPending, [
                        'object_id',
                        object_id,
                        'object_type',
                        object_type,
                    ]) > -1
                );
            },
        };

        Vue.prototype.$pendings = {
            add(id, type) {
                state.pendings.push({ id, type });
            },
            remove(id, type) {
                state.pendings = _.filter(
                    state.pendings,
                    (item) => item.id != id && item.type !== type,
                );
            },
            removeByType(type) {
                state.pendings = _.filter(state.pendings, (item) => item.type !== type);
            },
            find(id, type) {
                return _.findIndex(state.pendings, ['id', id, 'type', type]) > -1;
            },
        };
    },
};

export default Plugin;
