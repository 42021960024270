var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "320px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _c("p", { staticClass: "text-center" }, [_vm._v("Уведомление")]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-center" }, [
        _c("span", { staticClass: "d-block w-100" }, [_vm._v("Ваша")]),
        _vm._v("\n        подписка завершается сегодня\n        "),
        _c("span", { staticClass: "d-block w-100" }, [
          _vm._v("через "),
          _c("strong", [_vm._v(_vm._s(_vm.time))]),
          _vm._v(" часа"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "tariff" } } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Перейти в тарифы")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }