import { render, staticRenderFns } from "./ChatMessage.vue?vue&type=template&id=9206fea2"
import script from "./ChatMessage.vue?vue&type=script&lang=js"
export * from "./ChatMessage.vue?vue&type=script&lang=js"
import style0 from "./ChatMessage.vue?vue&type=style&index=0&id=9206fea2&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/www-root/data/www/view.sessiondesign.ru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9206fea2')) {
      api.createRecord('9206fea2', component.options)
    } else {
      api.reload('9206fea2', component.options)
    }
    module.hot.accept("./ChatMessage.vue?vue&type=template&id=9206fea2", function () {
      api.rerender('9206fea2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/front/shared/views/layouts/partials/ChatMessage.vue"
export default component.exports